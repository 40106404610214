import React from 'react';
import {Box, useTheme} from "@mui/material";
import { tokens } from '../../../theme';

import cx from 'classnames';

const Icon = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
        
    return (
        <Box
            className={cx(`${props.size}`, {
                "blue": props.theme === "blue",
                'primary': props.theme === "primary",
                'orange' : props.theme === "orange",
                'green' : props.theme === "green",
                'red' : props.theme === "red",
                'purple' : props.theme === "purple",
                'pink' : props.theme==="pink",
                "close-overlay": props.theme === "close-overlay" || props.theme === "sidepanel",
                "side-panel": props.theme === "sidepanel",
                'contrast': props.contrast,
                "hoverable": props.hoverable,
                "rounded": props.rounded
            })}
            sx={{
                flexShrink:0,
                borderRadius:3,
                width:40,
                height:40,
                display:"flex",
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
                textAlign:"center",
                verticalAlign:"center",
                lineHeight:"40px",
                background: props.color !== null ? props.color : `${colors.grey[200]}`,
                color:colors.txt["primary"],
                transition:"250ms",
                "& svg":  {
                    width:24,
                    height:24,
                },
                // Sizes
                "&.s20": {
                    width:20,
                    height:20,
                    lineHeight:"20px",
                    borderRadius:1,
                    "& svg":  {
                        width:12,
                        height:12,
                    }
                },
                "&.s24": {
                    width:24,
                    height:24,
                    lineHeight:"24px",
                    borderRadius:1,
                    "& svg":  {
                        width:12,
                        height:12,
                    }
                },
                "&.s32": {
                    width:32,
                    height:32,
                    lineHeight:"32px",
                    borderRadius:2,
                    "& svg":  {
                        width:16,
                        height:16,
                    }
                },
                "&.s48": {
                    width:48,
                    height:48,
                    lineHeight:"48px",
                    borderRadius:4,
                    "& svg":  {
                        width:28,
                        height:28,
                    }
                },
                "&.s56": {
                    width:56,
                    height:56,
                    lineHeight:"56px",
                    borderRadius:4,
                    "& svg":  {
                        width:32,
                        height:32,
                    }
                    // ""
                },
                // Colors
                "&.pink" : {
                    fill: `${colors.pink[400]}`,
                    color: `${colors.pink[400]}`,
                    background: `${colors.pink[100]}`,
                    "&.contrast" : {
                        background: `${colors.pink[400]}`,
                        color: "#fff"
                    },
                    "& path": {
                        fill: `${colors.pink[400]}`,
                    }
                },
                "&.primary" : {
                    fill: `${colors.primary[400]}`,
                    color: `${colors.primary[400]}`,
                    background: `${colors.primary[100]}`,
                    "&.contrast" : {
                        background: `${colors.primary[400]}`,
                        color: "#fff"
                    },
                    "& path": {
                        fill: `${colors.primary[400]}`,
                    }
                },
                "&.blue": {
                    fill: `${colors.blue[400]}`,
                    color: `${colors.blue[400]}`,
                    background: `${colors.blue[100]}`,
                    "&.contrast" : {
                        background: `${colors.blue[400]}`,
                        color: "#000"
                    },
                    "& path": {
                        fill: `${colors.blue[400]}`,
                    }
                },
                "&.orange":{
                    fill: `${colors.orange[400]}`,
                    color: `${colors.orange[400]}`,
                    background: `${colors.orange[100]}`,
                    "&.contrast" : {
                        background: `${colors.orange[400]}`,
                        color: `${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                    },
                    "& path": {
                        fill: `${colors.orange[400]}`,
                    }
                },
                "&.green" : {
                    fill: `${colors.green[400]}`,
                    color: `${colors.green[400]}`,
                    background: `${colors.green[100]}`,
                    "&.contrast" : {
                        background: `${colors.green[400]}`,
                        color: `${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`,
                        fill: `${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`,
                        "& path" : {
                            fill: `${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`,

                        }
                    },
                    "& path": {
                        fill: `${colors.green[400]}`,
                    }
                },
                "&.red" : {
                    fill: `${colors.red[400]}`,
                    color: `${colors.red[400]}`,
                    background: `${colors.red[100]}`,
                    "&.contrast" : {
                        background: `${colors.red[400]}`,
                        color: `${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`,
                        fill: `${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`,
                        "& path" : {
                            fill: `${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`,

                        }
                    },
                    "& path": {
                        // color: `${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`,
                        // fill: `${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                        fill: `${colors.red[400]}`,
                    }
                },
                "&.purple" : {
                    fill: `${colors.purple[400]}`,
                    color: `${colors.purple[400]}`,
                    background: `${colors.purple[100]}`,
                    "&.contrast" : {
                        background: `${colors.purple[400]}`,
                        color: `${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                    },
                    "& path": {
                        fill: `${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                        // fill: `${colors.purple[400]}`,
                    }

                },
                "&.close-overlay": {
                    background:"rgba(255,255,255,.15)",
                    color:"#fff",
                    position:"absolute",
                    right:"-64px",
                    top:"0px",
                    cursor:"pointer",
                    zIndex:10003,
                    "&:hover" : {
                        background:"rgba(255,255,255,.55)",
                        transition:"250ms",
                    },
                    "@media screen and (max-width: 47.9375em)" :{
                        background: `unset !important`,
                        right:"8px",
                        top:"8px",
                        color: `${theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.grey[300]}`,
                        "&:hover" : {
                            color: `${theme.palette.mode === "dark" ? colors.txt["primary"] : colors.grey[400]}`,
                        }
                        // borderTopRightRadius:"24px",
                        // top:"-64px",
                    },
                    "&.side-panel": {
                        top:"24px",
                        right:"960px",
                        "@media screen and (max-width: 63.9375em)" : {
                            top:"8px",
                            right:"8px",
                            // bottom:"calc(85% - 56px)",
                            // top:"unset",
                            zIndex:1051,
                            background:"transparent !important",
                            color: `${theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.grey[300]}`,
                            "&:hover" : {
                                color: `${theme.palette.mode === "dark" ? colors.txt["primary"] : colors.grey[400]}`,
                            }
                        }
                    },
                },
                "&.rounded": {
                    borderRadius:"50%"
                },
            }}
            >
                {props.children}
        </Box>
    )
}

export default Icon;