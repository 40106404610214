
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';
import ListIcon from '@mui/icons-material/List';

export const spInvoiceCategoryDetailsConfig = {
    details: {
        title: 'details',
        icon: <InfoIcon/>,
    },
    relations: {
        title: 'relations',
        icon: <ContactsIcon/>,
    },
    products: {
        title: 'products',
        icon: <ListIcon/>,
    }
};
