import { Box, IconButton, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { tokens } from "../../../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../../theme"; 
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import Text from "../../../../../components/theme/text/Text";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import { useTranslation } from "react-i18next";
import { useGetCountingStrategyTemplatesQuery, useGetCountingStrategyTemplateQuery } from "../../../../../newapi/countingstrategy/countingStrategyTemplateSlice";
import MAddCountingStrategy from "../../../../../components/global/Modals/MAddCountingStrategy";
import SpCountingStrategyDetails from "../../../../../components/global/Sidepanels/SpCountingStrategyDetails/SpCountingStrategyDetails";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("add_counting_strategy")}</Shbutton>
        </ButtonGroup>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    // isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const All = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    // const { getCarrier} = useGetCarrierQuery();
    const [selectedRowId, setSelectedRowId] = useState(1);
    const [rowSelected, setRowSelected] = useState(false);


    const [strategyId, setStrategyId] = useState();
    const strategyIdRef = useRef(strategyId);
    
    const getStrategyDetails = (params) => {
        if (!params) return;
    
        const newStrategyId = parseInt(params.row.id);
        // Only update state if the productId has actually changed
        if (strategyIdRef.current !== params.row.id) {
            setStrategyId(params.row.id);
            strategyIdRef.current = params.row.id; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const { data: countingStrategyData, error, isLoading } = useGetCountingStrategyTemplateQuery(
       selectedRowId,
        {
            skip: !rowSelected
        }
    );

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };


    useEffect(() => {
        if (!isLoading && countingStrategyData && rowSelected) {
            setIsExtended(true);
        }
    }, [countingStrategyData, isLoading, rowSelected]);

    useEffect(() => {
        if(!isExtended) {
            setRowSelected(false);
        }
    },[isExtended])
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.name
            }
        },
        {
            field: "type",
            headerName: t("type"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return t(params.row.type);
            },
        },
        {
            field: "frequency",
            headerName: t("frequency"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return t(params.row.frequency);
            },
        },
        {
            field: "amount",
            headerName: t("amount"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return `${params.row.amount}%`;
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
            }
        }
    ];

    useImperativeHandle(ref, () => ({
    }));

    const handleRowClick = (params) => {
        setIsExtended(true);
    };
    
    return (
            <Box
                sx={{
                    height:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingTop:0,
                        paddingX:0,
                    }
                }}
            >
                
                    {/* <GridActions></GridActions> */}
                    
                    <ShDataGrid2
                        content={useGetCountingStrategyTemplatesQuery}
                        gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                        title={t("overview")}
                        gridOptions={gridOptions} 
                        
                        sortModel={{field: columns[0].field,type:"ASC"}}
                        columns={columns} 
                        onRowClick={getStrategyDetails}
                        rows={data}>
                    </ShDataGrid2>
                    

                    <MAddCountingStrategy onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
                    {/* <SpCarrierDetails isExtended={isExtended} setIsExtended={setIsExtended}/> */}
                    { <SpCountingStrategyDetails
                        isExtended={isExtended}
                        setIsExtended={setIsExtended}
                        strategyId={strategyId}                
                    ></SpCountingStrategyDetails> }
                </Box>
    )
});

export default All;