
import { muiDataGridStarted } from "../../apiutils";
import { apiSlice } from "../../createApiSlice";

const RELATION_BASE = "/relations";
const buildUrl = (endpoint) => RELATION_BASE + endpoint;
const ApiName = "Relations";
const ApiContactName = "RelationContacts";
const ApiInvoiceProductsName = "RelationInvoiceProducts";

const relationsApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRelations: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}`}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getRelations', `${ApiName}`)
        }),
        getRelationContacts: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/contacts`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: ApiContactName }]
        }),
        getRelationsSelect: builder.query({
            query: () => ({
                url: buildUrl(`/select`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
        getRelationInvoiceProducts: builder.query({
            query: productInfo => ({
                url: buildUrl(`/${productInfo.id}/invoiceproducts?onlyActive=${productInfo.onlyActive}`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: ApiInvoiceProductsName }]
        }),
        updateRelationInvoiceProducts: builder.mutation({
            query: productsInfo => ({
                url: buildUrl(`/${productsInfo.relationId}/invoiceproducts`),
                method: 'PUT',
                body: productsInfo.products,
            }),
            invalidatesTags: (result, error, arg) => [{ type: ApiInvoiceProductsName }, { type: `${ApiName}Single`, id: arg }],
        }),
        getRelation: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
        addNewRelation: builder.mutation({
            query: relationInfo => ({
                url: buildUrl(`/add`),
                method: 'POST',
                body: relationInfo,
            }),
            invalidatesTags: [`${ApiName}`],
        }),
        editRelation: builder.mutation({
            query: relationInfo => ({
                url: buildUrl(`/${relationInfo.id}`),
                method: 'PUT',
                body: relationInfo,
            }),
            invalidatesTags: (result, error, arg) => [{ type: ApiName }, {type: `${ApiName}`}, {type: 'InvoiceCategories'}],
        }),
        deleteRelation: builder.mutation({
            query: args => ({
                url: buildUrl(`/${args.id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiName}],
        }),
        addRelationContact: builder.mutation({
            query: relationContactInfo => ({
                url: buildUrl(`/${relationContactInfo.relationId}/contacts`),
                method: 'POST',
                body: relationContactInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiContactName}],
        }),
        deleteRelationContact: builder.mutation({
            query: args => ({
                url: buildUrl(`/${args.relationId}/contacts/${args.id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiContactName}],
        }),
    })
});

export const {
    useGetRelationsQuery,
    useGetRelationQuery,
    useGetRelationContactsQuery,
    useGetRelationInvoiceProductsQuery,
    useUpdateRelationInvoiceProductsMutation,
    useAddNewRelationMutation,
    useEditRelationMutation,
    useAddRelationContactMutation,
    useDeleteRelationContactMutation,
    useDeleteRelationMutation,
    useGetRelationsSelectQuery,
  } = relationsApiSlice;