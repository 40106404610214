import { Box, InputBase, List, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import NumberInput from "../../theme/inputs/NumberInput";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify"; 
import cx from "classnames"
import {checkAllPropertiesTruthy } from "../../../utils"
import { useAddNewSupplierMutation } from "../../../newapi/global/supplier/supplierSlice";
import { showToastMessage, showToastMessagePostRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired } from "../../../utils/validation";
import { countriesFromLocale } from '../../../utils/countries';
import { countryCodeNL } from '../../../utils/constants';
import { useSelector } from 'react-redux';
// import { required } from '../../utils/validation';



const MAddSupplier = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);
    const [addSupplier,{isLoading}] = useAddNewSupplierMutation();

    const select = (selected) => {
        setSelected(selected);
    }

    const [supplierInfo, setSupplierInfo] = useState({
        name: "",
        street: "",
        number: "",
        insertion: "",
        postal: "",
        city: "",
        province: "",
        country: "",
        phone: "",
        email: "",
        remark: ""
    });
    
    const language = useSelector((state) => state.settings.language);
    const countries = countriesFromLocale(language);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        street: yupRequired('street', t),
        number: yupRequired('number', t),
        postal:yupRequired('postal', t),
        city: yupRequired('city', t),
        country: yupRequired('country', t),
        email: yupRequired('email', t),
    };
    
    const schema = yup.object().shape(rules);
   
    const SaveSupplier = async () => {
        try {
          // Reset field errors
          setFieldErrors({});
      
          await schema.validate(supplierInfo, { abortEarly: false });
      
          // If validation is successful, proceed with the addSupplier call
          const data = await addSupplier(supplierInfo).unwrap();
          showToastMessagePostRequest(t, data);
          props.handleClose();
        } catch (err) {
          if (err.name === 'ValidationError') {
            // Create an errors object where keys are field names and values are error messages
            const errors = err.inner.reduce((acc, currentError) => ({
              ...acc,
              [currentError.path]: currentError.message
            }), {});
      
            // Set the validation errors in the state
            setFieldErrors(errors);
          } else {
            // Handle other errors, such as network errors
            showToastMessageRequestError(t("create_failed"), t, { success: false, error: err.error });
          }
        }
    };

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    const handleChange = (key, value) => {
        setSupplierInfo({
          ...supplierInfo,
          [key]: value
        });
        // Optionally clear the error for that field
        setFieldErrors({
          ...fieldErrors,
          [key]: ''
        });
      };

    // Set default country
    if (supplierInfo.country === "") {
        handleChange('country', countryCodeNL);
    }

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_supplier")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    pb:2,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                }}
            >
                

                <Box
                >
                    <Text bold> Persoon gegevens</Text>
                </Box>
                <Box
                    sx={{

                        display:"flex",
                        flexDirection:"column",
                        gap:2,
                        mt:1,
                    }}
                >
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"flex-start",
                            justifyContent:"space-between",
                            gap:7
                        }}
                    >

                        <Box
                            sx={{
                                width:120,
                                flexShrink:0,
                                height:32,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text light>{t("name")}</Text>
                        </Box>
                        <ShValidatedInput
                            name="name"
                            value={supplierInfo.name}
                            onChange={handleChange}
                            error={fieldErrors.name}
                        />
                    </Box>

                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"flex-start",
                            justifyContent:"space-between",
                            gap:7
                        }}
                    >

                        <Box
                            sx={{
                                width:120,
                                flexShrink:0,
                                height:32,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text light>{t("remark")}</Text>
                        </Box>
                        
                        <Box
                            sx={{
                                flex:1,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32
                            }}
                        >
                            <InputBase 
                                sx={{
                                    px:2,
                                    flex:1, 
                                    color:colors.txt["primary"],
                                    lineHeight:"32px",
                                }}
                                value={supplierInfo.remark}
                                onChange={(e) => handleChange('remark', e.target.value)}
                                />
                        </Box>
                    </Box>
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"flex-start",
                            justifyContent:"space-between",
                            gap:7
                        }}
                    >

                        <Box
                            sx={{
                                width:120,
                                flexShrink:0,
                                height:32,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text light>{t("phone")}</Text>
                        </Box>
                        
                        <ShValidatedInput
                            name="phone"
                            value={supplierInfo.phone}
                            onChange={handleChange}
                            error={fieldErrors.phone}
                        />
                    </Box>
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"flex-start",
                            justifyContent:"space-between",
                            gap:7
                        }}
                    >

                        <Box
                            sx={{
                                width:120,
                                flexShrink:0,
                                height:32,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text light>{t("email")}</Text>
                        </Box>
                        
                        <ShValidatedInput
                            name="email"
                            value={supplierInfo.email}
                            onChange={handleChange}
                            error={fieldErrors.email}
                        />
                    </Box>
                </Box>
                
                <Box
                    sx={{
                        mt:3,
                    }}
                >
                    <Text bold> Adres gegevens</Text>
                </Box>

                <Box
                    sx={{

                        display:"flex",
                        flexDirection:"column",
                        gap:2
                    }}
                >
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"flex-start",
                            justifyContent:"space-between",
                            gap:7,
                            mt:1,
                        }}
                    >

                        <Box
                            sx={{
                                width:120,
                                flexShrink:0,
                                height:32,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text light>{t("street")}</Text>
                        </Box>
                        
                        <ShValidatedInput
                            name="street"
                            value={supplierInfo.street}
                            onChange={handleChange}
                            error={fieldErrors.street}
                        />
                    </Box>
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"flex-start",
                            justifyContent:"space-between",
                            gap:7,
                        }}
                    >

                        <Box
                            sx={{
                                width:120,
                                flexShrink:0,
                                height:32,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text light>{t("house_nr")}</Text>
                        </Box>
                        <Box sx={{
                            display:"flex",
                            width:1,
                            gap:2,
                        }}>
                            <ShValidatedInput
                                name="number"
                                value={supplierInfo.number}
                                onChange={handleChange}
                                error={fieldErrors.number}
                            />
                        </Box>
                    </Box>

                    
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"flex-start",
                            justifyContent:"space-between",
                            gap:7,
                        }}
                    >

                        <Box
                            sx={{
                                width:120,
                                flexShrink:0,
                                height:32,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text light>{t("postal")}</Text>
                        </Box>
                        
                        <ShValidatedInput
                            name="postal"
                            value={supplierInfo.postal}
                            onChange={handleChange}
                            error={fieldErrors.postal}
                        />
                    </Box>

                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"flex-start",
                            justifyContent:"space-between",
                            gap:7,
                        }}
                    >

                        <Box
                            sx={{
                                width:120,
                                flexShrink:0,
                                height:32,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text light>{t("province")}</Text>
                        </Box>
                        
                        <ShValidatedInput
                            name="province"
                            value={supplierInfo.province}
                            onChange={handleChange}
                            error={fieldErrors.province}
                        />
                    </Box>

                    
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"flex-start",
                            justifyContent:"space-between",
                            gap:7,
                        }}
                    >

                        <Box
                            sx={{
                                width:120,
                                flexShrink:0,
                                height:32,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text light>{t("city")}</Text>
                        </Box>
                        
                        <ShValidatedInput
                            name="city"
                            value={supplierInfo.city}
                            onChange={handleChange}
                            error={fieldErrors.city}
                        />
                    </Box>

                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"flex-start",
                            justifyContent:"space-between",
                            gap:7,
                        }}
                    >

                        <Box
                            sx={{
                                width:120,
                                flexShrink:0,
                                height:32,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text light>{t("country")}</Text>
                        </Box>
                        <ShValidatedInputDropdown
                                name="country"
                                displayName="name"
                                changeField="alpha2"
                                options={countries ?? null}
                                noSelection={t("choose_option")} 
                                onChange={handleChange}
                                selected={countries?.findIndex(country => (supplierInfo.country && supplierInfo.country === country.alpha2) || (country.alpha2 === countryCodeNL)) ?? -1}
                                error={fieldErrors.country}
                            />
                    </Box>
                </Box>

                </Box>

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"green"}
                    block
                    variant="contained" 
                    onClick={() => SaveSupplier()}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddSupplier;
