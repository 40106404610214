import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import TopRight from "../../positionals/TopRight";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ShLabel from "../../label/ShLabel";
import { ClearIcon, ClockIcon } from "@mui/x-date-pickers";
import Icon from "../../icons/Icon";
import { GridCheckIcon } from "@mui/x-data-grid";
import { getShipperColorSettingsByType } from "../../../Streams/StreamShipperSettings";

import { ReactComponent as DhlLogo } from '../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../../styles/svg/postnl.svg';
import { ReactComponent as BolLogo } from '../../../../styles/svg/bol.svg';

export const WarehouseStreamCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();

    const colorSettings = getShipperColorSettingsByType(props.shipper?.type);

    const ShipperLogoMap = {
        postnl: PostNLLogo,
        dhl: DhlLogo,
        dpd: DpdLogo,
        bol: BolLogo,
      };

    const renderShipperLogo = (shipperId) => {

        // Get the logo component based on shipper type
        const LogoComponent = !props.skeleton ? ShipperLogoMap[props.shipper?.type] : null;

        // Render the logo component if it exists, or null otherwise
        return LogoComponent ? <LogoComponent className={props.shipper?.type} /> : null;

    };


    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick} sx={{p:0,pl:2}}>
            <Box>
                <Box
                    sx={{
                        display:"flex",
                        // alignItems:"center",
                        // justifyContent:"center",
                    }}
                >
                    <Box
                        sx={{
                            pt:2,
                            display:"flex",
                            flexDirection:"column",
                            gap:3,
                            flex:1,
                        }}
                    >
                        <Box display={"flex"} flexDirection={"column"}
                        sx={{
                            pb:0,
                        }}>
                            {props.skeleton ?
                                <ShDoubleTextSkeleton/>
                                :    
                                <>
                                    <Text bold>{props.name}</Text>
                                    <Text>{t(props.pickingprocesstype)}</Text>
                                </>
                            }
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                pb:2,
                            }}
                        >
                            <Text light>{t("size_and_weight")}</Text>
                            {props.skeleton ?
                                <>
                                    <ShDoubleTextSkeleton/>
                                </>
                            :    
                                <>
                                    <Text>{props.minVolume ?? 0} - {props.maxVolume ?? 0} mm3</Text>
                                    <Text>{props.minWeight ?? 0} - {props.maxWeight ?? 0} {t("gram")}</Text>
                                </>
                            }

                        </Box>
                    </Box>


                    <Box 
                        sx={{
                            borderLeft: `2px solid ${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100]}`,
                            justifyContent:"center",
                            display:"flex",
                            gap:1,
                            flexDirection:"column",
                            px:2,
                            flexGrow:0,
                            width:166,
                        }}
                    >
                        <ShLabel palette="normal" borderless sx={{flex:"unset"}} size={32}>{props.reachType?.description}</ShLabel>
                        <ShLabel 
                            borderless
                            sx={{
                                pl:0.5,
                                flex:"unset",
                                color: props.shipper ? colorSettings.base : colors.grey[100],
                                background: props.shipper ? colorSettings.background : colors.grey[100],
                                display:"flex",
                                alignItems:"center"
                            }} 
                            size={32} 
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    color:props.shipper ? null : colors.txt["primary"],
                                    background:props.shipper ? colorSettings.streamrulehover : colors.grey[400],
                                    borderRadius:5,
                                    width:24,
                                    height:24,
                                    display:"flex",
                                    justifyContent:"center",
                                    flexShrink:0,
                                    minWidth:24,
                                    "& .postnl" : {
                                        width:20,
                                        height:20,
                                    },

                                    "& .dpd" : {
                                        width:20,
                                        height:20,
                                    },
                                    "& .dhl" : {
                                        width:20,
                                        height:20,
                                    },
                                    "& .bol" : {
                                        width:20,
                                        // width:24,
                                        height:10,
                                    },
                                }}
                            >
                                {renderShipperLogo()}
                            </Box>
                            <Box
                                sx={{
                                    mx:"auto",
                                    fontWeight:"bold"
                                }}
                            >
                                {props.streamrules?.length ?? 0} {t("rules")}
                            </Box>
                        </ShLabel>
                        <ShLabel sx={{flex:"unset",pl:0.5,display:"flex",gap:1,alignItems:"center",textAlign:"center" }} palette="normal" borderless size={32} variant={props.deliverytimePriority ? "green": "red"}>
                            <Icon theme={props.deliverytimePriority ? "green": "red"} rounded size="s24 contrast">
                                <ClockIcon/>
                            </Icon>
                            <Box
                                sx={{
                                    mx:"auto"
                                }}
                            >
                                {props.deliverytimePriority ? t("prio") : t("no_prio_small")}
                            </Box>
                        </ShLabel>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}
