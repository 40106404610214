import React, { useRef, useState,createRef,Component,componentDidMount, forwardRef, useEffect, useContext, useCallback, useMemo } from "react";
import { Box, IconButton, Typegraphy, Typography, useTheme, Button, Icon, debounce } from '@mui/material';
import { Link, useLocation } from "react-router-dom";
import { tokens } from "../../../theme";
// import { createRef } from "react";
import cx from 'classnames';
import { MenuStateContext } from "../../../contexts/MenuState";

import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import CloseOverlayBtn from "../buttons/CloseOverlayBtn";
import Shbutton from "../buttons/Shbutton";
// import Inventory2Icon from '@mui/icons-material/Inventory2';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Subheading from "../text/Subheading";
import Text from "../text/Text";
// import Spheadingrow from "./Spheadingrow";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import TabViewBody from "./TabViewBody";
import { useDispatch, useSelector } from "react-redux";
import { resetFilter, setActiveTabChanged, setPreviousRoute } from "../../../newapi/global/filter/gridFilterSlice";
import { useTranslation } from "react-i18next";
import { TabContextProvider } from "../../../contexts/TabContext";

function until(conditionFunction) {

    const poll = resolve => {
        if(conditionFunction()) resolve();
        else setTimeout(_ => poll(resolve), 10);
    }

    return new Promise(poll);
}

const TabView3Content = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const dispatch =useDispatch();
    const configViews = props.tabConfig;
    const [dataViews, addNewDataView] = useState([]);
    const [activeChild, setActiveChild] = useState(null);
    const [grandchildren, setGrandchildren] = useState([]);

    const [subpanelActive, setSubpanelActive] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const viewType = props.viewType ?? "tabs";

    const showStartIconOnBigScreens = viewType === "bodytabs";

    const replaceView = (data) => {
        addNewDataView(dataViews.map(item => {
            if(item.title === data.title) {
                return data;
            }
            return item;
        }));
    }

    
    const handleEvent = useCallback((index) => {
        setActiveChild(index);
        if (window.innerWidth <= 767) {
            setSubpanelActive(true);
        }
    }, []); // Add any dependencies if these change dynamically
    
    

    useEffect(() => {
        if(props.tabConfig){
            addNewDataView(Object.keys(configViews).map(key => ({
                ...configViews[key],
                key, // Include the original key if needed
            })));
        }
    },[props.tabConfig])
    
    useEffect(() => {
        // Convert all children to an array for easier manipulation
        let fragmentChildren = React.Children.toArray(props.children);
    
        if(!props.oneView) {
            // Check if the first child is a fragment and if so, use its children instead
            if (fragmentChildren.length === 1 && fragmentChildren[0].type === React.Fragment) {
                fragmentChildren = React.Children.toArray(fragmentChildren[0].props.children);
            }
        
            const activeChildComponent = fragmentChildren.map((child, index) => {
                // Assuming activeChild is the index of the child you want to activate
                if (index === activeChild) {
                    // This is the active child, clone it with additional props
                    return React.cloneElement(child, {
                        key: `active-${index}`,
                        isActive: true,
                        setActiveChild, // Assuming setActiveChild is a function passed as a prop or defined in this component
                        replaceView, // Assuming replaceView is a function passed as a prop or defined in this component
                    });
                }
                return null; // This ensures only the active child is processed
            }).filter(child => child !== null); // Remove nulls, keeping only the active child
            // Update the state to render only the active child component
            setGrandchildren(activeChildComponent);
        } else {

        const grandchildrenWithExtraProp = fragmentChildren.map((child, index) => {
            if (child.props.children) {
                return React.Children.map(child.props.children, (grandchild, grandChildIndex) => {
                    return React.cloneElement(grandchild, { 
                        key: `active-${index}`,
                        isActive: true,
                        setActiveChild, // Assuming setActiveChild is a function passed as a prop or defined in this component
                        replaceView, // Assuming replaceView is a function passed as a prop or defined in this component
                    });
                });
            }
            return child;
        });
        setGrandchildren(grandchildrenWithExtraProp);
        }
    }, [props, activeChild]);

    useEffect(() => {
        if(dataViews.length > 0 && activeChild === null) {
            setActiveChild(0);
        }
    },[dataViews]);


    // useEffect(() => {
    //     dispatch(resetFilter());
    // }, [activeChild, dispatch]);


    // useEffect(() => {
    //   window.addEventListener("resize", handleResize);
      
    //   return () => {
    //     window.removeEventListener("resize", handleResize);
    //   }
    // },[])

    useEffect(() => {
        const handleResizeDebounced = debounce(() => {
            setWindowWidth(window.innerWidth);
        }, 100); // Debounce with a 100ms delay
    
        window.addEventListener("resize", handleResizeDebounced);
        
        return () => {
            window.removeEventListener("resize", handleResizeDebounced);
        };
    }, []);

    const tabStyling = useMemo(() => {
        return viewType == "tabs" ?
            {
                overflowX:"auto",
                paddingX:4,
                display:"flex",
                flexWrap:"nowrap",
                gap:3,
                background: colors.bg["tertiary"],
                minHeight:"40px",
                "@media screen and (max-width: 47.9375em)" : {
                    display:!props.asTab ? "none" : null
                }
            } : viewType == "sidepanel" || viewType == "popup" ?{
                overflowX:"auto",
                paddingX:3,
                display:"flex",
                flexWrap:"nowrap",
                gap:3,
                "@media screen and (max-width: 47.9375em)" : {
                    display:!props.asTab ? "none" : null
                }
            } : viewType === "bodytabs" ? {
                display:"flex",
                flexDirection:"column",
                paddingRight:4,
                gap:1,
                "@media screen and (max-width: 47.9375em)" : {
                    display:!props.asTab ? "none" : null
                }
            } : null;
    },[viewType, props.asTab, theme.palette.mode]);

    return (
        <Box
            sx={props.sx}
        >
            {/* Header tabs, only visible on 768px+ */}
            <Box
                sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    background: viewType !== "bodytabs" ? colors.bg["tertiary"] : null,
                    ...(props.content?.styling?.header?.background && {
                      background: props.content.styling.header.background,
                    }),
                    
                }}
            >
                <Box sx={tabStyling}>
                    {dataViews.map((view, index) => (
                        typeof view.position === "undefined" || (typeof view.position !== "undefined" && view.position !== "right") ?
                            <Shbutton key={index+"dvshol"}
                                onClick={() => handleEvent(index)}
                                isTab={true} 
                                active={index === activeChild}  
                                startIcon={showStartIconOnBigScreens ? view.icon : null}  
                                className={props.tabClasses + " " + view.tabClasses} 
                                variant="text"
                                tabColor={props.tabColor ?? null}
                            >
                                {t(view.title)}
                            </Shbutton>
                        : null
                    ))}
                </Box>
                <Box sx={tabStyling}>
                    {dataViews.map((view, index) => (
                        typeof view.position !== "undefined" && view.position === "right" ?
                            <Shbutton key={index+"dvshor"}
                                onClick={() => handleEvent(index)}
                                isTab={true} 
                                active={index === activeChild}  
                                startIcon={showStartIconOnBigScreens ? view.icon : null}  
                                className={props.tabClasses + " " + view.tabClasses} 
                                variant="text"
                                tabColor={props.tabColor ?? null}
                            >
                                {view.title}
                            </Shbutton>
                        : null
                    ))}
                </Box>
            </Box>

            <Box sx={
                viewType === "tabs" ? {
                    position:"relative",
                    height: "calc(100% - 40px)",
                    flex:1,
                    display:"flex",
                    alignItems:"stretch",
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"unset",
                    }
                } : viewType === "bodytabs" ? {
                    width:1, 
                    borderTopLeftRadius:"24px",
                    borderTopRightRadius:"24px", 
                    background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    // background:"purple",
                    flex:1,
                    overflow:"auto",
                    display:"flex",
                    flexDirection:"column",
                    "@media screen and (max-width: 47.9375em)" : {
                        background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    }
                } : {
                    width:1, 
                    borderTopLeftRadius:"16px",
                    borderTopRightRadius:"16px", 
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    flex:1,
                    overflow:"auto",
                    "@media screen and (min-width: 47.9375em) and (max-width: 63.9375em)" : {
                        marginBottom: !props.hideFooter ? viewType !== "popup" ? "80px" : null : null
                    }
                }}
            >
                {/* If phone show btns */}
                {!props.asTab ?
                    <Box
                        sx={{
                            display:"none",
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"flex",
                                flexWrap:"wrap",
                                gap:2,
                                padding:3,
                                marginBottom:!props.hideFooter ? "80px" : null
                            }
                        }}
                    >
                        {dataViews.map((view, index) => (
                            <Shbutton key={index+"dvshat"}
                            onClick={() => handleEvent(index)}
                            isTab={true} startIcon={view.icon} endIcon={<KeyboardArrowRightIcon/>} variant="text">{t(view.title)}</Shbutton>
                        ))}
                    </Box>
                :null}
                
                <TabViewBody
                    activeChild={activeChild}
                    activeTab={dataViews[activeChild]}
                    // activeView={}
                    // activeTab={activeTab} 
                    subpanelActive={subpanelActive}
                    setSubpanelActive={setSubpanelActive} 
                    // setActiveTab={setActiveTab}
                    viewType={viewType}
                    {...props}
                >
                    
                    {grandchildren}
                </TabViewBody>
            </Box>
        </Box>
    );
};

const TabView3 = (props) => {
    return (
        <TabContextProvider>
            <TabView3Content {...props}/>
        </TabContextProvider>
    )
}
export default TabView3;