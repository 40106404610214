import { useTranslation } from "react-i18next";

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal";
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper";
import { useAddNewRelationMutation, useEditRelationMutation } from "../../../../newapi/global/relation/relationSlice";
import ContactDetails from "./views/ContactDetails";
import LocationDetails from "./views/LocationDetails";
import { useCreateRootRelationMutation  } from "../../../../newapi/global/relation/relationRootSlice";
import { useState } from "react";
import { showToastMessage, showToastMessagePostRequest } from "../../../../utils/toasts";

const MpAddRelation = (props) => {
    const {t} = useTranslation();
    const [saving, setSaving] = useState(false);

    const [CreateRelation,{isLoading}] = useAddNewRelationMutation();
    const [UpdateRelation,{isLoading:isLoadingUpdate}] = useEditRelationMutation();
    const [CreateRootRelation,{isLoading:isLoadingRoot}] = useCreateRootRelationMutation();
    const onSave = async (data) => {
        if(!saving) {
            setSaving(true);
            try{
                const response = await CreateRelation(data);
                if(response && response.data.success) {
                    const newData = {...data, externalId: response.data.id};
                    const responseRoot = await CreateRootRelation(newData);
                    const responseUpdate = await UpdateRelation({id: response.data.id, externalId: responseRoot.data.id});
                } else {
                    showToastMessagePostRequest(response);
                    props.handleClose();
                }
            } catch(error) {
                showToastMessage("error",t("error_saving_relation"));

            } finally {
                props.handleClose();
            }
        }
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} isModal title={t("add_relation")}>
                <ContactDetails/>
                <LocationDetails/>
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddRelation;