import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import ShLabel from "../../../../theme/label/ShLabel";
import AddIcon from '@mui/icons-material/Add';
import Text from "../../../../theme/text/Text";

import { useEffect, useRef, useState } from "react";
import CropFreeIcon from '@mui/icons-material/CropFree';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";

import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import OrderLinesCard from "../../../../theme/cards/orders/OrderLinesCard";
import {useTranslation} from "react-i18next";
import { useGetOrderLinesQuery } from "../../../../../newapi/order/ordersSlice";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShLabelSkeleton from "../../../../theme/skeleton/ShLabelSkeleton";
import { useReserverOrderLineMutation } from "../../../../../newapi/order/ordersSlice";
import { showToastMessageUpdateRequest, showToastMessageRequestError } from "../../../../../utils/toasts";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import SPStockDetails from "../../SpStockDetails/SPStockDetails";

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained" endIcon={<AddIcon/>}>{t("save")}</Shbutton>
        </ButtonGroup>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const OrderLines = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isExtended, setIsExtended] = useState(false);

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.product.id);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    

    const [ReserveOrderLine,{syncIsLoading}] = useReserverOrderLineMutation();
    const handleReserveOrderLine = async (params) => {
        try {
            const response = await ReserveOrderLine(params).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageRequestError(t("reserve_failed"), t, {success: false, error: err.error});
        }
    };

    const columns = [
        {field: "amount", headerName: t("quantity"), renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box paddingLeft={0}>
                    <Text semibold>{params.row.amount}</Text>
                </Box>
            )
        }},
        {field: "product.description", headerName: t("description"), flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text semibold>{params.row.product.description}</Text>
                </Box>
            )
        }},
        {field: "product.sku", headerName: t("sku"),flex:1,renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box onClick={() => getProductDetails(params.row)}>
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product.sku}</Text>
                </Box>
            )
        }},
        {field: "backorder", headerName: t("backorder"),flex:1,renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.row.backorder ?? 0;
        }},
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                let status = params.row.state;
                let labelcolor = "";
                let labeltext = "";
                switch(status){
                    case "open":
                        labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
                        labeltext = t('order_can_be_picked');
                        break;
                    case "active":
                        labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
                        labeltext = t('order_can_be_picked');
                        break;
                    case "shipped":
                        labelcolor = "green"
                        labeltext = t('shipped');
                        break;
                    case "fullypicked":
                        labelcolor = "green"
                        labeltext = t('fullypicked');
                        break;
                    case "blocked":
                        labelcolor = "red"
                        labeltext = t('blocked');
                        break;
                    case "cancelled":
                        labelcolor = "grey"
                        labeltext = t('cancelled');
                        break;
                    case "replenish":
                        labelcolor = "purple"
                        labeltext = t('replenish');
                        break;
                    case "backordered":
                        labelcolor = "pink"
                        labeltext = t('backordered');
                        break;
                    case "picking_in_progress":
                        labelcolor = "purple"
                        labeltext = t('picking_in_progress');
                        break;
                    default:
                        labelcolor = "orange"
                        labeltext = t('unknown');
                        break;
                }
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {labeltext}
                    </ShLabel>
                  );
              }
        },
    ];

    if (props.canReserveOrderLines) {
        columns.push({
            field: "actions", 
            headerName: "",
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                if (params.row.reserved) {
                    return <ShLabel size={32} palette="normal" variant={"blue"}>
                    {t('reserved')}
                  </ShLabel>;
                }

                return <Shbutton 
                    className={"s32"}
                    icon 
                    variant="contained" 
                    color="primaryGrey" 
                    type="button"
                    onClick={() => handleReserveOrderLine(params.row)}
                >
                    <ShoppingCartCheckoutIcon fontSize="small" />
                </Shbutton>;
            }
        });
    }

    columns.push({
        field: "phoneView", 
        headerName: "phoneView",
        cellClassName: "phone-column--cell",
        renderCell: (params) => {
            return (
                <OrderLinesCard onClick={() => getProductDetails(params.row)} skeleton={params.row.skeleton ?? false} {...params.row}/>
            )
        }
    });
    
    return (
        <Box height={1} display={"flex"} flexDirection={"column"} overflow={"auto"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetOrderLinesQuery}
                itemId={props.data}
                title={t("orderlines") }
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}>
            </ShDataGrid2>
            <SPStockDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                productId={productId}
            ></SPStockDetails>
            {/* <SpStockDetails
              isExtended={isExtended}
              setIsExtended={setIsExtended}
              productId={productId}
            //   data={productData}
            /> */}
        </Box>
    );
}

export default OrderLines;