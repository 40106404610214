import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import ShSwitch from "../../theme/inputs/ShSwitch";
import Text from "../../theme/text/Text";
import { useTranslation } from "react-i18next";
import { useUpdateSettingMutation } from "../../../newapi/global/generalApiSlice";


// variants
//    1: default has both a title and subtitle
//    2: has no subtitle ans text is light
const BoolSetting = (props) => {
  const { title = true, subtitle = true, variant = 1, disabled = false } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [updateSetting] = useUpdateSettingMutation();


  const [setting, setSetting] = useState();
  const [val, setVal] = useState();

  const handleBoxClick = () => {
    if (disabled) return;
    setVal(!val);
    handleChange(!val);
  };

  const handleChange = async (value) => {
    if (props.onChange) {
      props.onChange(value);
    }
    
    await updateSetting({
      key: setting.key,
      value: value,
      warehouseId: setting.warehouseId,
    });
  };

  useEffect(() => {
    if(props.skey && typeof props.settings !== "undefined"){
        let xsetting = props.settings.find((setting) => setting.key === props.skey);
        if (!xsetting) {
            xsetting = { key: props.skey, value: false, warehouseId: -1 };
        }
        setSetting(xsetting);
        let xval = disabled ? false : xsetting.value === "true";
        setVal(xval);
    }
  }, [props.settings, props.skey, disabled]);

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 4,
        height:40,
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      onClick={handleBoxClick}
    >
      <Box
        sx={{
          display:"flex",
          flexDirection:"column",
          overflow:"hidden",
          cursor:"pointer"
        }}
      >
        {title && <Text noBreak light={variant === 2} size={variant === 2 ?? "fs13"} semibold={variant === 1} >{t(`${setting?.key}_title`)}</Text>}
        {subtitle && variant !== 2 && <Text light>{t(`${setting?.key}_subtitle`)}</Text>}
      </Box>
      <ShSwitch
        checked={val}
        disabled={disabled}
        onChange={(e) => {
          e.stopPropagation();
          if (!disabled) handleChange(e.target.checked);
        }}
        color={theme.palette.mode === "dark" ? "blue" : "primary"}
      />
    </Box>
  );
};

export default BoolSetting;