import { Box, IconButton, InputBase, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import cx from "classnames"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import NumberInput from "../../../../theme/inputs/NumberInput";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import CropFreeIcon from '@mui/icons-material/CropFree';
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { toast } from 'react-toastify';
import { useUpdateProductMutation } from "../../../../../newapi/warehouse/productSlice";
import SpDesktopFooter from "../../../../theme/sidepanel2/SidepanelFooter";

// const GridActions = (props) => {
//     return (
//         <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
//             <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>Save</Shbutton>
//         </Box>
//     )
// }

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [updateProduct,{isLoading, isSuccess}] = useUpdateProductMutation();

    const handleButtonClick = () => {
        const product = { 
            length: props.length, 
            width: props.width, 
            height: props.height, 
            weight: props.weight
        };
        updateProduct({ id: props.id, data: product })
            .unwrap()
            .then((updatedLocation) => {
                // Handle the success state
                toast.success(t('update_success'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch((error) => {
                // Handle the error state
                toast.error(t('update_failed'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

    };

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={handleButtonClick}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const SizeAndWeight = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [length, setLength] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [weight, setWeight] = useState(0);
    const [volumeweightcat, setVolumeWeightCat] = useState("");
    const  { t } = useTranslation();

    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions 
                id={props.data.id}
                length={length}
                width={width}
                height={height}
                weight={weight}></GridActions>
        });
    },[props.data,length,width,height,weight]);
    
    useEffect(() => {
        if(props.data){
            setLength(props.data.length);
            setWidth(props.data.width);
            setHeight(props.data.height);
            setWeight(props.data.weight);
            setVolumeWeightCat(props.data.volumeWeightCategory?.name);
            setDataHasBeenSet(true);
        }
    },[props.isActive,props.data]);


    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3} overflow={"auto"}>
            <Dropdown title={t('dimensions_and_weight')} subTitle={volumeweightcat} open>
                
                <Box
                    sx={{
                        display:"flex",
                        gap:4,
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column",
                            gap:2
                        }
                    }}
                >
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                                gap:3
                            }
                        }}
                        >
                        <Box
                            sx={{
                                "@media screen and (max-width: 48em)" : {
                                    width:140
                                }
                            }}>
                            <Text light>
                                {t('length')}
                            </Text>
                            <Text italic>&nbsp;(mm)</Text>
                        </Box>
                        <NumberInput change={setLength} value={length}></NumberInput>
                    </Box>
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                                gap:3
                            }
                        }}
                        >
                        <Box
                            sx={{
                                "@media screen and (max-width: 48em)" : {
                                    width:140
                                }
                            }}>
                            <Text light>
                                {t('width')}
                            </Text>
                            <Text italic>&nbsp;(mm)</Text>
                        </Box>
                        <NumberInput change={setWidth} value={width}></NumberInput>
                    </Box>
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                                gap:3
                            }
                        }}
                        >
                        <Box
                            sx={{
                                "@media screen and (max-width: 48em)" : {
                                    width:140
                                }
                            }}>
                            <Text light>
                                {t('height')}
                            </Text>
                            <Text italic>&nbsp;(mm)</Text>
                        </Box>
                        <NumberInput change={setHeight} value={height}></NumberInput>
                    </Box>
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                                gap:3
                            }
                        }}
                        >
                        <Box
                            sx={{
                                "@media screen and (max-width: 48em)" : {
                                    width:140
                                }
                            }}>
                            <Text light>
                                {t('weight')}
                            </Text>
                            <Text italic>&nbsp;(gram)</Text>
                        </Box>
                        <NumberInput change={setWeight} value={weight}></NumberInput>
                    </Box>
                </Box>

            </Dropdown>
            <SpDesktopFooter>
                <GridActions
                    id={props.data?.id}
                    length={length}
                    width={width}
                    height={height}
                    weight={weight}
                />
            </SpDesktopFooter>

            {/* <Dropdown isLast title="Settings" subTitle="If these dimensions are entered, they are decisive for determining the sender" open={window.innerWidth > 768}>
                
                <Box
                    sx={{
                        display:"flex",
                        gap:4,
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column",
                            gap:2
                        }
                    }}
                >
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                                gap:3
                            }
                        }}
                        >
                        <Box
                            sx={{
                                "@media screen and (max-width: 48em)" : {
                                    width:112
                                }
                            }}
                        >
                            <Text light>
                                Length
                            </Text>
                            <Text italic>&nbsp;(mm)</Text>
                        </Box>
                        <NumberInput value={weight}></NumberInput>
                    </Box>
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                                gap:3
                            }
                        }}
                        >
                        <Box
                            sx={{
                                "@media screen and (max-width: 48em)" : {
                                    width:112
                                }
                            }}>
                            <Text light>
                                Width
                            </Text>
                            <Text italic>&nbsp;(mm)</Text>
                        </Box>
                        <NumberInput value={weight}></NumberInput>
                    </Box>
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                                gap:3
                            }
                        }}
                        >
                        <Box
                            sx={{
                                "@media screen and (max-width: 48em)" : {
                                    width:112
                                }
                            }}>
                            <Text light>
                                Height
                            </Text>
                            <Text italic>&nbsp;(mm)</Text>
                        </Box>
                        <NumberInput value={weight}></NumberInput>
                    </Box>
                </Box>

            </Dropdown> */}

        </Box>
    );
}

export default SizeAndWeight;