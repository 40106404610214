import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material';
import { tokens } from "../../../theme";
import TabView from "../TabViews/TabView";
import TabView3 from "../TabView3/TabView3";
// import TabView from "../Tabview/TabView";


const SidepanelBody= (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [tvHeight, setTvHeight] = useState(200);
    
    useEffect(() => {
        if(props.header && props.header.current) {
            setTvHeight((window.innerHeight - (props.header.current.clientHeight + (!props.hideFooter ? 80 : 0)))/100*85);
        }
    },[props.header,window.innerHeight])

    return (
        <TabView3
            tabConfig={props.tabConfig}
            className={"sh-sp-tv"}
            {...props}
            title={props.title}
            viewType="sidepanel"
            tabColor = {props.content.tabColor}
            
            sx={{
                display:"flex",
                flexDirection:"column",
                background:`${colors.bg["tertiary"]}`,
                height:1,
                overflow:"hidden",
                flexDirection:"column",
                "@media screen and (max-width: 63.9375em)" : {
                    height: !props.oneView ? 1 : "unset",
                    maxHeight:tvHeight
                }
            }}
        >
            {props.children}
        </TabView3>
    );
};
export default SidepanelBody;