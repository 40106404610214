import InfoIcon from '@mui/icons-material/Info';

export const spInvoiceDetailsConfig = {
    invoiceLines: {
        title: 'content',
        icon: <InfoIcon/>,
    },
    invoiceProducts: {
        title: 'mutations',
        icon: <InfoIcon/>,
    },
    logs: {
        title: 'logs',
        icon: <InfoIcon/>,
    }
};
