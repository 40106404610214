
// import { useGetShippersSelectQuery } from "../../../../../newapi/shipper/shipperSlice";
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as DhlLogo } from '../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../styles/svg/postnl.svg';
import { ReactComponent as BolLogo } from '../../styles/svg/bol.svg';
import { Box, ButtonBase, useTheme } from '@mui/material';
import Text from '../theme/text/Text';
import Subheading from "../theme/text/Subheading";
import { DND_STREAM_RULE, conditionKeys, conditions, dropdownKeys, getMobileCondition, getShipperColorSettings, getStreamRuleKey, streamRuleLineKeys } from "./StreamShipperSettings";
import { useGetShippersSelectQuery } from '../../newapi/shipper/shipperSlice';
import { DropdownBase } from '../theme/dropdowns/Dropdown';
import { useDrag, useDrop } from 'react-dnd';
import { useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../theme';
import TextInput from '../theme/inputs/TextInput';
import { useSelector } from 'react-redux';
import { countriesFromLocale } from '../../utils/countries';
import { localeSettings } from '../../utils/locales';
import { useGetCarriersTypesQuery } from '../../newapi/carrier/carrierSlice';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const CustomComponent = (props) => {
    return (
      <Box>
        {props.index}&nbsp;
        {props.isSelected ? "yes " : "no "}
        {props.option}
      </Box>
    );
};

const ShipperLogoMap = {
    postnl: PostNLLogo,
    dhl: DhlLogo,
    dpd: DpdLogo,
    bol: BolLogo,
  };

const StreamRule = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [disableDnD, setDisableDnD] = useState(true);

    const {attributes,listeners,setNodeRef,transform,transition} = useSortable({id: props.streamRule.id, disabled: disableDnD})
    
    const style={
        transition,
        transform:CSS.Transform.toString(transform)
    }
    

    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const handleResize = (event) => {
        setWindowSize(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    
    const colorSettings = getShipperColorSettings(props.streamRule.shipperId, props.shippers);

    const renderShipperLogo = (shipperId) => {
        // const shipperType = props.shippers.find((el) => el.id === shipperId).type;
        // // props.shippers.find((el) => el.id === shipperId);
        // console.log(props.shippers.find((el) => el.id === shipperId).type);
        // console.log(shippersFlattened);
        // if(shippersFlattened.includes(shipperType)) {

        // }
        // switch (shipperId) {
        //   case 1:
        //     return <PostNLLogo className="postnl" />;
        //   case 2:
        //     return <DhlLogo className="dhl" />;
        //   case 3:
        //     return <DpdLogo className="dpd" />;
        //   default:
        //     return null; // or a default logo if you have one
        // }
         // Assuming you have a way to get the shipper type using shipperId
        const shipperType = props.shippers.find((el) => el.id === shipperId)?.type;

        // Get the logo component based on shipper type
        const LogoComponent = ShipperLogoMap[shipperType];

        // Render the logo component if it exists, or null otherwise
        return LogoComponent ? <LogoComponent className={shipperType} /> : null;

    };
    
    const updateStreamRuleLine = (lineIndex, newRuleLine) => {
        props.setStreamRules(currentStreamRules => {
            // Find the index of the rule that needs to be updated
            const ruleIndex = currentStreamRules.findIndex(rule => rule.id === props.streamRule.id);
    
            // If the rule is found, proceed to update
            if (ruleIndex > -1) {
                // Clone the stream rules array
                let updatedStreamRules = [...currentStreamRules];
                // Clone the streamrulelines array from the found rule
                let updatedStreamRuleLines = [...updatedStreamRules[ruleIndex].streamrulelines];
                // Replace the specific rule line with the new rule line
                updatedStreamRuleLines[lineIndex] = { ...updatedStreamRuleLines[lineIndex], ...newRuleLine };
                // Update the rule with the new streamrulelines
                updatedStreamRules[ruleIndex] = { ...updatedStreamRules[ruleIndex], streamrulelines: updatedStreamRuleLines };
    
                return updatedStreamRules;
            }
    
            // If the rule wasn't found, return the current state unchanged
            return currentStreamRules;
        });
    };

    const addStreamRuleLine = () => {
        props.setStreamRules(currentStreamRules => {
            // Find the index of the rule that needs to be updated
            const ruleIndex = currentStreamRules.findIndex(rule => rule.id === props.streamRule.id);
    
            // If the rule is found, proceed to update
            if (ruleIndex > -1) {
                // Clone the stream rules array
                let updatedStreamRules = [...currentStreamRules];
                // Clone the streamrulelines array from the found rule
                let updatedStreamRuleLines = [...updatedStreamRules[ruleIndex].streamrulelines];
                updatedStreamRuleLines.push({
                    key: -1,
                    nominator: -1,
                    value: -1
                });
                
                // Update the rule with the new streamrulelines
                updatedStreamRules[ruleIndex] = { ...updatedStreamRules[ruleIndex], streamrulelines: updatedStreamRuleLines };
    
                return updatedStreamRules;
            }
    
            return currentStreamRules;
        });
    };

    const removeStreamRuleLine = (lineIndex) => {
        props.setStreamRules(currentStreamRules => {
            // Find the index of the rule that needs to be updated
            const ruleIndex = currentStreamRules.findIndex(rule => rule.id === props.streamRule.id);
    
            // If the rule is found, proceed to update
            if (ruleIndex > -1) {
                // Clone the stream rules array
                let updatedStreamRules = [...currentStreamRules];
                // Clone the streamrulelines array from the found rule
                let updatedStreamRuleLines = [...updatedStreamRules[ruleIndex].streamrulelines];
                updatedStreamRuleLines.splice(lineIndex,1);

                // Update the rule with the new streamrulelines
                updatedStreamRules[ruleIndex] = { ...updatedStreamRules[ruleIndex], streamrulelines: updatedStreamRuleLines };
    
                return updatedStreamRules;
            }
    
            return currentStreamRules;
        });
    }

    const removeStreamRule = (streamRule) => {
        props.setStreamRules(currentStreamRules => {
            // Find the index of the rule that needs to be updated
            const ruleIndex = currentStreamRules.findIndex(rule => rule.id === streamRule.id);
            if(ruleIndex > -1) {
                // Clone the stream rules array
                let updatedStreamRules = [...currentStreamRules];
                updatedStreamRules.splice(ruleIndex,1);

                return updatedStreamRules;
            }
        });

    }

    const selectShipper = (selected) => {
        props.setStreamRules(currentStreamRules => {
            // Find the index of the rule that needs to be updated
            const ruleIndex = currentStreamRules.findIndex(rule => rule.id === props.streamRule.id);
    
            // If the rule is found, proceed to update
            if (ruleIndex > -1) {
                // // Clone the stream rules array
                let updatedStreamRules = [...currentStreamRules];
                
                updatedStreamRules[ruleIndex].shipperId = selected;
    
                return updatedStreamRules;
            }
    
            return currentStreamRules;
        });
    }
    
    const selectRuleLineKey = (selectedKey, index) => {
        const ruleLine = { ...props.streamRule.streamrulelines[index] }; // Create a copy of the ruleLine
        const previousKey = ruleLine.key;
        const newKey = streamRuleLineKeys[selectedKey];

        const isPreviousConditionKey = conditionKeys.includes(previousKey);
        const isNewConditionKey = conditionKeys.includes(newKey);
        const isPreviousDropdownKey = Object.values(dropdownKeys).includes(previousKey);
        const isNewDropdownKey = Object.values(dropdownKeys).includes(newKey);

        // Reset value and nominator if the type of key is changing from condition to dropdown or vice versa
        if ((isPreviousConditionKey !== isNewConditionKey) || (isPreviousDropdownKey !== isNewDropdownKey)) {
            ruleLine.value = -1;
            ruleLine.nominator = -1;
        }

        // Reset value if switching between specific dropdown keys
        if ((previousKey === dropdownKeys.PackingAdvice && newKey === dropdownKeys.destination) ||
            (previousKey === dropdownKeys.destination && newKey === dropdownKeys.PackingAdvice)) {
            ruleLine.value = -1;
        }

        ruleLine.key = newKey;

        // Update the stream rule in the parent component's state
        updateStreamRuleLine(index, ruleLine);
    };

    const selectRuleLineCondition = (selectedNominator, index) => {
        updateStreamRuleLine(index, { nominator: conditions(props.streamRule.streamrulelines[index].key)[selectedNominator] });
    };

    const selectRuleLineValue = (selectedValue, index) => {
        updateStreamRuleLine(index, { value: selectedValue });
    };


    return (
        
        <Box
            style={style}
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            className={cx(``, {
            //   'is-dragging' : isDragging
            })}
            sx={{
                // background: "#FFF8F2",
                background: colorSettings.background,
                borderRadius:3,
                padding:2,
                width:500,
                overflow:"hidden",

                "& .postnl" : {
                    width:24,
                    height:24,
                },

                "& .dpd" : {
                    width:24,
                    height:24,
                },
                "& .dhl" : {
                    width:24,
                    height:24,
                },
                "& .bol" : {
                    width:32,
                    // width:24,
                    height:16,
                },

                "&.is-dragging": {
                    opacity:0,
                    // transform: 'rotate(-5deg)'
                },
                "@media screen and (max-width: 47.9375em)" : {
                    display:"flex",
                    flexDirection:"column",
                    // justifyContent:"flex-end",
                    alignItems:"flex-end"
                }
            }}
        >
            {/* Header */}
            {/* Colli */}
            <Box
                sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    alignItems:"center"
                }}
            >

                <DropdownBase
                    displayName={"name"} 
                    onChange={(selected) => selectShipper(selected)}
                    noSelection={""} //Custom text when nothing is selected
                    options={props.shippers} // array
                    selected={props.shippers.find(shipper => shipper.id === props.streamRule.shipperId)?.id}
                    closeOnSelection
                    maxHeight={"unset"}
                    valueKey={"id"}
                    disallowDeselection
                >
                    <Box 
                        sx={{
                            display:"inline-flex",
                            alignItems:"center",
                            gap:1,
                            height:32,
                            ml:"-8px",
                            pl:"8px",
                            pr:"8px",
                            borderRadius:4,
                            "&:hover": {
                                // background:"#FFE9D6",
                                background: colorSettings.streamrule,
                                cursor:"pointer",
                            },
                            // "@media screen and (max-width: 47.9375em)" : {
                            //     display:"inline",
                            //     width:"unset",
                            //     // width:1,
                            //     // display:"flex",
                            //     // justifyContent:"flex-end",
                            //     ml:0,
                            //     mr:"-8px"
                            // }
                        }}
                    >
                        {renderShipperLogo(props.streamRule.shipperId)}
                        {/* <PostNLLogo className="postnl"/> */}
                        <Text bold>{props.shippers.find(shipper => shipper.id === props.streamRule.shipperId).name}</Text>
                        {/* <Subheading>Postnl</Subheading> */}
                    </Box>
                </DropdownBase>
            
                <ButtonBase
                    onClick={() => removeStreamRule(props.streamRule)}
                    sx={{
                        color:colors.txt["secondary"],
                        width:32,
                        height:32,
                        borderRadius:4
                    }}
                >
                    <DeleteOutlineOutlinedIcon/>
                </ButtonBase>
            </Box>

            {/* Stream rule lines wrapper*/}
            <Box
                sx={{
                    mt:2,
                    display:"flex",
                    flexDirection:"column",
                    gap:1,
                    width:1
                }}
            >
                {props.streamRule.streamrulelines.map((ruleline,index) => (
                    <Box
                        key={index}
                        sx={{
                            height:40,
                            display:"flex",
                            alignItems:"center",
                            p:0.5,
                            // background:"#FFE9D6",
                            background:colorSettings.streamrule,
                            borderRadius:5,
                        }}
                    >
                        {/* Colli */}
                        <DropdownBase
                            displayName={"name"} 
                            onChange={(selected) => selectRuleLineKey(selected,index)}
                            noSelection={""} 
                            options={streamRuleLineKeys}
                            useTranslation 
                            selected={streamRuleLineKeys.findIndex(value => value === ruleline.key)}
                            closeOnSelection
                            maxHeight={"unset"}
                            disallowDeselection
                            // shComponent={<CustomComponent></CustomComponent>} // new
                        >
                            <Box
                                sx={{
                                    width:120,
                                    pl:1,
                                    pr:2,
                                    display:"flex",
                                    alignItems:"center",
                                    height:32,
                                    borderRadius:4,
                                    "&:hover" : {
                                        background:colorSettings.streamrulehover,
                                        cursor:"pointer",
                                    },
                                    "@media screen and (max-width: 768px)" : {
                                        px:1,
                                    }
                                }}
                            >
                                {/* {ru} */}
                                <Text noBreak>{t(getStreamRuleKey(ruleline.key))}</Text>
                            </Box>
                        </DropdownBase>

                        {/* Nominator */}
                        <DropdownBase
                            displayName={"name"} 
                            onChange={(selected) => selectRuleLineCondition(selected,index)}
                            noSelection={""} //Custom text when nothing is selected
                            options={conditions(ruleline.key)} // array
                            useTranslation // conditions uses translation keys as the array,
                            selected={conditions(ruleline.key).findIndex(value => value === ruleline.nominator)}
                            closeOnSelection
                            disallowDeselection
                        >
                            <Box
                                sx={{
                                    ml:1,
                                    mr:1,
                                    width:155,
                                    pl:2,
                                    pr:2,
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    height:32,
                                    borderRadius:4,
                                    "&:hover" : {
                                        background:colorSettings.streamrulehover,
                                        cursor:"pointer",
                                    },
                                    "@media screen and (max-width: 768px)" : {
                                        width:"32px",
                                        p:0,
                                        ml:0,
                                        mr:0,
                                    }
                                }}
                            >
                                {windowSize > 768?
                                    typeof ruleline.nominator === 'Number' || ruleline.nominator === -1 ?
                                        <Text noBreak >{t('choose_condition')}</Text>
                                    :
                                        <Text noBreak bold>{t(ruleline.nominator)}</Text>
                                :
                                    <Text bold>{getMobileCondition(ruleline.nominator)}</Text>
                                }
                            </Box>
                        </DropdownBase>

                        {/* Value */}
                        {conditionKeys.includes(ruleline.key) ?
                            <Box
                                sx={{
                                    flex:1,
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    background:"#FFF8F2",
                                    background:colorSettings.streamrulevalue,
                                    height:32,
                                    borderRadius:4,
                                    "&:hover" : {
                                        background:colorSettings.streamrulehover,
                                        cursor:"pointer",
                                    },
                                    "@media screen and (max-width: 768px)" : {
                                        ml:1,
                                    }
                                }}
                            >
                                <TextInput
                                    value={ruleline.value}
                                    background={"transparent"}
                                    onChange={(e) => selectRuleLineValue(e.target.value,index)}
                                    // onChange={(e) => handleChange("firstname", e.target.value)}
                                />
                            </Box>
                        :


                            <DropdownBase
                                displayName={"name"} 
                                valueKey={"id"}
                                onChange={(selected) => selectRuleLineValue(selected,index)}
                                noSelection={""} //Custom text when nothing is selected
                                options={props.countries} // array
                                selected={props.countries.find(country => country.id === ruleline.value)?.id}
                                closeOnSelection
                                toggleDragAndDrop={(isEnabled) => setDisableDnD(!isEnabled)}
                                searchOptions={{
                                    enabled: true,
                                    placeholder: t("search_country_placeholder")
                                }}
                                styles={{
                                    minWidth:300
                                }}
                                disallowDeselection
                            >
                                <Box
                                    sx={{
                                        flex:1,
                                        // flexGrow:0,
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        background:colorSettings.streamrulevalue,
                                        height:32,
                                        borderRadius:4,
                                        "&:hover" : {
                                            background:colorSettings.streamrulevaluehover,
                                            cursor:"pointer",
                                        },
                                        "@media screen and (max-width: 768px)" : {
                                            // ml:1,
                                        }
                                    }}
                                >
                                    <Text noBreak>{props.countries.find(country => country.id === ruleline.value)?.name}</Text>
                                </Box>
                            </DropdownBase>
                        }

                        <Box
                            onClick={() => removeStreamRuleLine(index)}
                            sx={{

                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                height:32,
                                width:32,
                                borderRadius:4,
                                ml:1,
                                mr:0.5,
                                color: colors.txt["secondary"],
                                "&:hover" : {
                                    // background:"#FFDBBD",
                                    background:colorSettings.streamrulehover,
                                    cursor:"pointer",
                                }
                            }}
                        >
                            <CloseIcon fontSize='small'/>
                        </Box>

                    </Box>
                ))}

                <Box
                    onClick={addStreamRuleLine}
                    sx={{
                        mt:1,
                        height:40,
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        borderRadius:5,

                        "&:hover" : {
                            background: colorSettings.streamrule,
                        }
                    }}
                >
                    <Text semibold>Nieuwe voorwaarde</Text>
                </Box>
                
                
            </Box>
        </Box>
    );
}

export default StreamRule;