import { Box, useTheme } from "@mui/material";
import Subheading from "../../../components/theme/text/Subheading";
import Text from "../../../components/theme/text/Text";
import Shbutton from "../../../components/theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import { useGridApiRef } from "@mui/x-data-grid";
import OverflowRow from "../../../components/theme/OverflowRow";

// import SPOrderDetails from "../../../components/global/Sidepanels/SpOrderDetails/SpOrderDetails";
import InboundsView from "./views/Inbounds";
import { useTranslation } from 'react-i18next';
import TabView from "../../../components/theme/TabViews/TabView";
import InboundInboundLines from "./views/InboundLines";
import TabView3 from "../../../components/theme/TabView3/TabView3";
import { relationInboundsConfig } from "./relationInboundsConfig";

const RelationInbound = () => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [isExtended, setIsExtended] = useState(false);

    const inboundsRef = useGridApiRef();
    const inboundlinesRef = useGridApiRef();

    const [data, setData] = useState({});

    useEffect(() => {
        // getOrderStatusCounts((result,data) => {
        //     if(result){
        //         setData(data);
        //     }
        // })
    },[]);
    // const [inboundData, setInboundData] = useState([]);
    // const handleIsExtendedChange = () => {
    //     getAllInbounds({}, (result, data) => {
    //         if (result) {
    //             setInboundData(data);
    //             setIsExtended(true);
    //             console.log(data);
    //         }
    //     });
    // };

    return (
        <Box sx={{background:"", width:1,paddingTop:9}}>
            {ready ?
            <>
                  

                <TabView3
                    tabConfig={relationInboundsConfig}
                    sx={{
                        background:"green",
                        minHeight:"calc(100%)",
                        background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        position:"relative",
                        zIndex:2,
                        display:"flex",
                        flexDirection:"column",

                        "&:before" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            left:0,
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            top:"40px",
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transition:"250ms"
                        },

                        "&:after" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            top:"40px",
                            right:0,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            transition:"250ms"
                        },

                        "@media screen and (max-width: 47.9375em)" : {
                            "&:after, &:before" : {
                                top:"-0px"
                            }
                        }
                    }}
                >
                    <>
                        <InboundsView config={relationInboundsConfig.inbound}/>
                        <InboundInboundLines config={relationInboundsConfig.inboundLines}/>

                    </>
                </TabView3>


            </>
            :null}
        </Box>
    );
};

export default RelationInbound;