import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme"
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Text from "../../../../../components/theme/text/Text";

import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../../components/theme/label/ShLabel";
import { useEffect, useRef, useState } from "react";
import { useGetPickingJobsQuery, useGetPickingJobPickingListQuery } from "../../../../../newapi/pickingjob/pickingjobSlice";
import OrdersCard from "../../../../../components/theme/cards/orders/OrdersCard";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import { general_states } from "../../../../../utils/staticEnums";

import PrintIcon from '@mui/icons-material/Print';
import BallotIcon from '@mui/icons-material/Ballot';
import ShDataGridSelectedActions from "../../../../../components/theme/datagrid/ShDataGridSelectedActions";
import DropdownButton from "../../../../../components/theme/buttons/DropdownButton";
import SpPickingJobPickingList from "../../../../../components/global/Sidepanels/SpPickingJobPickingList/SpPickingJobPickingList";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
const GridActions = (props) => {
    const theme = useTheme();
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <ButtonGroup version={2}>
                <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} endIcon={<AddIcon/>} variant="contained">New</Shbutton>
            </ButtonGroup>
        </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const isShipmentDue = (inputDateString) => {
   const inputDate = new Date(inputDateString);
   const now = new Date();
  
    return now > inputDate;
  };

const OutboundBatchesFinished = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(-1);
    const [rowSelected, setRowSelected] = useState(false);
    
    const ref = useRef();
    const [config, setConfig] = useState({"state": general_states.COMPLETED});

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
        }
    },[]);

    const { data: pickingJobData, isLoading } = useGetPickingJobPickingListQuery(selectedRowId,
        {
            enabled: rowSelected && selectedRowId !== -1,
            skip: !rowSelected
        }
    );
    
    const getPickingList = (event, params) => { 
        if (!params || !params.row) return;

        event.stopPropagation();
        setSelectedRowId(params.id);
        setRowSelected(true);
    };

    useEffect(() => {
        if (!isLoading && pickingJobData && rowSelected) {
            setIsExtended(true);
        }
    }, [pickingJobData, isLoading, rowSelected]);

    const columns = [
        {
            field: "id",
            headerName: t("reference"),
            shFilter : {
                type: "number",
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <Box 
                        sx={{
                            width:80,
                            cursor:"pointer",
                            lineHeight:"64px",
                        }} 
                        onClick={(event) => getPickingList(event, params.row.id)}
                    >
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.id}</Text>
                    </Box>
                );
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                
                return (
                    <ShLabel size={32} palette="normal" variant="blue">
                      {t('open')}
                    </ShLabel>
                  );
              }
        },
        {field: "orderCount", headerName: t("pickinglists"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.row.orderCount
        }},
        {field: "totalProducts", headerName: t("products"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.row.total_products
        }},
        {field: "userName", headerName: t("appointed_to"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.row.userName
        }},
        {field: "warehouse", headerName: t("warehouse"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.warehouse.name;
        }},
        {field: "createdAt", headerName: t("created"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            let created = new Date(params.row.createdAt);
            return created.toLocaleDateString(t.language);
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <OrdersCard skeleton={params.row.skeleton ?? false} {...params.row}  />
                )
            }
        }
    ];

    const [selectedItems, setSelectedItems] = useState([]);
    // const setSelected
    const onSelected = (selectedItems) => {
        setSelectedItems(selectedItems);
    }

    return (
        <Box height={1} ref={ref} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetPickingJobsQuery}
                config={config}
                // gridActions={<GridActions></GridActions>} 
                title={t('finished')}
                gridOptions={gridOptions} 
                columns={columns}
                rows={data}
                onRowClick={getPickingList}
                sortModel={{field: columns[0].field,type:"DESC"}}
                
                // selectable
                // setSelected={onSelected}
                // selectedActions={
                //     <ShDataGridSelectedActions selectedItems={selectedItems}>
                //         <DropdownButton icon={<PrintIcon/>} text={"print"}/>
                //         <DropdownButton icon={<BallotIcon/>} text={"Maak batch"}/>
                //     </ShDataGridSelectedActions>
                // }
                >
            </ShDataGrid2>

            {/* <ShDataGridSelectedActions selectedItems={selectedItems}>
                <DropdownButton icon={<PrintIcon/>} text={"print"}/>
                <DropdownButton icon={<BallotIcon/>} text={"Maak batch"}/>
            </ShDataGridSelectedActions> */}

            <SpPickingJobPickingList
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                data={pickingJobData}
            ></SpPickingJobPickingList>
        </Box>
    );
}

export default OutboundBatchesFinished;