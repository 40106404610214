import { Box, useTheme } from "@mui/material";
import BaseCard from "../BaseCard";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import Text from "../../text/Text";
import TopRight from "../../positionals/TopRight";
import CardLabel from "../../label/CardLabel";
import ShLabel from "../../label/ShLabel";
import { formatPrice } from "../../../../utils/helpers";
import { tokens } from "../../../../theme";
import { useTranslation } from "react-i18next";

export const WarehouseInvoiceCard = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t,i18n } = useTranslation();
    const color = props.isConcept ? "orange" : "green";

    let date = props.startDate && props.startDate !== null ? new Date(props.startDate) : undefined;

    let myDate = date ? date.toLocaleDateString(i18n.language) : "-";
              
    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box
                    sx={{

                    }}
                >
                    {/* Top row */}
                    <Box
                        sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            gap:3,
                        }}
                    >
                        <Box sx={{ flexGrow: 1, flexShrink: 1, overflow:"hidden" }}>
                            <Box>
                                <Box>
                                    <Text bold>{t("invoice")}</Text>&nbsp;
                                    <Text bold variant={color}>{props.number}</Text>
                                </Box>
                            </Box>
                            {/* Rleation name */}
                            <Box
                                sx={{
                                    
                                }}
                            >
                                <Text light>{props.relation?.name}</Text>
                            </Box>
                        </Box>
                        <ShLabel borderless palette={"normal"} fitted size={32}>{formatPrice(i18n,props.totalPrice)}</ShLabel>
                    </Box>
                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("start_date")}</Text>

                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>
                                    {myDate}
                                    {/* {props.stock && props.stock.total_quantity ? props.stock.total_quantity : 0}     */}
                                </Text>
                            }
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("lines")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>
                                    {props.totalLines}
                                    {/* {props.stock && props.stock.total_available ? props.stock.total_available : 0} */}
                                </Text>
                            }
                        </Box>

                        <Box 
                            sx={{
                                ml:"auto",
                                mt:"auto"
                            }}
                        >
                            <ShLabel borderless palette="normal" variant={color} fitted size={32}>
                                {props.isConcept ? t("concept") : t("final")}
                            </ShLabel>
                        </Box>
                    </Box>

                </Box>
            </Box>
        </BaseCard>
    )
}
