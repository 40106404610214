exports.formatPrice = (i18n,price) => {
    return new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: 'EUR'
    }).format(price);
}

exports.removeTrailingZeros = (input) => {
    // Format amount to remove trailing zeros and only show necessary decimals
    const num = Number(input);
    return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2).replace(/\.?0+$/, '');
}
