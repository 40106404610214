import { Box, useTheme } from "@mui/material"
import BaseCard from "../../BaseCard"
import { tokens } from "../../../../../theme";
import Text from "../../../text/Text";
import CardLabel from "../../../label/CardLabel";
import TopRight from "../../../positionals/TopRight";
import { useTranslation } from "react-i18next";
import ShTextSkeleton from "../../../skeleton/ShTextSkeleton";
import BottomRight from "../../../positionals/BottomRight";
import BottomLeft from "../../../positionals/BottomLeft";
import ShDoubleTextSkeleton from "../../../skeleton/ShDoubleTextSkeleton";

export const WarehouseVirtualProductCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t } = useTranslation();
    
    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick} sx={{p:0,pb:2,}}>
            <Box>
                <Box>
                    <Box display={"flex"}
                        sx={{
                            gap:3,
                        }}
                    >
                        <Box
                            sx={{
                                minWidth:170,
                                display:"flex",
                                flexDirection:"column",
                                pt:2,
                                pl:2,
                                overflow:"hidden",
                                flex:1,
                            }}
                        >
                            {props.skeleton ? 
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    <Text noBreak bold>{props.description}</Text>
                                    {/* <Text noBreak bold>{props.product?.description}</Text> */}
                                    {/* <Text >{props.product?.relation?.name}</Text> */}
                                    <Text>{props.relation ? props.relation.name : t('unknown')}</Text>
                                </>
                            }
                        </Box>
                        
                        <Box
                            sx={{
                                width:"fit-content",
                            }}
                        >
                            <CardLabel variant={theme.palette.mode === "dark" ? "blue" : "primary"}  position="child-tr">
                                {props.skeleton ? 
                                    <ShDoubleTextSkeleton/> 
                                    :
                                    props.sku ? props.sku : "N/A"
                                }
                            </CardLabel>
                        </Box>
                    </Box>

                    

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3} pl={2}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("products")}</Text>

                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>
                                    {props.childProducts ? props.childProducts.length : 0}    
                                </Text>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}



export const WarehouseVirtualProductDetailsCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t } = useTranslation();
    
    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick} sx={{p:0,pb:2,}}>
            <Box>
                <Box>
                    <Box display={"flex"}
                        sx={{
                            gap:3,
                        }}
                    >
                        <Box
                            sx={{
                                minWidth:170,
                                display:"flex",
                                flexDirection:"column",
                                pt:2,
                                pl:2,
                                overflow:"hidden",
                                flex:1,
                            }}
                        >
                            {props.skeleton ? 
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    <Text noBreak bold>{props.product ? props.product.sku : t('unknown')}</Text>
                                    {/* <Text noBreak bold>{props.product?.description}</Text> */}
                                    {/* <Text >{props.product?.relation?.name}</Text> */}
                                    <Text>{props.product ? props.product.description : t('unknown')}</Text>
                                </>
                            }
                        </Box>
                        
                        <Box
                            sx={{
                                width:"fit-content",
                            }}
                        >
                            <CardLabel variant={theme.palette.mode === "dark" ? "greyLight" : "grey"}  position="child-tr">
                                {props.skeleton ? 
                                    <ShDoubleTextSkeleton/> 
                                    :
                                    props.quantity
                                }
                            </CardLabel>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}
