import { Box } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme"
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../../components/theme/icons/Icon";
import Text from "../../../../../components/theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ShDateRangePicker from "../../../../../components/theme/ShDateRangePicker";
import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../../components/theme/label/ShLabel";
import { useEffect, useRef, useState } from "react";
import SpOrderDetails from "../../../../../components/global/Sidepanels/SpOrderDetails/SpOrderDetails";
import SpInboundDetails from "../../../../../components/global/Sidepanels/SpInboundDetails/SpInboundDetails";
import { useGetInboundsQuery, useGetInboundQuery, useGetInboundLinesQuery, useGetAllInboundLinesQuery } from "../../../../../newapi/inbound/inboundsSlice";
import InboundsCard from "../../../../../components/theme/cards/inbounds/InboundsCard";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";

import { useNavigate } from 'react-router-dom';
import { checkUserPermission, UserPrivileges, UNAUTHORIZED_ENDPOINT } from '../../../../../utils/permissions';
import { useSelector } from "react-redux";
import OrderLinesCard from "../../../../../components/theme/cards/orders/OrderLinesCard";
import MessageIcon from '@mui/icons-material/MessageOutlined';
import ShDoubleTextSkeleton from "../../../../../components/theme/skeleton/ShDoubleTextSkeleton";
import { WarehouseInboundLineCard } from "../../../../../components/theme/cards/inbounds/InboundLineCards";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";

const GridActions = (props) => {
    const theme = useTheme();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                // onClick={confirm} 
                endIcon={<AddIcon/>}
                variant="contained"
            >
                New
            </Shbutton>
        </ButtonGroup>
        // <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
        //     <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        // </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const InboundInboundLines = (props) => {
    // Check if user has permission to access the page
   const navigate = useNavigate();
   useEffect(() => {
       if (!checkUserPermission([UserPrivileges.INBOUND_READ])) {
         navigate(UNAUTHORIZED_ENDPOINT); // Use navigate instead of history.push
       }
     }, [navigate]);
     
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    //const [config, setConfig] = useState({"relationId": useSelector((state) => state.settings.environment.selectedId)});

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            
        }
    },[]);
    

    const [inboundIsExtended, setInboundIsExtended] = useState(false);
    const [inboundId, setInboundId] = useState();
    const inboundIdRef = useRef(inboundId);
    
    const getInboundDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.inbound?.id);
        // Only update state if the productId has actually changed
        if (inboundIdRef.current !== newProductId) {
            setInboundId(newProductId);
            inboundIdRef.current = newProductId; // Update the ref to the new value
        }
        setInboundIsExtended(true);
    };

    // const [selectedRowId, setSelectedRowId] = useState();
    // const selectedRowIdRef = useRef(selectedRowId);
    // useEffect(() => {
    //     selectedRowIdRef.current = selectedRowId;
    // }, [selectedRowId]);

    // const { data: inboundData, isLoading: isLoading } = useGetInboundQuery(selectedRowId,
    //     {
    //         skip: !selectedRowId
    //     });
        
    // const getInboundDetails = (params) => {
    //     if (!params) return;
    //     if (typeof selectedRowIdRef.current !== "undefined" &&  parseInt(selectedRowIdRef.current) === parseInt(params.row.inbound.id)) {
    //         setIsExtended(true);
    //     } else {
    //         if(params.row.inbound !== null) {
    //             setSelectedRowId(params.row.inbound?.id);
    //         }
    //     }
    // };

    // useEffect(() => {
    //     if (!isLoading && inboundData) {
    //         setIsExtended(true);
    //     }
    // }, [inboundData, isLoading]);

    const columns = [
        {field: "reference", headerName: t("inbound"), flex:3, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }


            return (
                <Box>
                    <Text semibold>{params.row.inbound?.reference}</Text><br/>
                    <Text light>{params.row.inbound?.relation?.name}</Text>
                </Box>
            )
        }},
        {
            field: "state", 
            headerName: t("status"),
            flex:3,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                let status = params.row.state;
                let labelcolor = "";
                let labeltext = "";
                switch(status){
                    case "notreceived":
                        labelcolor = "grey"
                        labeltext = t('not_received');
                        break;
                    case "partlyreceived":
                        labelcolor = "blue"
                        labeltext = t('partly_received');
                        break;
                    case "fullyreceived":
                        labelcolor = "green"
                        labeltext = t('fully_received');
                        break;
                    default:
                        labelcolor = "red"
                        labeltext = t('unknown');
                        break;
                }
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {labeltext}
                    </ShLabel>
                  );
              }
        },
        {field: "sku", headerName: t("sku"), flex:3, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShDoubleTextSkeleton/>
            }
            return (
                <Box>
                    <Text semibold>{params.row.product.sku}</Text><br/>
                    <Text light>{params.row.product.description}</Text>
                </Box>
            )
        }},

        {field: "amountForecasted", headerName: t("forecasted"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.value;
            }
        },
        
        {field: "amountReceived", headerName: t("received"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.value;
            }
        },
        {field: "amountMoved", headerName: t("moved"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.value;
            }
        },
        {
            field: "shipmentDate",
            headerName: t("shipment_date"),
            flex:2,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let date = new Date(params.row.inbound?.shipmentDate);
              
                return (
                    <Text>
                        {date.toLocaleDateString(i18n.language)}
                    </Text>
                );
            }
        },
        {
            field: "receivedFromDate",
            headerName: t("received_date"),
            flex:2,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                let xdate = new Date(params.row.inbound?.receivedFromDate);
              
                return (
                    <Text>
                         {xdate.toLocaleDateString(i18n.language)} 
                    </Text>
                );
            }
        },
        {field: "remark", headerName: t("remark"), flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            if(params.row.remark !== ""){
                return (
                    <Text light><MessageIcon/></Text>
                    
                  );
            } else {
                
            }
            
          }},
        {
            field: "phoneView", 
            headerName: "phoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <WarehouseInboundLineCard skeleton={params.row.skeleton ?? false} {...params.row} onClick={() => getInboundDetails(params)}/>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetAllInboundLinesQuery}
                // gridActions={<GridActions></GridActions>} 
                title={t('all_inboundlines') }
                gridOptions={gridOptions} 
                sortModel={{field: columns[6].field,type:"DESC"}}
                columns={columns}
                onRowClick={getInboundDetails}>
            </ShDataGrid2>
            <SpInboundDetails
                isExtended={inboundIsExtended}
                setIsExtended={setInboundIsExtended}
                inboundId={inboundId}
                // data={inboundData}
            ></SpInboundDetails>

        </Box>
    );
}

export default InboundInboundLines;