import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const PRINTER_BASE = "/printers";
const buildUrl = (endpoint) => PRINTER_BASE + endpoint;

const printerApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createPrinter: builder.mutation({
            query: printerData => ({
                url: buildUrl('/new'),
                method: 'POST',
                body: printerData,
            }),
            invalidatesTags: [{ type: 'Printers'},{ type: 'PrintersSelect'}],
        }),
        findAllPrinters: builder.query({
            query: ({ args }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: [{ type: 'Printers' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'Printers', 'Printers')
        }),
        findPrinterById: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Printer', id }],
        }),
        updatePrinter: builder.mutation({
            query: ({ id, data }) => ({
                url: buildUrl(`/${id}`),
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Printer', id },{ type: 'Printers'},{ type: 'PrintersSelect'}],
        }),
        deletePrinter: builder.mutation({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Printer', id },{ type: 'Printers'},{ type: 'PrintersSelect'}],
        }),
        printnode: builder.query({
            query: () => ({
                url: buildUrl(`/printnode`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Printer', id }],
        }),
        findPrintersSelect: builder.query({
            query: () => ({
                url: buildUrl(``),
                method: 'POST',
                body: ''
            }),
            providesTags: [{ type: 'PrintersSelect' }],
        }),
        getPrinterWorkspaces: builder.query({
            query: ({ args }) => ({
                url: buildUrl(`/${args.id}/workspaces`),
                method: 'POST',
                body: args.body
            }),
            providesTags: [{ type: 'PrinterWorkspaces' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'PrinterWorkspaces', 'PrinterWorkspaces')
        }),
    })
});

export const {
    useCreatePrinterMutation,
    useFindAllPrintersQuery,
    useFindPrinterByIdQuery,
    useUpdatePrinterMutation,
    useDeletePrinterMutation,
    usePrintnodeQuery,
    useFindPrintersSelectQuery,
    useGetPrinterWorkspacesQuery
} = printerApiSlice;
