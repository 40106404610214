import { Box, useTheme, ButtonBase } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";
import { useTranslation } from "react-i18next";
import { useGetInvoiceInvoiceProductsQuery, useDeleteInvoiceInvoiceProductsMutation } from '../../../../../newapi/financial/invoiceSlice';
import ShTextSkeleton from '../../../../theme/skeleton/ShTextSkeleton';
import ShDataGrid2 from '../../../../theme/datagrid2/ShDataGrid2';
import ShDataGridSelectedActions from "../../../../theme/datagrid/ShDataGridSelectedActions";
import DropdownButton from "../../../../../components/theme/buttons/DropdownButton";
import MEditInvoiceInvoiceProducts from "../../../Modals/MEditInvoiceInvoiceProducts";
import { useSelector } from "react-redux";
import { ENV_TYPES } from "../../../Sidebar";
import { formatPrice, removeTrailingZeros } from "../../../../../utils/helpers";
import { showToastMessageDeleteRequest } from "../../../../../utils/toasts";
import Text from "../../../../theme/text/Text";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import {QuestionMarkOutlined} from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";

const GridActions = (props) => {
    return <></>
}

const gridOptions = {
    export:false,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const InvoiceInvoiceProducts = (props) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const isRelation = selectedEnvironmentType === ENV_TYPES[200];
    const [selectedInvoiceProduct, setSelectedInvoiceProduct] = useState({id: 0});
    const [deleteModalSubTitle, setDeleteModalSubTitle] = useState("");

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions: <GridActions/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const [editModalOpen, setEditModalOpen] = useState(false);
    const editModal = {
        open: editModalOpen,
        setOpen: setEditModalOpen
    }

    const handleEditClose = () => {
        setEditModalOpen(false);
    };

    const [selectedRows, setSelectedRows] = useState([]);
    const onSelected = (selectedRows) => {
        setSelectedRows(selectedRows);
        setDeleteModalSubTitle(`${selectedRows.length} ${selectedRows.length === 1 ? t("mutation") : t("mutations")}`);
    }

    const handleEditAction = () => {
        setEditModalOpen(true);
    }
    
    const handleDeleteAction = () => {
        setDeleteConfirmOpen(true);
    }
    
    const [DeleteInvoiceProducts,{syncIsLoading}] = useDeleteInvoiceInvoiceProductsMutation();
    const deleteInvoiceProducts = async () => {
        try {
            const response = await DeleteInvoiceProducts({invoiceId: props.invoiceId, invoiceProductIds: selectedRows.map(row => row.id)}).unwrap();
            showToastMessageDeleteRequest(t, response);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };
    
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const confirmDeleteModal = {
        open: deleteConfirmOpen,
        setOpen: setDeleteConfirmOpen,
        confirm: deleteInvoiceProducts
    }

    const columns = [
        {field: "createdAt", headerName: t("created_on"), flex: 1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            const date = new Date(params.row.createdAt);
            return date.toLocaleString(i18n.language, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            });
        }},
        {field: "amount", headerName: t("amount"), renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return removeTrailingZeros(params.row.amount);
        }},
        {field: "description", headerName: t("description"), flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.description;
        }},
        {field: "price", headerName: t("price"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return formatPrice(i18n, params.row.price);
            }
        },
        ...!isRelation ? [{
            field: "userName", 
            headerName: t("user"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Box sx={{display:"flex", flexDirection:"row", gap:1}}>
                        <Box sx={{ paddingRight: '8px' }}>
                            {(() => {
                                switch(params.row.userType) {
                                    case "system":
                                        return <SettingsIcon width={30} height={30}/>;
                                    case "user":
                                        return <PersonIcon width={30} height={30}/>;
                                    case "object":
                                        return <ShoppingCartIcon width={30} height={30}/>;
                                    default:
                                        return <QuestionMarkOutlined width={30} height={30}/>;
                                }
                            })()}
                        </Box>
                        <Box>
                            <Text>{params.row.userType === "system" ? t("automatic") : params.row.userName}</Text>
                        </Box>    
                    </Box>            
                )
            }
        }] : [],
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <ShTextSkeleton/>
                )
            }
        },
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetInvoiceInvoiceProductsQuery}
                itemId={props.invoiceId}
                gridActions={<GridActions></GridActions>} 
                title={t('mutations')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                selectable
                setSelectedRows={onSelected}
                selectedActions={
                    <ShDataGridSelectedActions selectedItems={selectedRows}>
                        <DropdownButton onClick={handleEditAction} icon={<EditIcon/>} text={t("edit")}/>
                        <DropdownButton onClick={handleDeleteAction} icon={<DeleteIcon/>} text={t("delete")}/>
                    </ShDataGridSelectedActions>
                }
            >
            </ShDataGrid2>
            <MConfirmDelete
                open={confirmDeleteModal.open}
                data={deleteModalSubTitle}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                confirm={confirmDeleteModal.confirm}
                content={t("are_you_sure_delete_mutations_question")}
            />
            <MEditInvoiceInvoiceProducts onOpen={() => editModal.setOpen(true)} invoiceId={props.invoiceId} invoiceProducts={selectedRows} onClose={() => editModal.setOpen(false)} open={editModal.open} handleClose={handleEditClose} />
        </Box>
    );
}

export default InvoiceInvoiceProducts;