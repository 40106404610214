import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import WidgetsIcon from '@mui/icons-material/Widgets';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShDoubleTextSkeleton from "../../../../theme/skeleton/ShDoubleTextSkeleton";
import { useGetPrinterWorkspacesQuery } from "../../../../../newapi/warehouse/printerSlice";
import AddIcon from '@mui/icons-material/Add';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import MAddRelationContact from "../../../Modals/MAddRelationContact";
import DeleteIcon from '@mui/icons-material/Delete';
import { showToastMessageDeleteRequest } from "../../../../../utils/toasts";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import { GridCheckIcon, GridCloseIcon } from "@mui/x-data-grid";
import SpWorkspaceDetails from "../../SpWorkspaceDetails/SpWorkspaceDetails";
import ShLabel from "../../../../theme/label/ShLabel";

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
           
        </ButtonGroup>
        // <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
        //     <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        // </Box>
    )
}

const Workspaces = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    const [workspaceId, setWorkspaceId] = useState();

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const getWorkspaceDetails = (id) => {
        if (!id) return;
        setWorkspaceId(id);
        setIsExtended(true);
    };

    

    const columns = [
        {
            field: "name", 
            headerName: t('name'),
            flex:1, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return <Box onClick={() => getWorkspaceDetails(params.row.id)}>{params.row.name}</Box>;
            }
        },
        {
            field: "printer", 
            headerName: t('configured_as'),
            flex:2, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShDoubleTextSkeleton/>
                }
                console.log(params.value);
                return (
                    <Box
                        sx={{
                            display:"flex",
                            gap:1,
                        }}
                    >
                        {params.value.map((printer, index) => {
                            let color  = "grey";
                            switch(printer){
                                case "printer_packinglist":
                                    color = "pink";
                                    break;
                                case "printer_label":
                                    color = theme.palette.mode === "dark" ? "primary" : "blue";
                                    break;
                                case "printer_documents":
                                    color="purple";
                                    break;
                            }
                            return (
                                <ShLabel
                                    variant={color}
                                    size={32}
                                    fitted
                                >
                                    {t(printer)}
                                    {/* {index < params.value.length - 1 && <br />} */}
                                </ShLabel>
                            )
                        })}
                    </Box>
                );
            }
        },
        {
            field: "autoPrint", 
            headerName: t('autoprint'),
            flex:1, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.autoPrint ? <GridCheckIcon /> : <GridCloseIcon />;
                
            }
        },
    ];

    console.log(props.printerId);

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetPrinterWorkspacesQuery}
                itemId={props.printerId}
                title={t('workspaces')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                rows={data}>
            </ShDataGrid2>
            <SpWorkspaceDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                workspaceId={workspaceId}
            ></SpWorkspaceDetails>
        </Box>
    );
}

export default Workspaces;