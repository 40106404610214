import { Box, useTheme } from "@mui/material";
import BaseCard from "../BaseCard";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import Text from "../../text/Text";
import TopRight from "../../positionals/TopRight";
import CardLabel from "../../label/CardLabel";
import ShLabel from "../../label/ShLabel";
import { formatPrice } from "../../../../utils/helpers";
import { tokens } from "../../../../theme";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const WarehouseInvoiceProductCard = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t,i18n } = useTranslation();
    const color = props.isConcept ? "orange" : "green";

    // State to hold the formatted date, label color, and label text
    const [labelColor, setLabelColor] = useState("grey");

    useEffect(() => {
        // Get status from params or default to "unknown"
        let iLabelColor = "grey";
        switch(props.type) {
            case "time" :
                iLabelColor = "grey";
                break;
            case "material" :
                iLabelColor = "orange";
                break;

            case "process_order" :
                iLabelColor = "pink";
                break;

            case "process_orderline" :
                iLabelColor = "purple";
                break;

            case "default" :
                iLabelColor = "michael_brown";
                iLabelColor = "blue";
                break;

        }

        setLabelColor(iLabelColor);

    }, [props]);  // Dependencies to re-run when any of these change

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box
                    sx={{

                    }}
                >
                    {/* Top row */}
                    <Box
                        sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            gap:3,
                        }}
                    >
                        <Box sx={{ flexGrow: 1, flexShrink: 1, overflow:"hidden" }}>
                            <Box>
                                <Box>
                                    <Text bold>{props.name}</Text>&nbsp;
                                </Box>
                            </Box>
                            {/* Rleation name */}
                            <Box
                                sx={{
                                    
                                }}
                            >
                                <Text light>{props.invoiceline}</Text>
                            </Box>
                        </Box>
                        {/* <ShLabel borderless palette={"normal"} fitted size={32}>{formatPrice(i18n,props.totalPrice)}</ShLabel> */}
                    </Box>

                    <TopRight>
                        {/* <CardLabel> */}
                        <CardLabel 
                            // pastel
                            palette={'pastel'}
                            variant={labelColor}>
                            {t(props.type)}
                        </CardLabel>
                        {/* <CardLabel 
                            variant={theme.palette.mode === "dark" ? "greyLight" : "grey"}
                            hasleft 
                            colorflip={theme.palette.mode !== "dark"}
                            position="child-tr"
                        >
                            {props.skeleton ? "N/A" : props.amountForecasted}
                        </CardLabel> */}
                    </TopRight>

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={1.5}>
                        <ShLabel borderless  fitted size={32}>
                            {t(props.subType)}
                        </ShLabel>
                        <ShLabel borderless fitted size={32}>
                            #{t(props.ledger)}
                        </ShLabel>
                    </Box>

                </Box>
            </Box>
        </BaseCard>
    )
}
