import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useTranslation } from 'react-i18next';
import DetailsPanel from "./Views/Details";
import Categories from "./Views/Categories";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spInvoiceProductDetailsConfig } from "./spInvoiceProductDetailsConfig";
import { useGetInvoiceProductQuery } from "../../../../newapi/financial/invoiceProductSlice";
import ListIcon from '@mui/icons-material/List';

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpInvoiceProductDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: invoiceProductData, isLoading,isFetching,isError } = useGetInvoiceProductQuery(props.invoiceProductId,
    {
        skip: !props.invoiceProductId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ListIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon,defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.invoiceProductId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && invoiceProductData) {
            setPanel(prevPanel => ({
                ...prevPanel,
                title: invoiceProductData.name,
                subTitle: "",
                options: [],
            }));
        }
    }, [invoiceProductData, isLoading, isFetching, t,theme.palette.mode]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spInvoiceProductDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <DetailsPanel config={spInvoiceProductDetailsConfig.details} data={invoiceProductData} setIsExtended={props.setIsExtended} />
                    <Categories config={spInvoiceProductDetailsConfig.categories} invoiceProductId={props.invoiceProductId} setIsExtended={props.setIsExtended} />
                </>
        </Sidepanel2>
    );
};

export default SpInvoiceProductDetails;