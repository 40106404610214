import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useEditUserMutation, useDeleteUserMutation } from "../../../../../newapi/user/userSlice";

import { useTranslation } from "react-i18next";
import { useGetUsergroupsSelectQuery } from "../../../../../newapi/user/usergroupSlice";
import { useGetWarehousesSelectQuery } from "../../../../../newapi/warehouse/warehouseApiSlice";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { showToastMessageDeleteRequest, showToastMessagePostRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";
import { useSelector } from "react-redux";
import * as yup from "yup";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../../../utils/validation";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
          <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const customer_id = useSelector((state) => state.settings.environment.selectedId);

    const [userInfo, setUserInfo] = useState({
        id: 1,
        email: "",
        employee_code: "",
        type: 0,
        firstname: "",
        insertion: "",
        lastname: "",
        usergroups: []
    });

    const {data: usergroups } = useGetUsergroupsSelectQuery();
    const {data: warehouses } = useGetWarehousesSelectQuery();
    useEffect(() => {
      setUserInfo(props.data);
    },[props.data]);

    const handleChange = (key, value) => {
        setUserInfo(prevUserInfo => {
            const updatedUserInfo = Object.assign({}, prevUserInfo);

            if (key.startsWith("usergroupWarehouse")) {
                const warehouseId = parseInt(key.split("#")[1]);
                const usergroupIndex = updatedUserInfo.usergroups.findIndex(usergroup => usergroup.warehouseId === warehouseId);

                if (usergroupIndex !== -1) {
                    if (value === -1) {
                        updatedUserInfo.usergroups = updatedUserInfo.usergroups.filter((_, index) => index !== usergroupIndex);
                    } else {
                        updatedUserInfo.usergroups[usergroupIndex] = {
                            ...updatedUserInfo.usergroups[usergroupIndex],
                            id: usergroups[value].id
                        };
                    }
                } else {
                  if(value === -1){
                    updatedUserInfo.usergroups = updatedUserInfo.usergroups.filter((_, index) => index !== usergroupIndex);
                  } else {
                    updatedUserInfo.usergroups = [
                        ...updatedUserInfo.usergroups,
                        {
                            id: usergroups[value].id,
                            warehouseId,
                            admin: false,
                            userPrivileges: null
                        }
                    ];
                  }
                }
            } else {
                updatedUserInfo[key] = value;
            }

            return updatedUserInfo;
        });
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={userInfo}></GridActions>,
        });

        console.log(userInfo?.usergroups);
        return () => {
            // console.log("unmounting warehouse");
        }
    },[userInfo]);

    const [fieldErrors, setFieldErrors] = useState({});
    const rules = {
      firstname: yupRequired('firstname', t),
      lastname: yupRequired('lastname', t),
    };
    const schema = yup.object().shape(rules);

    const [EditUser,{isLoading}] = useEditUserMutation();
    const handleSaveClick = async () => {
        const canSave = true;
        if(canSave){    
            try {
                setFieldErrors({});
                await schema.validate(userInfo, { abortEarly: false });

                const data = await EditUser({body: userInfo, customerId: customer_id}).unwrap()
                showToastMessagePostRequest(t, data);
            } catch (err) {
              if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("update_failed"), t, {success: false, error: err.error});
              }
            }
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
          }
        }        
    };

    const [DeleteUserMutation] = useDeleteUserMutation();
      const confirmDelete = async () => {
        try {
          
            const response = await DeleteUserMutation({id: props.data.id, customerId: customer_id}).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };
    const handleDeleteClick = async () => {
        setOpen(true);
    };
    const [open, setOpen] = useState(false);
    const confirmDeleteModal = {
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    } 


    return (
      <Box
        height={1}
        display={"flex"}
        flexDirection={"column"}
        paddingX={3}
        sx={{
          "@media screen and (min-width: 48.1em)": {
            marginBottom: 11,
          },
        }}
      >
        <Dropdown title={t("basic")} open>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                "@media screen and (max-width: 47.9375em)": {
                  flex: 1,
                },
                width:"150px"
              }}
            >
              <Box lineHeight={"38px"}>
                <Text light>{t("employee_code")}</Text>
              </Box>
            </Box>
            <Box>
              <Box
                display="flex"
                height="32px"
                // maxWidth="400px"
                borderRadius="20px"
                backgroundColor={theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}
                sx={{
                  marginTop: 0.5,
                  marginBottom: 0.5,
                  justifyContent: "space-between",
                  "@media screen and (max-width: 47.9375em)": {
                    flexDirection: "column",
                  },
                }}
              >
              <ShValidatedInput
                name="employee_code"
                value={userInfo?.employee_code || ""}
                onChange={handleChange}
                error={fieldErrors.employee_code}
              />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                "@media screen and (max-width: 47.9375em)": {
                  flex: 1,
                },
                width:"150px"
              }}
            >
              <Box lineHeight={"38px"}>
                <Text light>{t("firstname")}</Text>
              </Box>
            </Box>
            <Box>
              <Box
                display="flex"
                height="32px"
                // maxWidth="400px"
                borderRadius="20px"
                backgroundColor={theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}
                sx={{
                  marginTop: 0.5,
                  marginBottom: 0.5,
                  justifyContent: "space-between",
                  "@media screen and (max-width: 47.9375em)": {
                    flexDirection: "column",
                  },
                }}
              >
                <ShValidatedInput
                  name="firstname"
                  value={userInfo?.firstname || ""}
                  onChange={handleChange}
                  error={fieldErrors.firstname}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                "@media screen and (max-width: 47.9375em)": {
                  flex: 1,
                  // width:1
                },
                width:"150px"
              }}
            >
              <Box lineHeight={"38px"}>
                <Text light>{t("insertion")}</Text>
              </Box>
            </Box>
            <Box>
              <Box
                display="flex"
                height="32px"
                // maxWidth="400px"
                borderRadius="20px"
                backgroundColor={theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}
                sx={{
                  marginTop: 0.5,
                  marginBottom: 0.5,
                  justifyContent: "space-between",
                  "@media screen and (max-width: 47.9375em)": {
                    flexDirection: "column",
                  },
                }}
              >
                <ShValidatedInput
                  name="insertion"
                  value={userInfo?.insertion || ""}
                  onChange={handleChange}
                  error={fieldErrors.insertion}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                "@media screen and (max-width: 47.9375em)": {
                  flex: 1,
                  // width:1
                },
                width:"150px"
              }}
            >
              <Box lineHeight={"38px"}>
                <Text light>{t("lastname")}</Text>
              </Box>
            </Box>
            <Box>
              <Box
                display="flex"
                height="32px"
                // maxWidth="400px"
                borderRadius="20px"
                backgroundColor={theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}
                sx={{
                  marginTop: 0.5,
                  marginBottom: 0.5,
                  justifyContent: "space-between",
                  "@media screen and (max-width: 47.9375em)": {
                    flexDirection: "column",
                  },
                }}
              >
                <ShValidatedInput
                  name="lastname"
                  value={userInfo?.lastname || ""}
                  onChange={handleChange}
                  error={fieldErrors.lastname}
                />
              </Box>
            </Box>
          </Box>
          {/* </Box> */}
        </Dropdown>

        <Dropdown title={t("usergroup")} open>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                lineHeight:"40px",
                "@media screen and (max-width: 47.9375em)": {
                  flex: 1,
                  // width:1
                },
                width:"250px"
              }}
            >
                <Text light>{t("general")}</Text>
            </Box>

            <InputDropdown
                displayName={"displayName"} 
                onChange={(selected) => handleChange("usergroupWarehouse#-1", selected)}
                noSelection={t("choose_usergroup")} //Custom text when nothing is selected
                options={usergroups ?? null}
                selected={usergroups?.findIndex(groups => groups.id === userInfo?.usergroups.find(usergroup => usergroup.warehouseId === -1)?.id) ?? -1}
                closeOnSelection
            />
          </Box>

          {userInfo?.customerId > 0 &&warehouses?.data.map((warehouse,index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: 4,
                justifyContent: "flex-start",
                mt:2,
                
              }}
            >
              <Box
                sx={{
                  lineHeight:"40px",
                  "@media screen and (max-width: 47.9375em)": {
                    flex: 1,
                    //width:"500px"
                    // width:1
                  },
                  width:"250px"
                }}
              >
                <Text light>{warehouse?.name}</Text>
              </Box>

              <InputDropdown
                  displayName={"displayName"} 
                  onChange={(selected) => handleChange(`usergroupWarehouse#${warehouse?.id}`, selected)}
                  noSelection={t("choose_usergroup")} //Custom text when nothing is selected
                  options={usergroups ?? null}
                  selected={usergroups?.findIndex(groups => groups.id === userInfo?.usergroups?.find(usergroup => usergroup.warehouseId === warehouse.id)?.id) ?? -1}
                  closeOnSelection
              />
            </Box>
          ))}
        </Dropdown>

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            padding: 3,
            width: 1,
            background:
              theme.palette.mode === "dark"
                ? colors.grey[200]
                : colors.grey[0],
            "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)":
              {
                padding: 2,
                paddingY: 2.5,
              },
          }}
        >
          <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
          {/* Pass the necessary state values and setter functions to GridActions */}
          <GridActions
            id={userInfo?.id}
            action={handleSaveClick}
            deleteAction={handleDeleteClick}
            // active={active}
            // blocked={blocked}
            // remark={remark}
            // carrier={carrier}
            // quarantine={quarantine}
            // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
          />
        </Box>
      </Box>
    );
}

export default DetailsPanel;