
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

export const warehouseRelationConfig = {
    all:  {
        title: 'relations',
        subtitle: 'all_relations',
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
    },
};
