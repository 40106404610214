import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import WidgetsIcon from '@mui/icons-material/Widgets';
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import { useGetChildProductsQuery, useDeleteChildProductsMutation, useDeleteProductMutation } from "../../../../../newapi/warehouse/productSlice";
import Text from "../../../../../components/theme/text/Text";
import SPStockDetails from "../../SpStockDetails/SPStockDetails";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import DeleteIcon from '@mui/icons-material/Delete';
import { showToastMessageDeleteRequest, showToastMessageRequestError } from "../../../../../utils/toasts";
import MAddChildProduct from "../../../Modals/MAddChildProduct";
import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import { WarehouseVirtualProductDetailsCard } from "../../../../theme/cards/products/warehouse/WarehouseVirtualProductCard";
import MConfirmDelete from "../../../Modals/MConfirmDelete";

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('add_products')}
            </Shbutton>
        </ButtonGroup>
        // <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
        //     <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        // </Box>
    )
}

const ChildProductOverview = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    const [open, setOpen] = useState(false);

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (params) => {
        if (!params) return;
        console.log(params.row);
    
        const newProductId = parseInt(params.row.product.id);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };


    const handleDeleteClick = () => {
        setOpen(true); // Open confirmation modal
    };

    const [DeleteVirtualProductMutation,{syncIsLoading2}] = useDeleteProductMutation();
    const confirmDelete = async (value) => {
        try {
            const response = await DeleteVirtualProductMutation(props.productId).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, { success: false, error: err.error });
        } finally {
            setOpen(false); // Close modal after deletion
        }
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions handleOpen={() => setAddModalOpen(true)} deleteAction={handleDeleteClick} />,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    

    const [DeleteChildProduct,{syncIsLoading}] = useDeleteChildProductsMutation();
    const handleDeleteChildProduct = async (id) => {
        try {
            const response = await DeleteChildProduct({productId: props.productId, body: { childProducts: [id] }}).unwrap();
            showToastMessageDeleteRequest(t, response);
        } catch (err) {
            showToastMessageRequestError(t('delete_failed'), t, err);
        }
    };

    const columns = [
        {
            field: "sku", 
            headerName: t('sku'),
            flex:1, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Box>
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product?.sku}</Text>
                    </Box>
                );
            }
        },
        {
            field: "description", 
            headerName: t('description'),
            flex:3, 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.product?.description
            }
        },
        {
            field: "quantity", 
            headerName: t('quantity'),
            flex:1, 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row?.quantity
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
                return (
                    <WarehouseVirtualProductDetailsCard onClick={() => getProductDetails(cellValues)} skeleton={cellValues.row.skeleton ?? false} {...cellValues.row}/>
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >

                    // </Box>
                )
            }
        }
    ];

    if (props.actionButtons) {
        columns.push({
            field: "actions", 
            headerName: "",
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                
                return <IconButton type="button" onClick={() => handleDeleteChildProduct(params.row.product.id)} sx={{p:1}}>
                    <DeleteIcon/>
                </IconButton>
            }
        })
    }

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            {props.productId && 
                <ShDataGrid2 key={theme.palette.mode}
                    content={useGetChildProductsQuery}
                    itemId={props.productId}
                    gridActions={<GridActions handleOpen={() => setAddModalOpen(true)} deleteAction={handleDeleteClick}></GridActions>} 
                    title={t('overview')}
                    onRowClick={getProductDetails}
                    gridOptions={gridOptions} 
                    isSidepanelGrid 
                    columns={columns}>
                </ShDataGrid2>
            }
            <SPStockDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                productId={productId}
                // data={productData} // Pass the productData as a prop
            ></SPStockDetails>
            { props.actionButtons ? 
                <MAddChildProduct productId={props.productId} onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
            : null
            }
                <MConfirmDelete
                    open={open}
                    handleClose={() => setOpen(false)}
                    confirm={confirmDelete}
                />
        </Box>
    );
}

export default ChildProductOverview;