import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const INVOICE_BASE = "/invoices";
const buildUrl = (endpoint) => INVOICE_BASE + endpoint;
const API_TAG = 'Invoices';
const LOGS_TAG = 'LogsInvoice';

const invoiceSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInvoices: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: API_TAG }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getInvoices', API_TAG)
        }),
        getInvoice: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: `${API_TAG}Single`, id: arg }]
        }),
        addInvoice: builder.mutation({
            query: invoiceInfo => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: invoiceInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG}],
        }),
        updateInvoice: builder.mutation({
            query: invoiceInfo => ({
                url: buildUrl(`/${invoiceInfo.id}`),
                method: 'PUT',
                body: invoiceInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }],
        }),
        finalizeInvoice: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/finalize/${id}`),
                method: 'PUT',
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }],
        }),
        deleteInvoice: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }],
        }),
        getInvoiceInvoiceProducts: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/invoiceproducts?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `${API_TAG}Single`, id: arg.id }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getInvoiceLines', `${API_TAG}Single`)
        }),
        addInvoiceInvoiceProducts: builder.mutation({
            query: invoiceProductsInfo => ({
                url: buildUrl(`/invoiceproducts/new`),
                method: 'POST',
                body: invoiceProductsInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }, {type: LOGS_TAG}],
        }),
        deleteInvoiceInvoiceProducts: builder.mutation({
            query: deleteObject => ({
                url: buildUrl(`/${deleteObject.invoiceId}/invoiceproducts/`),
                method: 'DELETE',
                body: deleteObject.invoiceProductIds,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }, {type: LOGS_TAG}],
        }),
        updateInvoiceInvoiceProducts: builder.mutation({
            query: updateObject => ({
                url: buildUrl(`/${updateObject.invoiceId}/invoiceproducts`),
                method: 'PUT',
                body: updateObject.data,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }, {type: LOGS_TAG}],
        }),
        getInvoiceLines: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/invoicelines?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `${API_TAG}Single`, id: arg.id }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getInvoiceLines', `${API_TAG}Single`)
        }),
    })
});

export const {
    useGetInvoicesQuery,
    useGetInvoiceInvoiceProductsQuery,
    useAddInvoiceInvoiceProductsMutation,
    useGetInvoiceQuery,
    useAddInvoiceMutation,
    useUpdateInvoiceMutation,
    useFinalizeInvoiceMutation,
    useDeleteInvoiceMutation,
    useDeleteInvoiceInvoiceProductsMutation,
    useUpdateInvoiceInvoiceProductsMutation,
    useGetInvoiceLinesQuery
  } = invoiceSlice;
