import { Box, ButtonBase } from "@mui/material";
import { useTheme } from "@mui/material";
import Text from "../../../../theme/text/Text";
import { useEffect, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import { useTranslation } from "react-i18next";
import { useGetOrderShipmentsQuery, useGetShipmentLabelQuery } from "../../../../../newapi/order/ordersSlice";
import { tokens } from "../../../../../theme";
import { ReactComponent as DhlLogo } from '../../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../../../styles/svg/postnl.svg';
import { ReactComponent as BolLogo } from '../../../../../styles/svg/bol.svg';
import { ReactComponent as ParcelLogo } from '../../../../../styles/svg/parcel.svg';
import cx from 'classnames';
import Shbutton from "../../../../theme/buttons/Shbutton";
import { Download, PictureAsPdf } from "@mui/icons-material";
import { getShipperColorSettingsByType } from "../../../../Streams/StreamShipperSettings";
import Subheading from "../../../../theme/text/Subheading";
import TopRight from "../../../../theme/positionals/TopRight";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";

const ShipperLogoMap = {
    postnl: PostNLLogo,
    dhl: DhlLogo,
    dpd: DpdLogo,
    bol: BolLogo,
    parcel: ParcelLogo,
};

const getShipperColorSettings = (shipperType) => {
    console.log("shipperType",shipperType);
    switch (shipperType) {
        case 'postnl':
            return { background: '#FFE9D6' };
        case 'dhl':
            return { background: '#FFF8F2' };
        case 'dpd':
            return { background: '#E0F7FA' };
        case 'bol':
            return { background: '#E8EAF6' };
        default:
            return { background: '#FFFFFF' };
    }
};

const Shipments = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const colors = tokens(theme.palette.mode);
    const [getLabelId, setGetLabelId] = useState(null);

    const { data: shipmentsData, isLoading: shipmentsIsLoading, isError: shipmentsIsError } = useGetOrderShipmentsQuery(props.data.id, {
        skip: !props.data.id
    });


    const { data: shipmentLabelData, isLoading: shipmentLabelIsLoading, isError: shipmentLabelIsError } = useGetShipmentLabelQuery(getLabelId, {
        skip: !getLabelId || getLabelId === 0
    });

    useEffect(() => {
        if (shipmentLabelData) {
            shipmentLabelData.labels?.forEach(label => {
                setGetLabelId(0);
                const decodedData = atob(label);
                const arrayBuffer = new ArrayBuffer(decodedData.length);
                const uint8Array = new Uint8Array(arrayBuffer);
                for (let i = 0; i < decodedData.length; i++) {
                    uint8Array[i] = decodedData.charCodeAt(i);
                }
                const blob = new Blob([uint8Array], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(blob);
                window.open(pdfUrl, '_blank');
            });
        }
    }, [shipmentLabelData]);

    const renderShipperLogo = (shipperType) => {
        const LogoComponent = ShipperLogoMap[shipperType];
        return LogoComponent ? <LogoComponent className={shipperType} /> : null;
    };

    return (
        <Box
            sx={{
                flexDirection:"column",
                padding:2,
                flex:1,
                width:1,
                // width: 'calc(50% - 12px)', // Subtract the gap from the total width
                display: 'flex',
                gap:3,
                "@media screen and (max-width: 47.9375em)" : {
                    gap:1.5,
                    position:"relative",
                }
            }}
        >
            {shipmentsIsLoading && <ShTextSkeleton />}
            {shipmentsIsError && <Text color="error">{t('error.loading')}</Text>}
            {shipmentsData && shipmentsData.map((shipment, index) => {
                console.log(shipment);
                const colorSettings = getShipperColorSettingsByType(shipment?.shipper?.type);

    console.log(colorSettings);
                return (
                    <Box
                    className={cx(``, {
                    //   'is-dragging' : isDragging
                    })}
                    sx={{
                        // background: "#FFF8F2",
                        background:colorSettings ? colorSettings.background : colors.grey[100],
                        border:`1px solid ${colorSettings ? colorSettings.streamrulehover : colors.grey[200]}`,
                        borderRadius:3,
                        padding:2,
                        width:1,
                        // overflow:"hidden",
        
                        "& .postnl" : {
                            width:24,
                            height:24,
                        },
        
                        "& .dpd" : {
                            width:24,
                            height:24,
                        },
                        "& .dhl" : {
                            width:24,
                            height:24,
                        },
                        "& .bol" : {
                            width:32,
                            // width:24,
                            height:16,
                        },
        
                        
                        "@media screen and (max-width: 47.9375em)" : {
                            pl:0,
                            pr:0,
                            pb:0,              
                            position:"relative"              
                        }
                    }}
                >
                    {/* Header */}
                    {/* Colli */}
                        <Box
                            sx={{
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:"flex-start"
                            }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    gap:2,
                                    alignItems:"center",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        alignItems:"flex-start",
                                        px:2,
                                    }
                                }}
                            >
                                {shipment.shipper &&
                                    <Box
                                        sx={{
                                            width:32,
                                            height:32,
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                            "@media screen and (max-width: 47.9375em)" : {
                                                // width:"unset",
                                                // height:"unset"
                                            }
                                        }}
                                    >
                                        {renderShipperLogo(shipment?.shipper?.type)}
                                    
                                    </Box>
                                }
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            display:"none",
                                        }
                                    }}
                                >
                                    <Subheading color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null} >{shipment?.shipper.name}</Subheading>
                                </Box>

                                {/* Mobile  */}
                                <Box
                                    sx={{
                                        display:"none",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            display:"flex",
                                            flexDirection:"column"
                                        }
                                    }}
                                >
                                    <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null} >{shipment?.shipper.name}</Text>
                                    <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null}  bold>{new Date(shipment?.shipmentDate).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}</Text>
                                </Box>

                            </Box>

                            {/* Hide button on mobile */}
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        display:"none",
                                    }
                                }}
                            >

                                <ButtonBase
                                    sx={{
                                    }}
                                >
                                    <Box

                                        onClick={() => setGetLabelId(shipment?.id)}
                                        sx={{
                                                background: colorSettings ? colorSettings.base : colors.grey[400],
                                                display:"flex",
                                                px:3,
                                                height:40,
                                                borderRadius:5,
                                                gap:3,
                                                color:"#fff",
                                                fontSize:"14px",
                                                fontFamily:"Roboto, sans-serif",
                                                fontWeight:500,
                                                alignItems:"center",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                ml:"auto"
                                            }}
                                        >
                                        {t("download_label")}
                                        </Box>
                                        <Box
                                            sx={{
                                                ml:"auto",
                                                display:"flex",
                                                alignItems:"center",
                                            }}
                                        >
                                        <Download/>
                                        </Box>
                                    </Box>
                                </ButtonBase>
                                {/* <Shbutton variant="contained">Download</Shbutton> */}
                            </Box>

                            <Box
                                sx={{
                                    display:"none",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        display:"unset",
                                    }

                                }}
                            >

                                <TopRight>
                                    <Box
                                        sx={{
                                            height:32,
                                            px:2,
                                            background:colorSettings ? colorSettings.streamrule : colors.grey[200],
                                            borderTopLeftRadius:6,
                                            borderTopRightRadius:12,
                                            borderBottomLeftRadius:12,
                                            borderBottomRightRadius:6,
                                            lineHeight:"32px",
                                            fontFamily:"Roboto, sans-serif",
                                            fontSize:"14px",
                                            color: colorSettings ? colorSettings.base : colors.grey[100],
                                            fontWeight:500
                                        }}
                                    >
                                        {shipment?.trackingNumber}
                                    </Box>

                                </TopRight>
                            </Box>

                        </Box>

                        <Box 
                            sx={{
                                pt:1,
                                display:"flex",
                                gap:8,
                                pl:6,
                                "@media screen and (max-width: 47.9375em)" : {
                                    px:2,
                                    pt:2,
                                    gap:4,
                                    pl:3,
                                }
                            }}
                        >
                            <Box 
                                sx={{
                                    display:"flex",
                                    flexDirection:"column", 
                                    "@media screen and (max-width: 47.9375em)" : {
                                        display:"none"
                                    }
                                }}
                            >
                                <Box sx={{display:"flex",flexDirection:"column"}}>
                                    <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,.55)" : null}  >{t("shipped_date")}</Text>
                                    <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null}  bold>{new Date(shipment?.shipmentDate).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}</Text>
                                </Box>
                                <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null} >{shipment?.trackingNumber}</Text>
                            </Box>

                            <Box 
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        pl:5,
                                    }
                                }}
                            >
                                <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,.55)" : null} light>{t('address')}</Text>
                                <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null}  bold>{shipment?.street} {shipment?.house_nr}{shipment?.house_nr_extra ? ` ${shipment?.house_nr_extra}` : ''}</Text>
                                <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null} >{shipment?.postal} {shipment?.city}, {shipment?.country}</Text>
                            </Box>
{/* 
                            <Shbutton
                            variant="contained"
                                onClick={() => setGetLabelId(shipment?.id)}
                                sx={{
                                    //background: colorSettings.buttonBackground,
                                    //color: colorSettings.buttonText,
                                    paddingTop:"16px",
                                    marginTop:2,
                                    borderRadius: 4,
                                    textAlign: "center",
                                    "&:hover": {
                                        background: colorSettings.buttonHoverBackground,
                                        cursor: "pointer",
                                    },
                                }}
                            >
                                <Download />
                            </Shbutton> */}

                        </Box> 
                        <ButtonBase
                            sx={{
                                display:"none",
                                "@media screen and (max-width: 47.9375em)" : {
                                    mt:3,
                                    display:"block",
                                    width:1,
                                }

                            }}
                        >
                            <Box

                                onClick={() => setGetLabelId(shipment?.id)}
                                sx={{
                                    display:"none",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        background: colorSettings ? colorSettings.base : colors.grey[100],
                                        display:"flex",
                                        px:3,
                                        height:40,
                                        borderBottomLeftRadius:12,
                                        borderBottomRightRadius:12,
                                        color:"#fff",
                                        fontSize:"14px",
                                        fontFamily:"Roboto, sans-serif",
                                        fontWeight:500,
                                        alignItems:"center",
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        ml:"auto"
                                    }}
                                >
                                    {t("download_label")}
                                </Box>
                                <Box
                                    sx={{
                                        ml:"auto",
                                        display:"flex",
                                        alignItems:"center",
                                    }}
                                >
                                <Download/>
                                </Box>
                            </Box>
                        </ButtonBase>
                    </Box>
                );
            })}
        </Box>
         
    );
}

export default Shipments;
