import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import TopRight from "../../positionals/TopRight";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const WarehouseStockWarehouseLocationCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();

    // State for icon type and color
    const [iconType, setIconType] = useState('');
    const [color, setColor] = useState('grey');

    useEffect(() => {
        if(!props.skeleton){
            // Map warehouse location types to icons
            const iconMap = {
                bulk: "box",
                transition: "truck",
                general: "pick",
                link: "link",
            };

            // Determine the icon type based on warehouseLocation
            const type = iconMap[props.warehouseLocation?.warehouseLocationType.type] || props.warehouseLocation?.warehouseLocationType.type;
            setIconType(type);

            // Map icon type to color
            const getColor = (icon) => {
                switch (icon) {
                    case iconMap.bulk:
                        return "pink";
                    case iconMap.transition:
                        return "cyan";
                    case iconMap.general:
                        return theme.palette.mode === "dark" ? "blue" : "primary";
                    case iconMap.link:
                        return "purple";
                    default:
                        return "grey";
                }
            };

            // Set the color based on the icon type
            setColor(getColor(type));
        }

    }, [props.warehouseLocation,props.skeleton]); // Re-run effect when warehouseLocation or theme changes


    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick} sx={{p:1}}>
            <Box>
                <Box>
                    <Box display={"flex"} width={props.skeleton ? "70%" : null} flexDirection={"column"}
                    sx={{
                        p:1,
                        pb:0,
                    }}>
                        {props.skeleton ?
                            <ShDoubleTextSkeleton/>
                            :    
                            <>
                                <Text bold>{props.warehouseLocation.location}</Text>
                                <Text light>{props.warehouseLocation.warehouse?.name}</Text>
                            </>
                        }
                    </Box>

                    <TopRight>
                        <CardLabel variant={color}>
                            {t(iconType)}
                        </CardLabel>
                    </TopRight>

                    {/* Holds tht date and batch */}
                    {/* <Box marginTop={3} display={"flex"}>
                        <Box
                            sx={{
                                marginRight:3,
                                display:"flex",
                                flexDirection:"column"
                            }}
                        >
                            <Text light>Batch</Text>
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text semibold>{props.row.batch}</Text>
                            }
                        </Box>
                        <Box
                            sx={{
                                marginRight:3,
                                display:"flex",
                                flexDirection:"column"
                            }}
                        >
                            <Text light>THT-datum</Text>
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text semibold>{props.row.thtDate}</Text>
                            }
                        </Box>
                    </Box> */}
                    {/* Holds all physical stock */}
                    <Box 
                        sx={{
                            mt:2,
                            display:"flex",
                            justifyContent:"space-between",
                            gap:4,
                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[50],
                            p:1,
                            borderRadius:2,
                        }}
                    >
                        <Box
                            sx={{
                                minWidth:110,
                                display:"flex",
                                flexDirection:"column",
                                gap:1,
                            }}
                        >
                            <Text light>{t("administrative")}</Text>
                            <Text light>{t("physical")}</Text>
                            <Text light>{t("in_order")}</Text>
                            <Text light>{t("blocked")}</Text>
                            <Text light>{t("in_pick_process")}</Text>
                            <Text light>{t("reserved")}</Text>
                        </Box>
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                alignItems:"flex-end",
                                gap:1,
                            }}
                        >
                            {
                                props.skeleton ? 
                                <>
                                    <ShTextSkeleton/>
                                    <ShTextSkeleton/>
                                    <ShTextSkeleton/>
                                    <ShTextSkeleton/>
                                    <ShTextSkeleton/>
                                    <ShTextSkeleton/>
                                </>
                                :
                                <>
                                    <Text >{props.available ?? 0}</Text>
                                    <Text >{props.quantity ?? 0}</Text>
                                    <Text >{props.inOrder ?? 0}</Text>
                                    <Text >{props.blocked ?? 0}</Text>
                                    <Text >{props.pickingprocess ?? 0}</Text>
                                    <Text >{props.reserved ?? 0}</Text>
                                </>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}
