import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import TopRight from "../../positionals/TopRight";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";
import { useEffect, useState } from "react";
import MessageIcon from '@mui/icons-material/MessageOutlined';
import { useTranslation } from "react-i18next";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";

export const WarehouseInboundLineProductCard = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    

    
    // State to hold the formatted date, label color, and label text
    const [formattedDate, setFormattedDate] = useState("-");
    const [labelColor, setLabelColor] = useState("grey");
    const [labelText, setLabelText] = useState("");

    useEffect(() => {
        
        // Get status from params or default to "unknown"
        const status = props.state || "unknown";

        // Define label configuration based on status
        const labelConfig = {
            notreceived: {
                color: "grey",
                text: t('not_received'),
            },
            partlyreceived: {
                color: "orange",
                text: t('partly_received'),
            },
            fullyreceived: {
                color: "green",
                text: t('fully_received'),
            },
            unknown: {
                color: "red",
                text: t('unknown'),
            },
        };

        // Update state for label based on status
        const { color, text } = labelConfig[status] || labelConfig.unknown;
        setLabelColor(color);
        setLabelText(text);

    }, [props]);  // Dependencies to re-run when any of these change


    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box display={"flex"} flexDirection={"column"}>
                        {props.skeleton ?
                            <ShDoubleTextSkeleton/> 
                            :
                            <>
                                <Text bold>{props.isDescriptionRow ? "" : props.product.sku}</Text>
                                <Text>{props.isDescriptionRow ? props.description : props.product.description}</Text>
                            </>
                        }
                    </Box>

                    {props.remark !== "" ?
                        <TopRight>
                            <CardLabel hasright variant={labelColor} position="child-tr">
                                {props.skeleton ? null : labelText}
                            </CardLabel>
                            <CardLabel hasleft variant={theme.palette.mode === "dark" ? "greyLight" : "grey"} icon position="child-tr">
                                <MessageIcon fontSize="small"/>
                            </CardLabel>
                        </TopRight>
                    : 
                    
                        <TopRight>
                            <CardLabel  variant={labelColor}>
                                {props.skeleton ? null : labelText}
                            </CardLabel>
                        </TopRight>
                    }

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("forecasted")}</Text>

                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text semibold>
                                    {props.amountForecasted}
                                    {/* {props.stock && props.stock.total_quantity ? props.stock.total_quantity : 0}     */}
                                </Text>
                            }
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("lines")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text semibold>
                                    {props.amountReceived}
                                    {/* {props.stock && props.stock.total_available ? props.stock.total_available : 0} */}
                                </Text>
                            }
                        </Box>

                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}
