import { Box, useTheme } from "@mui/material"
import { useState, useEffect } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import {  showToastMessagePostRequest, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedNumberInput from "../../theme/inputs/ShValidatedNumberInput";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import ShDateRangePicker from "../../theme/ShDateRangePicker";
import { yupRequired, yupDecimalRequired, yupNumberRequired } from "../../../utils/validation";
import { useUpdateInvoiceInvoiceProductsMutation } from "../../../newapi/financial/invoiceSlice";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
// import { required } from '../../utils/validation';

const MEditInvoiceInvoiceProducts = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);
    const [updateInvoiceProduct,{isLoading}] = useUpdateInvoiceInvoiceProductsMutation();

    const select = (selected) => {
        setSelected(selected);
    }

    const [selectedInvoiceProducts, setSelectedInvoiceProducts] = useState([]);
    const [updateFields, setUpdateFields] = useState({
        amount: 0,
        price: 0,
    });

    useEffect(() => {
        if (props.invoiceProducts && props.invoiceProducts.length > 0) {
            setSelectedInvoiceProducts(props.invoiceProducts);
            setUpdateFields({
                amount: props.invoiceProducts[0].amount,
                price: props.invoiceProducts[0].price,
            });
        }
    }, [props.invoiceProducts]);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        amount: selectedInvoiceProducts.length === 1 ? yupNumberRequired('amount', t) : null,
        price: yupDecimalRequired('price', t),
    };
    
    const schema = yup.object().shape(rules);
   
    const SaveInvoiceProduct = async () => {
        try {
            const updateData = selectedInvoiceProducts.map(product => ({
                id: product.id,
                price: updateFields.price,
                ...(selectedInvoiceProducts.length === 1 && {
                    amount: updateFields.amount
                })
            }));

            // // If validation is successful, proceed with the  call
            const data = await updateInvoiceProduct({invoiceId: props.invoiceId, data: {invoiceProducts: updateData}}).unwrap();
            showToastMessagePostRequest(t, data);

             props.handleClose();
        } catch (err) {
          if (err.name === 'ValidationError') {
            // Create an errors object where keys are field names and values are error messages
            const errors = err.inner.reduce((acc, currentError) => ({
              ...acc,
              [currentError.path]: currentError.message
            }), {});
      
            // Set the validation errors in the state
            setFieldErrors(errors);
          } else {
            // Handle other errors, such as network errors
            showToastMessageRequestError(t("create_failed"), t, err);
          }

        }
    };

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        if (!/^\d*\.?\d*$/.test(value) && key === "price") {
            return;
        }

        setUpdateFields({
            ...updateFields,
            [key]: value
        });

        // Optionally clear the error for that field
        setFieldErrors({
          ...fieldErrors,
          [key]: ''
        });
      };      

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("edit_invoice_mutations")}</Subheading>
            </Box>

            {/* BODY */}
            
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    pb:2,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                {selectedInvoiceProducts.length === 1 && (
                    <>
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:4
                        }}
                    >

                        <Box
                            sx={{
                                width:178,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text>{t("amount")}</Text>&nbsp;
                        </Box>
                        
                        <Box 
                            sx={{
                                display:"flex",
                                flex:1,
                            }}
                        >
                            <Box    
                                sx={{
                                    flex:1,
                                    borderRadius:4,
                                    display:"flex",
                                    gap: 1
                                }}
                            >
                            <ShValidatedNumberInput
                                btnSize={"24"}
                                name="amount"
                                value={parseInt(updateFields.amount)}
                                onChange={handleChange}
                                error={fieldErrors.amount}
                            />
                            </Box>
                            
                        </Box>
                    </Box>
                </>)}
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:4
                    }}
                >

                    <Box
                        sx={{
                            width:178,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text>{t("price")}</Text>&nbsp;
                    </Box>
                    
                    <Box 
                        sx={{
                            display:"flex",
                            flex:1,
                        }}
                    >
                        <Box    
                            sx={{
                                flex:1,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32,
                                gap: 1
                            }}
                        >
                        <ShValidatedInput
                            name="price"
                            value={updateFields.price}
                            onChange={handleChange}
                            error={fieldErrors.price}
                        />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"}
                    block
                    variant="contained" 
                     onClick={() => SaveInvoiceProduct()}
                    >{t("save")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MEditInvoiceInvoiceProducts;
