import { Box, ButtonBase, InputBase, TextField, Typography, useTheme } from "@mui/material"
import { Link, useNavigate, useParams } from "react-router-dom"
import { tokens } from "../../theme";

import cx from "classnames";
import { Formik } from "formik";
import * as yup from "yup";
import Shbutton from "../../components/theme/buttons/Shbutton";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { ReactComponent as StockVisual } from '../../styles/svg/rmaportal/stockvisual.svg';
import Text from "../../components/theme/text/Text";
import Subheading from "../../components/theme/text/Subheading";
import ShSwitch from "../../components/theme/inputs/ShSwitch";
import { useEffect, useState } from "react";
import { StyledDropdown } from "../../components/theme/dropdowns/StyledDropdown";
import { useCreateReturnForReturnMutation,  useGetOrderForReturnQuery } from "../../newapi/return/returnsSlice";
import { getTranslatedDateAndTime } from "../../utils";

import { ReactComponent as PostNLLogo } from '../../styles/svg/rmaportal/postnl-detail.svg';
import { showToastMessage } from "../../utils/toasts";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

const OrderDetailsContent = (props) => {

    const [details, setDetails] = useState(null);

    const handleReasonChange = (selected) => {
        console.log(props.reasonOptions);
        console.log(selected);
        console.log("prop",props.reasonOptions[parseInt(selected)].id)
        setDetails(prevDetails => ({
            ...prevDetails,
            reasonId: props.reasonOptions[parseInt(selected)].id
        }));
    }

    const handleAmountChange = (selected) => {
        setDetails(prevDetails => ({
            ...prevDetails,
            amount: props.amountOptions[parseInt(selected)].name
        }));
    }

    const handleInputChange = (e,val) => {
        setDetails(prevDetails => ({
            ...prevDetails,
            remark: val
        }));
    }

    useEffect(() => {
        setDetails(prevDetails => ({
            ...prevDetails,
            active: props.active
        }));
    }, [props.active]);

    useEffect(() => {
        setDetails(props.data);
    }, [props.data]);

    useEffect(() => {
        if(details)
            props.onReturnChange(details);
    }, [details]);

    return (
        <Box
            className={cx('order-inner-content',{
                'active': props.active,
                'cantbereturned': props.cantbereturned
            })}
            TouchRippleProps={{
                style: {
                    color: "#31415f",
                    // color:"rgba(65,128,246,1)",
                    // color:"#fff"
                }
            }}
            sx={{
                padding:1.5,
                borderRadius:4,
                position:"relative",
                
                opacity:1,
                display:"flex",
                flexDirection:"column",
                alignItems:"flex-start",
                background:"rgba(255,255,255,0.55)",
                "&.active": {
                    opacity:1,
                    background:"rgba(255,255,255,1)"
                },
                "&.cantbereturned" : {
                    opacity:0.5,
                }
            }}
        >
            <ButtonBase 
                onClick={() => props.onClick()}
                component="div"
                TouchRippleProps={{
                    style: {
                        color: "#31415f",
                    }
                }}
                sx={{
                    pr:8,
                }}
            >
                <Subheading fontWeight={props.active ? 500 : 400} allowOverflow>
                    {props.title}
                </Subheading>
            </ButtonBase>

            {/* Dropdowns */}
            {props.active &&
            <Box
                sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:2,
                    width:1,
                    mt:3,
                }}
            >
                <Box>
                    <Text>Aantal</Text>
                    <StyledDropdown
                        closeOnSelection
                        options={props.amountOptions}
                        selected={props.amountOptions.findIndex(option => option.name === props.data.amount)}
                        onChange={(selected) => handleAmountChange(selected)}
                        noSelection={t("choose_amount")}
                        multiple={false}
                        displayName={"name"}
                        sx={{
                            base: {
                                background:"#ecf0f5",
                                paddingRight:0.5,
                                mt:1,
                            },
                            text: {
                                fontSize:14,
                                color:"#31415f"

                            },
                            button: {
                                background:"ecf0f5",
                                color:"#31415f !important"
                            },
                            touchProps: {
                                style: { 
                                    color: "#31415f"
                                } 
                            }
                        }}
                    />
                </Box>

                <Box>
                    <Text>{t("reason")}</Text>
                    <StyledDropdown
                        closeOnSelection
                        onChange={(selected) => handleReasonChange(selected)}
                        options={props.reasonOptions}
                        selected={props.reasonOptions.findIndex(option => option.Id === props.data.reasonId)}
                        displayName={"name"}
                        noSelection={t("select_a_reason")}
                        multiple={false}
                        sx={{
                            base: {
                                background:"#ecf0f5",
                                paddingRight:0.5,
                                mt:1,
                            },
                            text: {
                                fontSize:14,
                                color:"#31415f"

                            },
                            button: {
                                background:"ecf0f5",
                                color:"#31415f !important"
                            },
                            touchProps: {
                                style: { 
                                    color: "#31415f"
                                } 
                            }
                        }}
                    />
                </Box>

                <Box>
                    <Text>{t("remark")}</Text>
                    
                    <TextField

                        id="outlined-multiline-static"
                        multiline
                        value={props.data?.remark}
                        onChange={(event) => handleInputChange(event, event.target.value)}
                        rows={3}
                        variant="outlined"
                        sx={{
                            background:"#ecf0f5",
                            borderRadius:3,
                            width:1,
                            border:"none",
                            outlineColor:"transparent !important",
                            color: "#31415f",
                            padding:0,
                            " .MuiOutlinedInput-notchedOutline": {
                                border:"none !important"
                            },
                            " .MuiInputBase-root": {
                                paddingX:2,
                                paddingY:1.5
                            }
                        }}

                    ></TextField>
                </Box>
            </Box>
            }



            {/* Switch */}
            <Box
                sx={{
                    position:'absolute',
                    right:0,
                    top:0,
                }}
            >
                <ShSwitch onChange={() => props.onClick()} checked={props.active}/>
            </Box>
        </Box>
    )
}
const OrderCantBeReturnedDetailsContent = (props) => {
 
    return (
        <>
        <Box
                className={cx('order-inner-content',{
                    'active': props.active,
                    'cantbereturned': props.cantbereturned
                })}
                sx={{
                    padding:1.5,
                    borderRadius:4,
                    position:"relative",
                    pr:7,
                    
                    opacity:1,
                    "&.cantbereturned" : {
                        opacity:0.55,
                    }
                }}
            >
                <Subheading allowOverflow fontWeight={400}>
                    {props.title}
                </Subheading>

            </Box>

            <Box
                sx={{
                    pl:1.5,
                    pb:1.5,
                    mt:"-8px",
                }}
            >
                <Typography 
                    variant="span"
                    sx={{
                        color:"rgba(49,65,95,1)",
                        fontSize:16,
                        fontWeight:500,
                    }}
                >
                {t("item_cant_be_returned")}
                </Typography>
            </Box>
        </>
    )
}

const OrderDetails = (props) => {
    const [active, setActive] = useState(props.data.active ?? false);

    const onChange = () => {
        if(active){
            setActive(false);
        } else {
            setActive(true);
        }
        // setActive((active) => !active);
    }

    

    useEffect(() => {
        
        props.data.active = active;
        props.onReturnChange(props.data);
    }, [active])

    const amountOptions = [{id: -1, name: "Kies een aantal"}, ...Array.from({length: props.amount }, (_, index) => ({id: index +1 , name: index +1}))];
    const reasonOptions = [{id: -1, name: "Kies een reden"}, ...props.reasons.map(reason => ({id: reason.id, name: reason.description}))];
    return (
        
        <Box
            className={cx({
                'active': active
            })}
            sx={{
            }}
        >
            {typeof props.cantbereturned === "undefined" || !props.cantbereturned ?
                <OrderDetailsContent
                    active={active}
                    onClick={onChange}
                    cantbereturned={props.cantbereturned}
                    title={props.title}
                    amountOptions={amountOptions}
                    reasonOptions={reasonOptions}
                    id={props.data?.id}
                    data={props.data}
                    onReturnChange={props.onReturnChange}
                />
            :
                <OrderCantBeReturnedDetailsContent
                    active={active}
                    cantbereturned={props.cantbereturned}
                    title={props.title}
                />
                // null
            }
        </Box>
    )
}


const ItemDetails = (props) => {
    return (
        <Box
        className={cx('order-inner-content',{
        })}
        TouchRippleProps={{
            style: {
                color: "#31415f",
                // color:"rgba(65,128,246,1)",
                // color:"#fff"
            }
        }}
        sx={{
            padding:1.5,
            borderRadius:4,
            position:"relative",
            
            opacity:1,
            display:"flex",
            flexDirection:"column",
            alignItems:"flex-start",
            background:"rgba(255,255,255,1)",
        }}
    >
        <Box 
            sx={{
                // pr:1.5,
            }}
        >
            <Typography
                variant="span"
                sx={{
                    fontWeight:700,
                    color:"#31415f",
                    fontSize:18,
                    lineHeight:"130%",
                }}
            >
                {props.title}
            </Typography>
            {/* <Heading fontWeight={500} allowOverflow>
                {props.title}
            </Heading> */}
        </Box>

        {/* Dropdowns */}
        <Box
            sx={{
                display:"flex",
                flexDirection:"column",
                gap:2,
                width:1,
                mt:2,
            }}
        >
            <Box
                sx={{
                    display:"flex"
                }}
            >
                <Box
                    sx={{
                        width:"64px",
                        flexShrink:0,
                    }}
                >
                    <Typography
                        variant="span"
                        sx={{
                            fontWeight:700,
                            color:"#31415f",
                            fontSize:17,
                        }}
                    >
                        {t("amount")}: 
                    </Typography>
                </Box>
                <Typography
                    variant="span"
                    sx={{
                        fontWeight:400,
                        color:"#31415f",
                        fontSize:17,

                    }}
                >
                    {props.amount}
                </Typography>
            </Box>

            <Box
                sx={{
                    display:"flex"
                }}
            >
                <Box
                    sx={{
                        width:"64px",
                        flexShrink:0,
                    }}
                >
                    <Typography
                        variant="span"
                        sx={{
                            fontWeight:700,
                            color:"#31415f",
                            fontSize:17,
                        }}
                    >
                        {t("reason")}: 
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        gap:0.5,
                    }}
                >

                    <Typography
                        variant="span"
                        sx={{
                            fontWeight:400,
                            color:"#31415f",
                            fontSize:17,

                        }}
                    >
                        {props.reason}
                    </Typography>
                    {/* Opmerking */}
                    <Typography
                        variant="p"
                        sx={{
                            fontWeight:400,
                            color:"rgba(49,65,95,.75)",
                            fontSize:15,
                            textAlign:"justify",
                            lineHeight:"130%",
                        }}
                    >
                        {props.remark}
                    </Typography>
                </Box>
            </Box>

        </Box>

    </Box>
    )
}

const OrderD = (props) => {

    const { t,i18n } = useTranslation();
    

    return (
        <Box
            sx={{
                width:1024,
                zIndex:2,
            }}
        >

            <Box>
                <Typography
                    sx={{
                        fontSize: "2.75em", 
                        fontWeight: "100", 
                        color: "#31415f", 
                        alignItems: "center",
                    }}
                >
                    {t("order")} <b>{props.orderData?.order?.webshopreference}</b> {t("of")}
                </Typography>
                <Typography
                    variant="h1"
                    sx={{
                        fontSize:"2.75em",
                        fontWeight:"bold",
                        color:"#31415f",
                        mb:3,
                    }}
                >
                    {props.orderData?.order?.deliveryName}
                </Typography>
            </Box>

            {props.orderData?.order?.returns.length > 0 &&
                <Box
                    sx={{
                        width:1,
                        borderRadius:4,
                        // background:"rgba(10,178,198,.15)",
                        background:"rgba(255,255,255,.35)",

                        ".return-exists" : {
                            height:48,
                            display:"flex",
                            alignItems:"center",
                            pl:0.5,
                            color:"#31415f",
                            textDecoration:"none",
                            "&:hover" : {
                                textDecoration:"underline",
                            }
                        }
                    }}
                >
                    {props.orderData?.order?.returns.map((retour,i) => (
                        <Link to={"/"+retour.hash + "/status"} className={"return-exists"}>
                            <Box
                                sx={{
                                    height:40,
                                    width:40,
                                    borderRadius:3,
                                    background:"rgba(10,178,198,.15)",
                                    color:"rgba(10,178,198,1)",
                                    // color:"#fff",
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    mr:2,
                                }}
                            >
                                <LocalShippingOutlinedIcon/>
                            </Box>
                            Retour aangemaakt op <b>&nbsp;{new Date().toLocaleDateString(i18n.language)}&nbsp;</b> met track and trace:&nbsp;<b>{retour.inbound?.trackandtrace}</b>
                        </Link>
                    ))}
                </Box>
            }
            <Box
                className={"order-container"}
                sx={{
                    display:"flex",
                    alignItems:"stretch",
                    gap:3,
                    mt:3,
                }}
            >
                <Box
                    className={"order-wrapper"}
                    sx={{
                        width:"66%",
                        background:"rgba(255,255,255,.35)",
                        borderRadius:6,
                        backdropFilter:"blur(4px)",
                    }}
                >
                    <Box className={"order-content"}
                        sx={{
                            padding:1.5,
                            // pb:0,
                        }}
                    >

                        {/* The actual orders */}
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:1.5,
                            }}
                        >
                            <Box
                                sx={{
                                    p:1.5,
                                }}
                            >

                                <Subheading>{t("returnable_products_in_order")}</Subheading>
                            </Box>
                            {/* {props.orderData?.order?.orderlines.map((orderline, index) => (
                                <OrderDetails 
                                    id={orderline.id} 
                                    title={orderline.product.description} 
                                    amount={orderline.amount} 
                                    reasons={props.orderData?.returnreasons} 
                                    cantbereturned={orderline.product.returnable}
                                    data={props.returnRequest.items[index]}
                                    onChange={props.onChange}
                                />
                            ))} */}
                            {props.returnRequest?.items.map((item, index) => (
                                <OrderDetails 
                                    key={item.id}
                                    id={item.id} 
                                    title={props.orderData?.order?.orderlines[index]?.product?.description} 
                                    amount={props.orderData?.order?.orderlines[index]?.amount} 
                                    reasons={props.orderData?.returnreasons} 
                                    cantbereturned={props.orderData?.order?.orderlines[index].product.returnable}
                                    data={item}
                                    onReturnChange={props.onReturnChange}
                                />
                            ))}

                        </Box>


                    </Box>
                </Box>

                {/* Extra order details */}
                <Box
                    className={"order-details"}
                    sx={{
                        // flex:1,
                        width:"34%",
                        flexShrink:0,
                        background:"rgba(255,255,255,.35)",
                        borderRadius:6,
                        backdropFilter:"blur(4px)",
                    }}
                >
                    <Box
                        className={"order-details-content"}
                        sx={{
                            padding:3,
                        }}
                    >
                        {/* Besteld bij */}
                        <Box
                            className={"ordered-at"}
                        >
                            <Typography 
                                variant="p"
                                sx={{
                                    color:"rgba(49,65,95,1)",
                                    fontSize:16,
                                }}
                            >
                                {t("ordered_at")}
                            </Typography><br/>
                            <Typography 
                                variant="p"
                                sx={{
                                    color:"rgba(49,65,95,1)",
                                    fontWeight:"Bold",
                                    fontSize:18,
                                }}
                            >
                                {props.orderData?.order?.relation?.name}
                            </Typography>
                        </Box>

                        {/* Ordered on */}
                        <Box
                            className={"ordered-on"}
                            sx={{
                                mt:3,
                            }}
                        >
                            <Typography 
                                variant="p"
                                sx={{
                                    color:"rgba(49,65,95,1)",
                                    fontSize:16,
                                }}
                            >
                                {t("ordered_on")}
                            </Typography><br/>
                            <Typography 
                                variant="p"
                                sx={{
                                    color:"rgba(49,65,95,1)",
                                    fontWeight:"Bold",
                                    fontSize:18,
                                }}
                            >
                                {getTranslatedDateAndTime(props.orderData?.order?.date_sent, t).date}
                            </Typography>
                        </Box>

                        {/* Return by */}
                        <Box
                            className={"return-by"}
                            sx={{
                                mt:3,
                            }}
                        >
                            <Typography 
                                variant="p"
                                sx={{
                                    color:"rgba(49,65,95,1)",
                                    fontSize:16,
                                }}
                            >
                                {t("returnable_until")}
                            </Typography><br/>
                            <Typography 
                                variant="p"
                                sx={{
                                    color:"rgba(49,65,95,1)",
                                    fontWeight:"Bold",
                                    fontSize:18,
                                }}
                            >
                                {getTranslatedDateAndTime(new Date(new Date(props.orderData?.order?.date_sent).getTime() + 14 * 24 * 60 * 60 * 1000), t).date}
                            </Typography>
                        </Box>
                    </Box>

                </Box>
            </Box>
            <Box
                className={"button-wrapper-container"}
                sx={{
                    display:"flex",
                    gap:3,
                    mt:3,
                }}
            >
                <Box
                    className={"button-wrapper"}
                    sx={{
                        width:"66%",
                        borderRadius:6,
                    }}
                >
                    <ButtonBase
                        type="button"
                        onClick={() => props.setActiveTab("confirm")}
                    // onClick={() => switchInnerEnvironment(nav[selectedEnvironmentIndex])}
                        sx={{
                            color:"white",
                            fontWeight:"700",
                            fontSize:16,
                            // justifyContent:"flex-start",
                            height:"48px",
                            width:"48px",
                            alignItems:"center",
                            // pl:2,
                            // background:"rgba(255,255,255,.15)",
                            background:"rgba(65,128,246,1)",
                            borderRadius:6,
                            flexShrink:0,
                            width:1,
                            transition:"250ms",
                            "&:hover" : {
                                background:"#0057FA",
                                transition:"250ms"
                            },   
                            
                        }}
                    >
                        {t("create_return")}
                            {/* <ArrowBackIcon fontSize="small"/> */}
                    </ButtonBase>
                </Box>

                {/* Extra order details */}
                <Box
                    className={"button-empty-space"}
                    sx={{
                        width:"34%",
                    }}
                >

                </Box>
            </Box>

        </Box>
    )
}

const Confirm = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    const navigate = useNavigate();


    const {relation} = useParams();

    const [createReturnMutation, { isLoading: isCreatingReturn }] = useCreateReturnForReturnMutation();

    const handleFormSubmit = async (values) => {
        try {
            const data = await createReturnMutation({ relation: relation, body: props.returnRequest }).unwrap();
            if(data?.success && data?.url){
                window.location.href = data.url;
            } else {
                showToastMessage("error", t('create_return_failed'));
            }
        } catch (error) {
            console.error("Error creating return:", error);
            showToastMessage("error", t('create_return_failed'));
        }
    }

    return (

        <Box
            sx={{
                width:1024,
                zIndex:2,
                "@media screen and (max-width: 1120px)" : {
                    width:768
                },
                "@media screen and (max-width: 920px)" : {
                    px:4,
                },
                "@media screen and (max-width: 768px)" : {
                    px:3,
                }
            }}
        >


            <Box>
                <Typography
                    sx={{
                        fontSize: "2.75em", 
                        fontWeight: "100", 
                        color: "#31415f", 
                        alignItems: "center",
                    }}
                >
                    {t("order")} <b>{props.orderData?.order?.webshopreference}</b> {t("of")}
                </Typography>
                <Typography
                    variant="h1"
                    sx={{
                        fontSize:"2.75em",
                        fontWeight:"bold",
                        color:"#31415f",
                        mb:3,
                    }}
                >
                    {props.orderData?.order?.deliveryName}
                </Typography>
            </Box>

            <Box
                className={"order-container"}
                sx={{
                    display:"flex",
                    alignItems:"stretch",
                    gap:3,
                    mt:3,
                    "@media screen and (max-width: 1120px)" : {
                        flexDirection:"column"
                    }
                }}
            >
                <Box
                    className={"order-wrapper"}
                    sx={{
                        width:"66%",
                        background:"rgba(255,255,255,.35)",
                        borderRadius:6,
                        backdropFilter:"blur(4px)",
                        "@media screen and (max-width: 1120px)" : {
                            width:1
                        }
                    }}
                >
                    <Box className={"order-content"}
                        sx={{
                            padding:1.5,
                            // pb:0,
                        }}
                    >

                        {/* The actual orders */}
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:1.5,
                            }}
                        >
                            <Box
                                sx={{
                                    p:1.5,
                                }}
                            >

                                <Subheading>{t("check_and_confirm")}</Subheading>
                            </Box>
                            {props.returnRequest?.items.filter(item => item.active === true).map((item, index) => (
                                <ItemDetails
                                    title={props.orderData?.order?.orderlines[index]?.product?.description} 
                                    amount={item.amount}
                                    reason={props.orderData?.returnreasons?.find(reason => reason.id === item.reasonId)?.description ?? t("unknown")}
                                    remark={item.remark}
                                />
                            ))}
                            

                        </Box>


                    </Box>
                </Box>

                {/* Extra order details */}
                <Box
                    className={"order-details"}
                    sx={{
                        // flex:1,
                        width:"34%",
                        flexShrink:0,
                        background:"rgba(255,255,255,.35)",
                        borderRadius:6,
                        backdropFilter:"blur(4px)",
                        "@media screen and (max-width: 1120px)" : {
                            width:1
                        }
                    }}
                >
                    <Box
                        className={"order-details-content"}
                        sx={{
                            padding:3,
                        }}
                    >
                        {/* Besteld bij */}
                        <Box
                            className={"ordered-at"}
                        >
                            <Typography 
                                variant="p"
                                sx={{
                                    color:"rgba(49,65,95,1)",
                                    fontWeight:"Bold",
                                    fontSize:17,
                                }}
                            >
                                {t("return_via")}
                            </Typography>
                            <Box
                                sx={{
                                    mt:2.5,
                                    p:2,
                                    background:"rgba(65,128,246,.15)",
                                    background:"rgba(255,255,255,1)",
                                    borderRadius:3,
                                    display:"flex",
                                    gap:2,
                                }}
                            >
                                <Box
                                    sx={{
                                        flexShrink:0,
                                    }}
                                >

                                    <PostNLLogo width={56} height={56}/>
                                </Box>
                                <Box>

                                    <Typography 
                                        variant="p"
                                        sx={{
                                            color:"rgba(49,65,95,1)",
                                            fontWeight:"Bold",
                                            fontSize:17,
                                        }}
                                    >
                                        PostNL pakket | €2,00-
                                    </Typography>
                                    <br/>
                                    <Typography 
                                        variant="p"
                                        sx={{
                                            color:"rgba(49,65,95,1)",
                                            fontWeight:"400",
                                            fontSize:15,
                                        }}
                                    >
                                        {t("postnl_delivery_instructions")}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                    </Box>

                </Box>
            </Box>
            <Box
                className={"button-wrapper-container"}
                sx={{
                    display:"flex",
                    gap:3,
                    mt:3,
                }}
            >
                
                <Box
                    className={"button-wrapper"}
                    sx={{
                        width:"66%",
                        borderRadius:6,
                        display:"flex",
                        flexDirection:"column",
                        backdropFilter:"blur(4px)",
                        "@media screen and (max-width: 1120px)" : {
                            width:1
                        }
                    }}
                >
                    <Typography 
                        variant="p"
                        sx={{
                            color:"rgba(49,65,95,1)",
                            fontSize:15,
                        }}
                    >
                        {t("return_edit_warning")}
                    </Typography>
                    <Box
                        sx={{
                            display:"flex",
                            gap:3,
                            mt:3,
                        }}
                    >
                        <ButtonBase
                            type="button"
                            onClick={() => props.setActiveTab("order")}
                        // onClick={() => switchInnerEnvironment(nav[selectedEnvironmentIndex])}
                            sx={{
                                color:"#31415f",
                                fontWeight:"700",
                                fontSize:16,
                                // justifyContent:"flex-start",
                                height:"48px",
                                width:"48px",
                                alignItems:"center",
                                // pl:2,
                                background:"rgba(65,128,246,.15)",
                                borderRadius:6,
                                flexShrink:0,
                                flex:1,
                                transition:"250ms",
                                "&:hover" : {
                                    background:"rgba(65,128,246,.25)",
                                    transition:"250ms"
                                },
                                "&:focus" : {
                                    // boxS
                                }
                                
                            }}
                        >
                            {t("last_step")}
                        </ButtonBase>
                        <ButtonBase
                            type="submit"
                            onClick={handleFormSubmit}
                            sx={{
                                color:"white",
                                fontWeight:"700",
                                fontSize:16,
                                // justifyContent:"flex-start",
                                height:"48px",
                                width:"48px",
                                alignItems:"center",
                                // pl:2,
                                // background:"rgba(255,255,255,.15)",
                                background:"rgba(65,128,246,1)",
                                borderRadius:6,
                                flexShrink:0,
                                flex:1,
                                transition:"250ms",
                                "&:hover" : {
                                    background:"#0057FA",
                                    transition:"250ms"
                                },                                     
                            }}
                        >
                            {t("confirm")}
                                {/* <ArrowBackIcon fontSize="small"/> */}
                        </ButtonBase>
                    </Box>
                </Box>

                {/* Extra order details */}
                <Box
                    className={"button-empty-space"}
                    sx={{
                        width:"34%",
                        "@media screen and (max-width: 1120px)" : {
                            display:"none"
                        }
                    }}
                >

                </Box>
            </Box>

        </Box>

    )
}

// export default Confirm

const Order = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    const navigate = useNavigate();

    
    const {relation} = useParams();


    const {data: orderData, isSuccess, isError} = useGetOrderForReturnQuery(relation, {
        skip: !relation
    });  

    // props.setRelation(); set the relation for navigation

    const [returnRequest, setReturnRequest] = useState({
        "items": []
    })

    useEffect(() => {
        console.log("returnrequest", returnRequest);
    }, [returnRequest])
   
    useEffect(() => {
        if (isSuccess && orderData && orderData.order && orderData.order.orderlines) {
            console.log("orderData",orderData);
            const newItems = orderData.order.orderlines.map(orderline => ({
                id: orderline.id,
                active: false,
                amount: 0,
                reasonId: 0,
                remark: ""
            }));
            setReturnRequest(prevState => ({
                ...prevState,
                items: newItems
            }));
            props.setRelation(orderData?.order?.relation?.name);
        }
        if(isError || (orderData && !orderData?.found)){
            navigate(`/notfound`);
        }
    }, [orderData, isSuccess, isError]);


    const handleFormSubmit = async (values) => {
        

    }

    const onReturnItemChange = (item) => {
        if(!item) return;
        setReturnRequest(prevState => {
            const existingItemIndex = prevState.items.findIndex(i => i.id === item.id);
            const updatedItems = [...prevState.items];
            updatedItems[existingItemIndex] = item;
            return { items: updatedItems };
        });
    }

    const [activeTab, setActiveTab] = useState("order");

    const tabToRender = () => {
        if(activeTab === "order") {
            //onChange={onReturnItemChange}
            return <OrderD orderData={orderData} returnRequest={returnRequest} onReturnChange={onReturnItemChange}  setActiveTab={setActiveTab}/>
        } else if(activeTab === "confirm"){
            return <Confirm orderData={orderData} returnRequest={returnRequest} setActiveTab={setActiveTab}/>
        }
    }

    return (
        <Box
            component={"main"}
            sx={{
                // minHeight:"calc(100%)"
                zIndex:1,
            }}
        >
            <Box
                component={"section"}
                sx={{
                    background:"rgba(65,128,246,.10)",
                    background: "radial-gradient(circle, rgba(65,128,246,0.25) 0%, rgba(65,128,246,0.10) 100%)",
                    width:1,
                    minHeight:"100vh",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"flex-start",
                    paddingTop:"200px",
                    paddingBottom:"200px",
                    // height:"100vh",
                    position:'relative',
                    overflow:"hidden",

                    ".stock-visual" : {
                        position:'absolute',
                        bottom:"0",
                        width:1,
                        zIndex:0,
                    }
                }}
            >

                {tabToRender()}

                <StockVisual className="stock-visual"/>
            </Box>
        </Box>
    )
}

export default Order

