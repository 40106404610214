import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import TopRight from "../../positionals/TopRight";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import { useTranslation } from "react-i18next";
import { InputDropdown } from "../../dropdowns/InputDropdown";

const WarehousePickingLinesCard = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();


    const canEditLocation = !props.skeleton &&  props.params.row.stock.product.stocks && props.params.row.stock.product.stocks.length > 1;

    return (
        <BaseCard>
            <Box>
                <Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            // alignItems:"center"
                        }}
                    >
                        <Box 
                            sx={{
                                minWidth:170,
                                display:"flex",
                                flexDirection:"column",
                                overflow:"hidden",
                                flex:1,
                            }}
                        >
                            {props.skeleton ?
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    
                                    <Text bold>{props.params.row.stock?.product?.sku}</Text>
                                    <Text noBreak light>{props.params.row.stock?.product?.description}</Text>
                                </>
                            }
                        </Box>
                    
                        <Box
                            sx={{
                                maxWidth:130,
                            }}
                        >
                            {props.skeleton ? null : canEditLocation ?
                                <InputDropdown
                                    displayName={"warehouseLocation"} 
                                    onChange={(selected) => props.handlePickingLineLocationUpdate(props.params.row, props.params.row.stock.product.stocks[selected])}
                                    noSelection={t("select_location")} //Custom text when nothing is selected
                                    options={props.params.row.stock.product.stocks ?? null}
                                    selected={props.params.row.stock.product.stocks?.findIndex(stock => props.params.row.stock.id === stock.id) ?? -1}
                                    closeOnSelection
                                    stopPropagation
                                />
                                :
                                <TopRight>
                                    <CardLabel variant={theme.palette.mode === "dark" ? "greyLight" : "grey"} position="child-tr" onClick={(event) => {
                                        event.stopPropagation(); // Prevent click from propagating to the row
                                        props.getLocationDetails(props.params);
                                    }}>
                                        {props.params.row.stock?.warehouseLocation?.location}
                                    </CardLabel>
                                </TopRight>
                            }
                        </Box>
                    </Box>

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{ t("amount")}</Text>
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text>{props.params.row.amount}</Text>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default WarehousePickingLinesCard;

export const WarehousePickingLinesInBatchesCard = (props) => {

    const theme = useTheme();
    const {t} = useTranslation();


    const canEditLocation = !props.skeleton &&  props.params.row.alternativeLocations && props.params.row.alternativeLocations.length > 1;

    return (
        <BaseCard>
            <Box>
                <Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            // alignItems:"center"
                        }}
                    >
                        <Box 
                            sx={{
                                minWidth:170,
                                display:"flex",
                                flexDirection:"column",
                                overflow:"hidden",
                                flex:1,
                            }}
                        >
                            {props.skeleton ?
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    
                                    <Text bold>{props.params.row.sku}</Text>
                                    <Text noBreak light>{props.params.row.description}</Text>
                                </>
                            }
                        </Box>
                    
                        <Box
                            sx={{
                                maxWidth:130,
                            }}
                        >
                            {props.skeleton ? null : canEditLocation ?
                                <InputDropdown
                                    displayName={"warehouseLocation"} 
                                    onChange={(selected) => props.handlePickingLineLocationUpdate(props.params.row, props.params.row.alternativeLocations[selected])}
                                    noSelection={t("select_location")} //Custom text when nothing is selected
                                    options={props.params.row.alternativeLocations ?? null}
                                    selected={props.params.row.alternativeLocations?.findIndex(altLocation => props.params.row.productId === altLocation.productId && 
                                        props.params.row.warehouseLocationId === altLocation.warehouseLocationId) ?? -1}
                                    closeOnSelection
                                    stopPropagation
                                />
                                :
                                <TopRight>
                                    <CardLabel variant={theme.palette.mode === "dark" ? "greyLight" : "grey"} position="child-tr" onClick={(event) => {
                                        event.stopPropagation(); // Prevent click from propagating to the row
                                        props.getLocationDetails(props.params);
                                    }}>
                                        {props.params.row.location}
                                    </CardLabel>
                                </TopRight>
                            }
                        </Box>
                    </Box>

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{ t("amount")}</Text>
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text>{props.params.row.amount}</Text>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}