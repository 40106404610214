import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import TopRight from "../../positionals/TopRight";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const RelationReturnCard = (props) => {
    const theme = useTheme();
    const { t,i18n } = useTranslation();

    let status = props.state;
    let labelcolor = "grey";
    let labeltext = t('unknown');
    switch(status){
        case "announced":
            labelcolor = "blue"
            labeltext = t('announced');
            break;
        case "approved":
            labelcolor = "green"
            labeltext = t('approved');
            break;
        case "declined":
            labelcolor = "red"
            labeltext = t('declined');
            break;
        case "cancelled":
            labelcolor = "pink"
            labeltext = t('cancelled');
            break;
        case "receivedwithoutnotice":
            labelcolor = "purple"
            labeltext = t('receivedwithoutnotice');
            break;
        default:
            labelcolor = "orange"
            labeltext = t('unknown');
            break;
    }
    let date = new Date(props.createdAt);

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box
                        sx={{
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >
                        {props.skeleton ?
                            <ShTextSkeleton/> 
                            :
                            <>
                                <Text bold>{props.name}</Text>
                                <Text light>{props.order?.webshopreference}</Text>
                            </>
                        }
                    </Box>
                    <Box 
                        sx={{
                            mt:4,
                        }}
                    >
                        {props.skeleton ?
                            <ShTextSkeleton/> 
                            :
                            <>
                                <Text>
                                    {date.toLocaleDateString(i18n.language)}
                                </Text>&nbsp;&nbsp;
                                <Text light>
                                    {date.toLocaleTimeString(i18n.language,{timeStyle: 'short'})}
                                </Text>
                            </>
                        }
                    </Box>

                    <TopRight>
                        <CardLabel hasright variant={labelcolor} position="child-tr">
                            {labeltext}
                        </CardLabel>
                        <CardLabel 
                            variant={theme.palette.mode === "dark" ? "greyLight" : "grey"}
                            hasleft 
                            colorflip={theme.palette.mode !== "dark"}
                            position="child-tr"
                        >
                            {props.skeleton ? "N/A" : props.itemCount}
                        </CardLabel>
                    </TopRight>
                </Box>
            </Box>
        </BaseCard>
    )
}

export const WarehouseReturnCard = (props) => {
    const theme = useTheme();
    const { t,i18n } = useTranslation();
    const [labelColor, setLabelColor] = useState(theme.palette.mode === "dark" ? "greyLight" : "grey");
    const [labelText, setLabelText] = useState(t('unknown'));

    console.log(props);
    useEffect(() => {
        switch (props.state) {
            case "completed":
                setLabelColor("green");
                setLabelText(t('completed'));
                break;
            case "in_progress":
                setLabelColor("blue");  // Assuming a different color for in_progress
                setLabelText(t('in_progress'));
                break;
            case "notreceived":
                setLabelColor("orange");  // Assuming a different color for in_progress
                setLabelText(t('notreceived'));
                break;
            default:
                setLabelColor(theme.palette.mode === "dark" ? "greyLight" : "grey");
                setLabelText(t('unknown'));
                break;
        }
    }, [props.state, t,theme.palette.mode]); // Dependencies: effect runs when `props.status` or `props.t` changes
    let date = new Date(props.createdAt);

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box
                        sx={{
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >
                        {props.skeleton ?
                            <ShTextSkeleton/> 
                            :
                            <>
                                <Text bold>{props.name}</Text>
                                <Text light>{props.order?.webshopreference}</Text>
                            </>
                        }
                    </Box>
                    <Box 
                        sx={{
                            mt:4,
                            display:"flex",
                            alignItems:"flex-end",
                            gap:4,
                        }}
                    >
                        <Box
                            sx={{
                                flexShrink:0,
                                textAlign:"right",
                                display:"flex",
                                flexDirection:"column"
                            }}
                        >
                            {props.skeleton ?
                                <ShTextSkeleton/> 
                                :
                                <>
                                    <Text>
                                        {date.toLocaleDateString(i18n.language)}
                                    </Text>
                                    <Text light>
                                        {date.toLocaleTimeString(i18n.language,{timeStyle: 'short'})}
                                    </Text>
                                </>
                            }
                        </Box>
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                maxWidth:158,
                                whiteSpace:"nowrap",
                                wordBreak:"keep-all",
                                textOverflow:"ellipsis",
                                overflow:"hidden"
                            }}
                        >
                            <Text light>{t("warehouse")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton/> 
                                :
                                <Text>
                                    {props.warehouse?.name ?? t("unknown")}
                                </Text>
                            }
                        </Box>
                    </Box>

                    <TopRight>
                        <CardLabel hasright variant={labelColor} position="child-tr">
                            {labelText}
                        </CardLabel>
                        <CardLabel 
                            variant={theme.palette.mode === "dark" ? "greyLight" : "grey"}
                            hasleft 
                            colorflip={theme.palette.mode !== "dark"}
                            position="child-tr"
                        >
                            {props.skeleton ? "N/A" : props.itemCount}
                        </CardLabel>
                    </TopRight>
                </Box>
            </Box>
        </BaseCard>
    )
}
