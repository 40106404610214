import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useTranslation } from 'react-i18next';
import InvoiceInvoiceProducts from "./Views/InvoiceInvoiceProducts";
import Logs from "./Views/Logs";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import InvoiceLines from "./Views/Invoicelines";
import { spInvoiceDetailsConfig } from "./spInvoiceDetailsConfig";
import { spInvoiceDetailsRelationConfig } from "./spInvoiceDetailsRelationConfig";
import { useGetInvoiceQuery, useFinalizeInvoiceMutation } from "../../../../newapi/financial/invoiceSlice";
import ListIcon from '@mui/icons-material/List';
import ShLabel from "../../../theme/label/ShLabel";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import CheckIcon from '@mui/icons-material/Check';
import { showToastMessageUpdateRequest} from "../../../../utils/toasts";
import { useSelector } from "react-redux";
import { ENV_TYPES } from "../../Sidebar";
import { formatPrice } from "../../../../utils/helpers";
import Subheading from "../../../theme/text/Subheading";
import Text from "../../../theme/text/Text";
import ButtonGroup from "../../../theme/buttons/ButtonGroup";

const SidePanelActions = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>
            {
            !props.isRelation && props.invoiceData?.isConcept && 
                <Shbutton
                    onClick={() => props.finalizeAction(props.invoiceData?.id)}
                    color={theme.palette.mode === "dark" ? "blue" : "primary"}
                    variant="contained"
                    endIcon={<CheckIcon />}
                >
                    {t('finalize')}
                </Shbutton>
            }
        </SidepanelHeaderActiongroup>
    )
}

const SpInvoiceDetails = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const isRelation = selectedEnvironmentType === ENV_TYPES[200];

    const { data: invoiceData, isLoading,isFetching,isError } = useGetInvoiceQuery(props.invoiceId,
    {
        skip: !props.invoiceId
    });

    const [finalizeInvoice,{isFinalizing}] = useFinalizeInvoiceMutation();
    const handleFinalizeClick = async (id) => {
        try {
            const response = await finalizeInvoice(id).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    }

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ListIcon/>
    }), [theme.palette.mode]);


    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t("relation")} /> },
        { component: <ShRowSkeleton heading={t("invoice_date")} /> },
        { component: <ShRowSkeleton heading={t("total_price")} /> },
        { component: <ShRowSkeleton heading={t("start_date")} /> },
        { component: <ShRowSkeleton heading={t("end_date")} /> },
        { component: <ShRowSkeleton heading={t("invoicelines")} /> },
    ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        options: defaultOptions,
        icon: defaultIcon,
        styling: {
            header : {
                padding:0,
                background: `linear-gradient(${colors.orange[100]}, ${colors.orange[100]}), white`, /* Solid white background */
            }
        },
        tabColor: colors.orange[400],
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} finalizeAction={handleFinalizeClick} invoiceData={invoiceData} />  
    }), [defaultIcon,defaultOptions, setIsExtendedCallback]);


    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.invoiceId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && invoiceData) {
            let date = invoiceData.date && invoiceData.date !== null ? new Date(invoiceData.date) : undefined;
            let startDate = invoiceData.startDate && invoiceData.startDate !== null ? new Date(invoiceData.startDate) : undefined;
            let endDate = invoiceData.endDate && invoiceData.endDate !== null ? new Date(invoiceData.endDate) : undefined;

            setPanel(prevPanel => ({
                ...prevPanel,
                title: `${t('invoice')} ${invoiceData.number}`,
                icon: {
                    theme: invoiceData.isConcept ? "orange" : "green",
                    content: <ListIcon/>
                },
                options: [
                    {
                        heading: t("relation"),
                        content: invoiceData.relation?.name ?? t("unknown")
                    },
                    {
                        heading: t("total_price"),
                        content: formatPrice(i18n, invoiceData.totalPrice)
                    },
                    {
                        heading: t("invoice_date"),
                        content: date ? date.toLocaleDateString(i18n.language) : "-"
                    },
                    {
                        heading: t("start_date"),
                        content: startDate ? startDate.toLocaleDateString(i18n.language) : "-"
                    },
                    {
                        heading: t("end_date"),
                        content: endDate ? endDate.toLocaleDateString(i18n.language) : "-"
                    },
                    {
                        heading: t("invoicelines"),
                        content: invoiceData.totalLines
                    }
                ],
                styling: {
                    header : {
                        padding:"0px !important",
                        background: invoiceData.isConcept ? `linear-gradient(${colors.orange[100]}, ${colors.orange[100]}), white` : `linear-gradient(${colors.green[100]}, ${colors.green[100]}), white`, /* Solid white background */
                    }
                },
                tabColor:invoiceData.isConcept ? colors.orange[400] : colors.green[400],
                actions:<SidePanelActions setIsExtended={setIsExtendedCallback} finalizeAction={handleFinalizeClick} invoiceData={invoiceData} isRelation={isRelation} />  
            }));
        }
    }, [invoiceData, isLoading, isFetching,theme.palette.mode]);

    const date = useMemo(() => {
        return invoiceData?.date && invoiceData.date !== null
          ? new Date(invoiceData.date).toLocaleDateString(i18n.language)
          : "-";
      }, [invoiceData?.date, i18n.language]);
    
      const startDate = useMemo(() => {
        return invoiceData?.startDate && invoiceData.startDate !== null
          ? new Date(invoiceData.startDate).toLocaleDateString(i18n.language)
          : "-";
      }, [invoiceData?.startDate, i18n.language]);
    
      const endDate = useMemo(() => {
        return invoiceData?.endDate && invoiceData.endDate !== null
          ? new Date(invoiceData.endDate).toLocaleDateString(i18n.language)
          : "-";
      }, [invoiceData?.endDate, i18n.language]);
    

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={isRelation ? spInvoiceDetailsRelationConfig : spInvoiceDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            isCustom
            setIsExtended={props.setIsExtended}>
                {!isRelation ? 
                <>
                    <InvoiceLines config={spInvoiceDetailsConfig.invoiceLines} relationId={invoiceData?.relation?.id} invoiceId={props.invoiceId} invoiceData={invoiceData} setIsExtended={props.setIsExtended} />
                    <InvoiceInvoiceProducts config={spInvoiceDetailsConfig.invoiceProducts} relationId={invoiceData?.relation?.id} invoiceId={props.invoiceId} setIsExtended={props.setIsExtended} />
                    <Logs config={spInvoiceDetailsConfig.logs} invoiceId={props.invoiceId} setIsExtended={props.setIsExtended} />
                </>
                :
                <>
                    <InvoiceLines config={spInvoiceDetailsRelationConfig.invoiceLines} relationId={invoiceData?.relation?.id} invoiceId={props.invoiceId} invoiceData={invoiceData} setIsExtended={props.setIsExtended} />
                </>
                }

                {/* Hader */}
                <Box
                    sx={{
                        paddingBottom:3,
                        // position:"relative",
                    }}
                >
                    <Box
                        sx={{
                            pr:3,
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"flex-end",
                        }}
                    >
                        <Box
                            sx={{
                                background:invoiceData?.isConcept ? colors.orange[100] : colors.green[100],
                                pt:3,
                                px:3,
                                pb:2,
                                fontSize:21,
                                fontWeight:"bold",
                                fontFamily:"Roboto, sans-serif",
                                color: invoiceData?.isConcept ?  colors.orange[400] : colors.green[400],
                                borderBottomRightRadius:24,
                                "@media screen and (max-width: 47.9375em)" : {
                                    position:"absolute",
                                    top:0,
                                },
                                "@media screen and (max-width: 566px)" : {
                                    display:"none"
                                }
                            }}
                        >
                           {invoiceData?.isConcept ? t('concept') : t('final') }
                        </Box>
                        <Box
                            sx={{
                                pb:1,
                            }}
                        >
                            <SidePanelActions setIsExtended={setIsExtendedCallback} finalizeAction={handleFinalizeClick} invoiceData={invoiceData} isRelation={isRelation} />  
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            px:3,
                            pt:4,
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"center",
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"column"
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    display:"flex",
                                    alignItems:"center",
                                    flexDirection:"column",
                                    mb:4,
                                }
                            }}
                        >
                            <Box
                                sx={{

                                    display:"flex",
                                    alignItems:"center",
                                    gap:2,
                                }}
                            >
                                <Box
                                    sx={{
                                        display:"flex",
                                        gap:1,
                                    }}
                                >
                                    <Subheading>{t('invoice')}</Subheading> <Subheading variant={invoiceData?.isConcept ? "orange" : "green"}>#{invoiceData?.number}</Subheading>
                                </Box>
                                <Box
                                    sx={{
                                        width:40,
                                        background:invoiceData?.isConcept ? colors.orange[200] : colors.green[200],
                                        height:4,
                                    }}
                                />
                                <Box
                                    sx={{
                                        display:"flex",
                                        gap:1,
                                    }}
                                >
                                    <Subheading>{formatPrice(i18n, invoiceData?.totalPrice)}</Subheading>
                                </Box>

                            </Box>
                            <Box>
                                <Text light>{invoiceData?.relation?.name ?? t("unknown")}</Text>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    display:"flex",
                                    gap:2,
                                    // flexDirection:"column"
                                },
                                "@media screen and (max-width: 566px)" : {
                                    width:1,
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    // height:40,
                                    lineHeight:"40px",
                                    background:invoiceData?.isConcept ? colors.orange[100] : colors.green[100],
                                    px:3,
                                    fontSize:14,
                                    fontWeight:"bold",
                                    fontFamily:"Roboto, sans-serif",
                                    color: invoiceData?.isConcept ?  colors.orange[400] : colors.green[400],
                                    borderRadius:20,
                                    display:"none",
                                    alignItems:"center",
                                    flex:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        display:"flex",
                                    },
                                    "@media screen and (max-width: 566px)" : {
                                        justifyContent:"center"
                                    }
                                }}
                            >
                                {invoiceData?.isConcept ? t('concept') : t('final') }
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    gap:4,
                                    borderRadius:7,
                                    height:56,
                                    px:4,
                                    background:invoiceData?.isConcept ? colors.orange[100] : colors.green[100],
                                    "@media screen and (max-width: 47.9375em)" : {
                                        // width:400,
                                    },
                                    "@media screen and (max-width: 566px)" : {
                                        flexDirection:"column",
                                        gap:2,
                                        height:"unset",
                                        px:3,
                                        py:3,
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        "@media screen and (max-width: 566px)" : {
                                            flexDirection:"row",
                                            width:1,
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Text light>{t("invoice_date")}</Text>
                                    <Text>{date}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        "@media screen and (max-width: 566px)" : {
                                            flexDirection:"row",
                                            width:1,
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Text light>{t("start_date")}</Text>
                                    <Text>{startDate}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        "@media screen and (max-width: 566px)" : {
                                            flexDirection:"row",
                                            width:1,
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Text light>{t("end_date")}</Text>
                                    <Text>{endDate}</Text>
                                </Box>
                                
                            </Box>
                            
                        </Box>

                    </Box>
                </Box>
        </Sidepanel2>
    );
};

export default SpInvoiceDetails;