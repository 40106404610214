import { Box,IconButton, TextField, InputBase, TextareaAutosize, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import AddIcon from '@mui/icons-material/Add';
import WidgetsIcon from '@mui/icons-material/Widgets';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import cx from "classnames"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import NumberInput from "../../../../theme/inputs/NumberInput";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useUpdateProductMutation } from "../../../../../newapi/warehouse/productSlice";
import SpDesktopFooter from "../../../../theme/sidepanel2/SidepanelFooter";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    
    const [updateProduct,{isLoading, isSuccess}] = useUpdateProductMutation();

    const handleButtonClick = () => {
        const product = { 
            inboundInstruction: props.inboundInstruction, 
            outboundInstruction: props.outboundInstruction, 
            returnInstruction: props.returnInstruction
        };

        updateProduct({ id: props.id, data: product })
            .unwrap()
            .then((updatedLocation) => {
                // Handle the success state
                toast.success(t('update_success'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch((error) => {
                // Handle the error state
                toast.error(t('update_failed'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    };


    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={handleButtonClick}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Instructions = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [inboundInstruction, setInboundInstruction] = useState("");
    const [outboundInstruction, setOutboundInstruction] = useState("");
    const [returnInstruction, setReturnInstruction] = useState("");
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const  { t } = useTranslation();

    

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions
            id={props.data.id}
            inboundInstruction={inboundInstruction}
            outboundInstruction={outboundInstruction}
            returnInstruction={returnInstruction}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props.data,inboundInstruction,outboundInstruction,returnInstruction]);
    

    useEffect(() => {
        if(props.data) {
            setDataHasBeenSet(true);
            setInboundInstruction(props.data.inboundInstruction);
            setOutboundInstruction(props.data.outboundInstruction);
            setReturnInstruction(props.data.returnInstruction);
        }
    },[props.data]);

    const handleInputChange = (event, type) => {
        switch(type) {
            case "inbound" : setInboundInstruction(event.target.value);
            break;
            case "outbound" : setOutboundInstruction(event.target.value);
            break;
            case "return" : setReturnInstruction(event.target.value);
            break;
        }
      };

    return (
        <Box height={1} padding={3} display={"flex"} flexDirection={"column"} overflow={"auto"}>
            <Text light>{t('inbound_instruction')}</Text>

            <Box display="flex"
                borderRadius="8px"
                backgroundColor={colors.grey[100]}
                sx={{
                    marginTop:1,
                    marginBottom: 2
                }}
            >
                <TextField

                    id="outlined-multiline-static"
                    multiline
                    value={inboundInstruction}
                    onChange={(event) => handleInputChange(event, "inbound")}
                    rows={3}
                    variant="outlined"
                    sx={{
                        width:1,
                        border:"none",
                        outlineColor:"transparent !important",
                        color: colors.txt["primary"],
                        padding:0,
                        " .MuiOutlinedInput-notchedOutline": {
                            border:"none !important"
                        },
                        " .MuiInputBase-root": {
                            paddingX:2,
                            paddingY:1.5
                        }
                    }}

                ></TextField>
            </Box>
            <Text light>{t('outbound_instruction')}</Text>

            <Box display="flex"
                borderRadius="8px"
                backgroundColor={colors.grey[100]}
                sx={{
                    marginTop:1,
                    marginBottom: 2
                }}
            >
                <TextField

                    id="outlined-multiline-static"
                    multiline
                    value={outboundInstruction}
                    onChange={(event) => handleInputChange(event, "outbound")}
                    rows={3}
                    variant="outlined"
                    sx={{
                        width:1,
                        border:"none",
                        outlineColor:"transparent !important",
                        color: colors.txt["primary"],
                        padding:0,
                        " .MuiOutlinedInput-notchedOutline": {
                            border:"none !important"
                        },
                        " .MuiInputBase-root": {
                            paddingX:2,
                            paddingY:1.5
                        }
                    }}

                ></TextField>
            </Box>
            <Text light>{t('return_instruction')}</Text>

            <Box display="flex"
                borderRadius="8px"
                backgroundColor={colors.grey[100]}
                sx={{
                    marginTop:1
                }}
            >
                <TextField

                    id="outlined-multiline-static"
                    multiline
                    value={returnInstruction}
                    onChange={(event) => handleInputChange(event, "return")}
                    rows={3}
                    variant="outlined"
                    sx={{
                        width:1,
                        border:"none",
                        outlineColor:"transparent !important",
                        color: colors.txt["primary"],
                        padding:0,
                        " .MuiOutlinedInput-notchedOutline": {
                            border:"none !important"
                        },
                        " .MuiInputBase-root": {
                            paddingX:2,
                            paddingY:1.5
                        }
                    }}

                ></TextField>
            </Box>
            
            <SpDesktopFooter>
                <GridActions
                    id={props.data?.id}
                    inboundInstruction={inboundInstruction}
                    outboundInstruction={outboundInstruction}
                    returnInstruction={returnInstruction}
                />
            </SpDesktopFooter>
        </Box>
    );
}

export default Instructions;