import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { createFilterInstance, removeFilterInstance } from '../newapi/global/filter/gridFilterSlice';
import { useTab } from './TabContext';
// import { createFilterInstance, removeFilterInstance } from '../newapi/global/filter/gridFilterSlice';
const GridFilterContext = createContext("");

export const useGridFilter = () => useContext(GridFilterContext);

export const GridFilterProvider = (props) => {
    const dispatch = useDispatch();
    const { index } = useTab();
    const instances = useSelector(state => state.gridFilter.viewInstances[index]);
    
    useEffect(() => {
        const id = props.activeChild;

        if (index !== null && id !== null && !(id in instances)) {
            dispatch(createFilterInstance({ index, id }));
        } 
    }, [props.activeChild, index, instances, dispatch]);
  
    return (
        <GridFilterContext.Provider value={{ index, id: props.activeChild }}>
            {props.children}
        </GridFilterContext.Provider>
    );
};
