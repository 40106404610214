
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme"

import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../../components/theme/label/ShLabel";
import Text from "../../../../../components/theme/text/Text";
import { useEffect, useRef, useState } from "react";
import { useGetInvoicesQuery } from "../../../../../newapi/financial/invoiceSlice";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import { general_states } from "../../../../../utils/staticEnums";
import { WarehouseInvoiceCard } from "../../../../../components/theme/cards/financial/InvoiceCard";
import { formatPrice } from "../../../../../utils/helpers";

import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import SpInvoiceDetails from "../../../../../components/global/Sidepanels/SpInvoiceDetails/SpInvoiceDetails";

const GridActions = (props) => {
    const theme = useTheme();
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <ButtonGroup version={2}>
            </ButtonGroup>
        </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const All = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    const [isRelationExtended, setIsRelationExtended] = useState(false);
    // const [selectedRowId, setSelectedRowId] = useState(-1);
    // const [rowSelected, setRowSelected] = useState(false);
    
    const ref = useRef();
    const [config, setConfig] = useState({"state": general_states.ACTIVE});

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
        }
    },[]);

    const handleOpen = () => {
        setAddModalOpen(true);
    }
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const [relationId, setRelationId] = useState();
    const relationIdRef = useRef(relationId);
    
    const getRelationDetails = (params) => {
        if (!params && params.row) return;
    
        const newRelationId = parseInt(params.row.relation.id);
        // Only update state if the productId has actually changed
        if (relationIdRef.current !== newRelationId) {
            setRelationId(newRelationId);
            relationIdRef.current = newRelationId; // Update the ref to the new value
        }
        setIsRelationExtended(true);
    };

    const [invoiceId, setInvoiceId] = useState();
    const invoiceIdRef = useRef(invoiceId);
    
    const getInvoice = (params) => {
        if (!params) return;
    
        const newInvoiceId = parseInt(params.row.id);
        // Only update state if the pickingJobListId has actually changed
        if (invoiceIdRef.current !== newInvoiceId) {
            setInvoiceId(newInvoiceId);
            invoiceIdRef.current = newInvoiceId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {
            field: "number",
            headerName: t("number"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return params.row.number;
            }
        },
        
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                let isConcept = params.row.isConcept;
                let labelcolor = isConcept ? "orange" : "green";
                let labeltext = isConcept ? t('concept') : t('finalized');
                
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {labeltext}
                    </ShLabel>
                  );
              }
        },
        {
            field: "date", 
            headerName: t("invoice_date"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let date = params.row.date && params.row.date !== null ? new Date(params.row.date) : undefined;
              
                return (
                    <Text>
                        {date ? date.toLocaleDateString(i18n.language) : "-"}
                    </Text>
                );
            }
        },
        {
            field: "totalPrice", 
            headerName: t("total_price"),
            flex:1,
            shFilter: {
                type: "number"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return formatPrice(i18n, params.row.totalPrice);
            }
        },
        {
            field: "startDate", 
            headerName: t("start_date"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let date = params.row.startDate && params.row.startDate !== null ? new Date(params.row.startDate) : undefined;
              
                return (
                    <Text>
                        {date ? date.toLocaleDateString(i18n.language) : "-"}
                    </Text>
                );
            }
        },
        {
            field: "endDate", 
            headerName: t("end_date"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let date = params.row.endDate && params.row.endDate !== null ? new Date(params.row.endDate) : undefined;
              
                return (
                    <Text>
                        {date ? date.toLocaleDateString(i18n.language) : "-"}
                    </Text>
                );
            }
        },
        {
            field: "totalLines",
            headerName: t("invoicelines"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return params.row.totalLines;
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                // WarehouseInvoiceCard
                return (
                    <WarehouseInvoiceCard
                        onClick={() => getInvoice(params)}
                        skeleton={params.row.skeleton}
                        {...params.row}
                    />
                    // <ShTextSkeleton/>
                )
            }
        },
    ];

    return (
        <Box height={1} ref={ref} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetInvoicesQuery}
                config={config}
                // gridActions={<GridActions></GridActions>} 
                title={t('all')}
                gridOptions={gridOptions} 
                sortModel={{field: columns[0].field,type:"DESC"}}
                columns={columns}
                rows={data}
                onRowClick={getInvoice}
                >
            </ShDataGrid2>
            <SpInvoiceDetails
                invoiceId={invoiceId}
                isExtended={isExtended}
                setIsExtended={setIsExtended}
            ></SpInvoiceDetails>
        </Box>
    );
}

export default All;