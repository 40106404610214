import WidgetsIcon from '@mui/icons-material/Widgets';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const spPriceIncreaseDetailsConfig = {
    products: {
        title: 'products',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
    logs: {
        title: 'logs',
        icon: <FormatListBulletedIcon/>,
        isSingleDataGrid: true,
    },
};
