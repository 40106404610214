import { Box, ButtonBase, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { useState } from "react";

import { ArrowRightIcon } from "@mui/x-date-pickers";
import PopoverModal from "../Modal/PopoverModal";
import { useTranslation } from "react-i18next";
const ShDataGridSelectedActions = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();    

    const [anchorEl, setAnchorEl] = useState(null);
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    return (
        props.selectedItems?.length > 0 ?
            <>
                <Box
                    sx={{
                        p:1,
                        
                        background:theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400],
                        borderRadius:7,
                        borderTopLeftRadius:0,
                        borderBottomLeftRadius:0,
                        fontWeight:500,
                        color:`${theme.palette.mode === "dark" ? "#31415f" : "#fff"}`,
                        display:"flex",
                        alignItems:"center",
                        minWidth:400,
                        "@media screen and (max-width: 47.9375em)" : {
                            minWidth:"unset",
                            position:"fixed",
                            borderBottomRightRadius:0,
                            borderTopLeftRadius:"28px",
                            bottom:0,
                            top:"unset",
                            left:0,
                            zIndex:1060,
                            width:1,
                            px:2,
                            height:56,
                        }
                        // boxShadow: `0px 0px 19px ${colors.grey[300]}`
                    }}
                >
                    <Box
                        sx={{
                            px:1.5,
                            py:1,
                            background:theme.palette.mode === "dark" ? "rgba(0,0,0,.25)" : "rgba(255,255,255,.15)",
                            color:`#fff`,
                            borderRadius:5,
                            fontWeight:"bold",
                            mr:1.5,
                            flexShrink:0,
                            minWidth:40,
                            textAlign:"center",
                        }}
                    >
                        {props.selectedItems.length}
                        {/* 12321324 */}
                    </Box>
                    
                    {t("selected")}

                {!props.showButtons ?
                    <ButtonBase
                        aria-describedby={popoverId}
                        onClick={handlePopoverClick}
                        sx={{
                            height:40,
                            flexShrink:0,
                            // background:"orange",
                            marginLeft:"auto",
                            borderRadius:6,
                            paddingLeft:3,
                            paddingRight:0.5,
                            background: colors.pastel.light.blue[100],
                            color:"#31415f",
                            fontWeight:"bold",
                            fontSize:"17",
                            fontFamily:"Roboto",
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",

                            "& .arrow" : {
                                transition:"250ms",
                            },
                            "&:hover" : {
                                "& .arrow" : {
                                    transition:"250ms",
                                    transform:"rotate(-90deg)"
                                },
                            },
                            "@media screen and (max-width: 47.9375em)" : {
                                pl:0,
                                ml:"auto",
                                p:0.5,
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    display:"none"
                                }
                            }}
                        >
                        {t("actions")}
                        </Box>
                        <Box
                            className={"arrow"}
                            sx={{
                                marginLeft:2,
                                background:theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400],
                                color:`${theme.palette.mode === "dark" ? "#31415f" : "#fff"}`,
                                height:32,
                                width:32,
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                borderRadius:5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    ml:0,
                                }
                            }}
                        >
                            <ArrowRightIcon/>
                        </Box>
                    </ButtonBase>
                    : 
                    <Box
                            sx={{
                                marginLeft:"auto",
                            }}
                    >

                        <ButtonBase
                            aria-describedby={popoverId}
                            onClick={handlePopoverClick}
                            sx={{
                                height:40,
                                flexShrink:0,
                                // background:"orange",
                                borderRadius:6,
                                paddingLeft:3,
                                paddingRight:0.5,
                                background: colors.pastel.light.blue[100],
                                color:"#31415f",
                                fontWeight:"bold",
                                fontSize:"17",
                                fontFamily:"Roboto",
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"space-between",

                                "& .arrow" : {
                                    transition:"250ms",
                                },
                                "&:hover" : {
                                    "& .arrow" : {
                                        transition:"250ms",
                                        transform:"rotate(-90deg)"
                                    },
                                },
                                "@media screen and (min-width:47.9375em)" :{
                                    display:"none",
                                }
                            }}
                        >
                            Acties
                            <Box
                                className={"arrow"}
                                sx={{
                                    marginLeft:2,
                                    background:theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400],
                                    color:`${theme.palette.mode === "dark" ? "#31415f" : "#fff"}`,
                                    height:32,
                                    width:32,
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    borderRadius:5,
                                }}
                            >
                                <ArrowRightIcon/>
                            </Box>
                        </ButtonBase>
                        <Box
                            sx={{ 
                                
                                
                                "@media screen and (max-width:47.9375em)" :{
                                    display:"none",
                                }
                            }}
                        >
                            {props.children}
                        </Box>
                    </Box>
                }
                </Box>
                
                <PopoverModal
                    id={popoverId}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    transformOrigin={{
                        vertical:"bottom"
                    }}
                    anchorOrigin={{
                        vertical:"top"
                    }}
                    // {...props}
                    triangle={{position:"center",color:theme.palette.mode === "dark" ? colors.grey[300] : "#fff"}}
                >
                    <Box 
                        sx={{
                            background: theme.palette.mode === "dark" ? colors.grey[300] : "#fff",
                            padding:1.5,
                            paddingBottom: isStandAlone ? 4 : 1.5,
                            display:"flex",
                            gap:1,
                            flexDirection:"column",
                            borderRadius:"16px",
                            borderBottomLeftRadius:0,
                            borderBottomRightRadius:0,
                            minWidth:300,
                            "@media screen and (max-width: 48em)" : {
                                borderRadius:"32px",
                                borderTopLeftRadius:"12px",
                                borderTopRightRadius:"12px",
                                borderBottomLeftRadius:0,
                                borderBottomRightRadius:0,

                            }
                        }}
                    >
                        {props.children}
                    </Box>
                </PopoverModal>
            </>
        :null
    )
}

export default ShDataGridSelectedActions;