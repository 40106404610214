
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
export const filterModalConfig = {
    settings:  {
        title: <SettingsOutlinedIcon/>,
        position:"right",
        icon: <SettingsOutlinedIcon/>,
        tabClasses: "icon s40",
    },
};
