import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import TopRight from "../../positionals/TopRight";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";

const OrderLinesCard = (props) => {
    const theme = useTheme();

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box display={"flex"} flexDirection={"column"}>
                        {props.skeleton ?
                            <ShDoubleTextSkeleton/> 
                            :
                            <>
                                <Text bold>{props.product?.sku}</Text>
                                <Text light>{props.product?.description}</Text>
                            </>
                        }
                    </Box>

                    <TopRight>
                        <CardLabel hasright variant={theme.palette.mode === "dark" ? "blue" : "primary"} position="child-tr">
                            {props.skeleton ? null : props.product?.barcode}
                        </CardLabel>
                        <CardLabel hasleft variant={theme.palette.mode === "dark" ? "greyLight" : "grey"} icon position="child-tr">
                            {props.skeleton ? null : props.amount}
                        </CardLabel>
                    </TopRight>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default OrderLinesCard;