import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Progressbar from '../Progressbar';
import { setQuery, setCurrentTab, setProgressionInfo } from '../../../newapi/global/progressionTabSlice';
import Text from '../text/Text';
import { Box } from '@mui/material';
import Subheading from '../text/Subheading';
import { showToastMessagePostRequest, showToastMessageRequestError } from '../../../utils/toasts';
import { useTranslation } from "react-i18next";

/**
 * ProgressionWrapper Component
 * 
 * Props:
 * - title: String, initial title to display.
 * - subtitle: String, initial subtitle to display.
 * - isModal: boolean, needed for the progressionbar offset
 * 
 * Children:
 * - ProgressionTab components that represent each step in the progression.
 */
const ProgressionWrapper = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const { currentTab, title, subtitle, maxSteps, currentStep,data, shouldSaveData } = useSelector((state) => state.progressiontabs);
    

    useEffect(() => {
        if(currentStep === 1) {
            if (typeof props.title !== 'undefined') {
                dispatch(setProgressionInfo({ title: props.title, subtitle: props.subtitle ?? ''}));
            }
        }
    },[currentStep])

    useEffect(() => {
        // dispatch(setQuery(props.query));
    }, [])


     // Initialize save function and isLoading state only if props.query is provided
     const [save, { isLoading }] = props.query ? props.query() : [null, { isLoading: false }];

     useEffect(() => {
         if (shouldSaveData && save) {
             handleSave();
         } else if (shouldSaveData) {
            if (props.onSave) {
                props.onSave(data);
            }
         }
     }, [shouldSaveData, save, data, dispatch, props]);
 
     const handleSave = async () => {
         if (save) {
            try {
                const result = await save(data);
                dispatch(setProgressionInfo({ shouldSaveData: false }));
                const res = result.data ? result.data : result.error.data; // error object
                if (res.success) { // Error handling
                    if (props.onSave) {
                        props.onSave();
                    }
                } 
                showToastMessagePostRequest(t, res);
            } catch(e) {
                showToastMessageRequestError(t('save_failed'), t, e);
            }
         }
     };

    return (
        <Box
            sx={props.sx}
        >
            <Box
                sx={{
                    paddingY:3,
                    paddingX:3,
                }}
            >
                <Box
                    sx={{
                        "@media screen and (max-width: 47.9375em)" : {
                            marginRight: props.isModal ? 4 : null
                        }
                    }}
                >
                    <Progressbar
                        progress={
                            maxSteps > 1 ? 
                                currentStep > 1 ? 
                                    currentStep / maxSteps 
                                    : 0 
                                : 0
                        }
                    />
                </Box>
                <Box
                    sx={{
                        paddingTop:3
                    }}
                >
                    <Text light>{t('step')}  {maxSteps > 1 ? `${currentStep} / ${maxSteps}` : `${currentStep}`}</Text>
                    <Box sx={{
                        display:"flex",
                        gap:1,
                        alignItems:"center"
                    }}>
                        <Subheading>{title}</Subheading> {subtitle && <><Text light>/</Text><Text light>{subtitle}</Text></>}
                    </Box>
                </Box>
            </Box>
            {/* <p>Step: {currentStep} / {maxSteps}</p> */}
            {props.children}
            {/* {childrenWithProps} */}
        </Box>
    );
};

export default ProgressionWrapper;
