import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import TopRight from "../../positionals/TopRight";
import BottomRight from "../../positionals/BottomRight";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const WriteOffsCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box display={"flex"} flexDirection={"column"}>
                        {props.skeleton ? 
                            <ShDoubleTextSkeleton/> 
                            :
                            <>
                                <Text bold>{props.row.stock?.product?.sku}</Text>
                                <Text>{props.row.stock?.product?.description}</Text>
                            </>
                        }
                    </Box>
                    <TopRight>
                        <CardLabel variant={theme.palette.mode === "dark" ? "greyLight" : "grey"} position="child-tr">
                            {props.skeleton ? null : props.row.stock?.quantity}
                        </CardLabel>
                    </TopRight>


                    {/* Holds tht date and batch */}
                    <Box display={"flex"} gap={4} marginTop={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("location")}</Text>
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>
                                    {props.row.stock?.warehouseLocation?.location}
                                </Text>
                            }
                        </Box>

                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("barcode")}</Text>
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text>{props.row.stock?.product?.barcode}</Text>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default WriteOffsCard;