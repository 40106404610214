import { Box } from "@mui/material";
import { DataGrid, GridCheckIcon, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import ShDataGrid from "../../../../components/theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../theme"
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../components/theme/icons/Icon";
import Text from "../../../../components/theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ShDateRangePicker from "../../../../components/theme/ShDateRangePicker";
import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../components/theme/label/ShLabel";
import { useEffect, useRef, useState } from "react";
import SpOrderDetails from "../../../../components/global/Sidepanels/SpOrderDetails/SpOrderDetails";
import SpReturnDetails from "../../../../components/global/Sidepanels/SpReturnDetails/SpReturnDetails"; // Changed from SpInboundDetails to SpReturnDetails
import { useGetReturnReasonsQuery, useGetReturnReasonQuery } from "../../../../newapi/inbound/returnsSlice"; // Changed from inboundsSlice to returnsSlice
// import ReturnsCard from "../../../../components/theme/cards/returns/ReturnsCard"; // Changed from InboundsCard to ReturnsCard
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../components/theme/skeleton/ShTextSkeleton";
import ShLabelSkeleton from "../../../../components/theme/skeleton/ShLabelSkeleton";

import { useNavigate } from 'react-router-dom';
import { checkUserPermission, UserPrivileges, UNAUTHORIZED_ENDPOINT } from '../../../../utils/permissions';
import { RelationReturnCard } from "../../../../components/theme/cards/inbounds/ReturnCards";
import ShDataGrid2 from "../../../../components/theme/datagrid2/ShDataGrid2";
import { PhonelinkOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useGetRelationQuery } from "../../../../newapi/global/relation/relationSlice";
import { ClearIcon } from "@mui/x-date-pickers";
import SpReturnReasonDetails from "../../../../components/global/Sidepanels/SpReturnReasonDetails/SpReturnReasonDetails";
import MAddReturnReason from "../../../../components/global/Modals/MAddReturnReason";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('new')}
            </Shbutton>
        </ButtonGroup>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const ReturnReasons = (props) => { // Changed from InboundInbounds to ReturnReturns
    // Check if user has permission to access the page
   const navigate = useNavigate();
   useEffect(() => {
       if (!checkUserPermission([UserPrivileges.RETURN_READ])) { // Changed from INBOUND_READ to RETURN_READ
         navigate(UNAUTHORIZED_ENDPOINT); // Use navigate instead of history.push
       }
     }, [navigate]);
     
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    const id = useSelector((state) => state.settings.environment.selectedId);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            
        }
    },[]);

    const handleOpen = (open) => {
        addModal.setOpen(true);
    }

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    const [relationId, setRelationId] = useState();
    const { data: relation } = useGetRelationQuery(relationId, {
        skip: !relationId
    });
    
    const handlePortalOpen = () => {
        if(!relation){
            setRelationId(id);
        } else {
            window.open(`https://returns.stockhub.nl/${relation.portalCode}`, '_blank');
        }
    }

    useEffect(() => {
        if(relation) window.open(`https://returns.stockhub.nl/${relation.portalCode}`, '_blank');
    }, [relation]);

    const [selectedRowId, setSelectedRowId] = useState();
    const selectedRowIdRef = useRef(selectedRowId);
    useEffect(() => {
        if(selectedRowId > 0) {
            setIsExtended(true);
        }
    }, [selectedRowId]);

    // const { data: returnReasonData, isLoading: isLoading } = useGetReturnReasonQuery(selectedRowId,
    //     {
    //         skip: !selectedRowId
    //     });
        
    const getReturnReasonDetails = (params) => {
        if (!params) return;
        if (parseInt(selectedRowId) === parseInt(params.row.id)) {
            setIsExtended(true);
        } else {
            setSelectedRowId(params.row.id);
        }
    };

    // useEffect(() => {
    //     if (!isLoading && returnData) {
    //         setIsExtended(true);
    //     }
    // }, [returnData, isLoading]);
    const [returnId, setReturnId] = useState();
    const returnIdRef = useRef(returnId);
    
    const getReturnDetails = (params) => {
        if (!params) return;
    
        const newreturnId = parseInt(params.row.id);
        // Only update state if the returnId has actually changed
        if (returnIdRef.current !== newreturnId) {
            setReturnId(newreturnId);
            returnIdRef.current = newreturnId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {field: "description", headerName: t("description"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return(
                    <Text>
                        {params.row.description}
                    </Text>
                )
            }
        },
        {field: "requireImageUpload", headerName: t("requireimageupload"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Box>
                        <Text>
                            {params.value ? (
                                <GridCheckIcon sx={{ color: 'green' }} />
                            ) : (
                                <ClearIcon sx={{ color: 'rgba(128, 128, 128, 0.5)' }} />
                            )}
                        </Text>
                    </Box>
                )
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    // <RelationReturnCard skeleton={params.row.skeleton ?? false} {...params.row}/>
                    // <InboundsCard skeleton={params.row.skeleton ?? false} {...params.row} />
                // <Box padding={3} sx={{background:"orange"}}></Box>
                    <Box
                        sx={{
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            paddingX:2,
                            paddingY:1.5,
                            borderRadius:2
                        }}
                    >
                        {params.row.description}
                        <br/>
                        <Text>
                            {t("requireimageupload")}: 
                        </Text>
                        <Text>
                            {params.row.requireImageUpload ? (
                                <GridCheckIcon sx={{ color: 'green' }} />
                            ) : (
                                <ClearIcon sx={{ color: 'rgba(128, 128, 128, 0.5)' }} />
                            )}
                        </Text>
                    </Box>
                    
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetReturnReasonsQuery} // Changed from useGetInboundsQuery to useGetReturnsQuery
                gridActions={<GridActions handleOpen={handleOpen} handlePortalOpen={handlePortalOpen}></GridActions>} 
                title={t('all_returns') } // Changed from all_inbounds to all_returns
                gridOptions={gridOptions} 
                sortModel={{field: columns[2].field,type:"DESC"}}
                columns={columns}
                onRowClick={getReturnReasonDetails}> 
            </ShDataGrid2>
            <SpReturnReasonDetails // Changed from SpInboundDetails to SpReturnDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                returnReasonId={selectedRowId}
                // data={returnData} // Changed from inboundData to returnData
            ></SpReturnReasonDetails>
            <MAddReturnReason onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>

        </Box>
    );
}

export default ReturnReasons; // Changed from InboundInbounds to ReturnReturns
