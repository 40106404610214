import { Box, IconButton, InputBase, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateProductMutation } from "../../../../../newapi/warehouse/productSlice";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import NumberInput from "../../../../theme/inputs/NumberInput";
import SpDesktopFooter from "../../../../theme/sidepanel2/SidepanelFooter";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const AvailableStock = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const  { t } = useTranslation();

    const [productInfo, setProductInfo] = useState({
        active: false,
        sku: '',
        barcode: '',
        secondarySku: '',
        safetyStock: 0,
        food: false,
        fragile: false,
        dangerous: false,
        informShipper: false,
        highcare: false,
        enterDimensionsAfterInbound: false,
        DaWBlockedByWarehouse: false,
        quarantineAfterReturn: false,
        lotPossible: false,
        lotRequired: false,
        batchPossible: false,
        batchRequired: false,
        customInboundSettings: false,
        inboundProcessType: '',
        repackingRequired: false,
        directReplenish: false
    });
    
    useEffect(() => {
        setProductInfo(props.data);
    },[props.data]);

    const handleInputChange = (value, field) => {
        setProductInfo({ ...productInfo, [field]: value });
    };

    const [UpdateProduct,{isLoading}] = useUpdateProductMutation();
    const handleSaveClick = async () => {
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                const data = await UpdateProduct(productInfo).unwrap()
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("update_failed"), t, err);
            }
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
          }   
        }        
    };


    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions 
                            id={props.data?.id}
                            action={handleSaveClick} />
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[productInfo,props.data]);

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3} overflow={"auto"}>
            <Dropdown title={t("available_stock")} alwaysOpen>
                <Box
                    sx={{
                        display:"flex",
                        // alignItems:"center",
                        flexDirection:"column",
                        gap:2,
                        // gap:4,
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column",
                            gap:1
                        }
                    }}
                >
                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>
                                {t('all_stock')}
                            </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockQuantity ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                    {/* <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>-</Text>
                    </Box> */}
                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>
                                {t('in_order')}
                            </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockInOrder ?? 0}
                            </Text>

                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                    {/* <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>-</Text>
                    </Box> */}

                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                        >

                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                        <Text light>
                            {t('pickingprocess')}
                        </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockPickingprocess ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                    {/* <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>-</Text>
                    </Box> */}

                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >

                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>
                                {t('blocked')}
                            </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockBlocked ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                    {/* <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>-</Text>
                    </Box> */}

                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>
                                {t('quarantine')}
                            </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockQuarantine ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                    {/* <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>-</Text>
                    </Box> */}

                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>
                                {t('reserved')}
                            </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockReserved ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width:210,
                            mt:"-16px",
                            fontSize:25,
                            // mb:"-15px",
                            display:"flex",
                            justifyContent:"flex-end",
                            alignItems:"flex-end",
                            borderBottom:`2px solid ${colors.grey[200]}`,
                            // background:"orange",
                            "@media screen and (max-width: 47.9375em)" : {
                                width:1,
                                mt:1,
                                mb:1,
                            //     display:"none"
                            }
                        }}
                    >
                        <Box
                            sx={{
                                mb:1.5,
                                width:12,
                                height:3,
                                background:colors.grey[400],
                            }}
                        ></Box>
                    </Box>

                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>
                                {t('available')}
                            </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockAvailable ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                </Box>

            </Dropdown>
            <Dropdown title={t('extra_options')} alwaysOpen>
                <Box
                    sx={{

                        display:"flex",
                        alignItems:"center",
                        gap:1,
                        "@media screen and (max-width:47.9375em)" : {
                            width:1,
                            flexDirection:"row",
                            alignItems:"center",
                            justifyContent:"space-between",
                        }
                    }}
                >
                    {/* Safety Stock Input */}
                    <Box
                        sx={{
                            flexShrink:0,
                            width:150,
                            "@media screen and (max-width:63.9375em)" : {
                                width:130,
                            }
                        }}
                    >
                        <Text light>{t('safety_stock')}</Text>
                    </Box>
                    <NumberInput
                        value={productInfo.safetyStock}
                        change={(e) => handleInputChange(e, 'safetyStock')}
                    />
                </Box>
            </Dropdown>
            <SpDesktopFooter>
                <GridActions 
                id={props.data?.id}
                action={handleSaveClick} />
            </SpDesktopFooter>
        </Box>
    );
}

export default AvailableStock;