import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import Products from "./Views/Products";
import Logs from "./Views/Logs";
import { useTranslation } from 'react-i18next';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spPriceIncreaseDetailsConfig } from "./spPriceIncreaseDetailsConfig";
import { useGetPriceIncreaseQuery } from "../../../../newapi/financial/priceIncreaseSlice";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpPriceIncreaseDetails = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: priceIncreaseData,isFetching, isLoading,isError } = useGetPriceIncreaseQuery(props.priceIncreaseId,
    {
        skip: !props.priceIncreaseId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <TrendingUpIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t('date')} /> },
        { component: <ShRowSkeleton heading={t('percentage')} /> },
        { component: <ShRowSkeleton heading={t('products')} /> }
    ], [t]);

    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        icon: defaultIcon,
        options: defaultOptions,
        actions: <SidePanelActions setIsExtended={setIsExtendedCallback} />
    }), [defaultIcon, defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.productId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && priceIncreaseData) {
            let date = new Date(priceIncreaseData.date);

            setPanel(prevPanel => ({
                ...prevPanel,
                title: priceIncreaseData.name,
                icon: {
                    theme: priceIncreaseData.state === "active" ? "primary" : "green",
                    content: <TrendingUpIcon/>
                },
                options: [
                    { heading: t('date'), content: date.toLocaleDateString(i18n.language) },
                    { heading: t('increase'), content: `${priceIncreaseData.percentage}%` },
                    { heading: t('products'), content: priceIncreaseData.invoiceProducts.length },
                ],
            }));
        }
    }, [priceIncreaseData, isLoading, t]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spPriceIncreaseDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} >
                <>
                    <Products config={spPriceIncreaseDetailsConfig.products} priceIncreaseId={props.priceIncreaseId} />
                    <Logs config={spPriceIncreaseDetailsConfig.logs} priceIncreaseId={props.priceIncreaseId}/> 

                </>
        </Sidepanel2>
    );
};

export default SpPriceIncreaseDetails;