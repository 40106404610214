
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';

export const spInvoiceProductDetailsConfig = {
    details: {
        title: 'details',
        icon: <InfoIcon/>,
    },
    categories: {
        title: 'categories',
        icon: <ContactsIcon/>,
    }
};
