import { Box, Popper, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React, { useEffect, useRef, useState } from "react";
import ContextButton from "./ContextButton";
import ContextIcon from "./ContextIcon";

import { ReactComponent as Triangle } from "../../../styles/svg/triangle.svg";
import Text from "../text/Text";

const ContextMenu = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const popperRef = useRef();
    
    const handleRightClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    
    const handleClickOutside = (event) => {
        if (popperRef.current && !popperRef.current.contains(event.target) && !anchorEl.contains(event.target)) {
            setOpen(false);
        }
    };
    
    const selectOption = (id, toClose, data) => {
        if(toClose) {
            setOpen(false);
        }

        props.onOptionSelected(id,data);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [anchorEl]);
    
    const id = open ? 'simple-popper' : undefined;
  
    //Passes back any and all data objects from child plus gives access to hasActive prop in child
    const wrappedComponent =  (option,index) => {
        return React.Children.map(option.component, child => React.cloneElement(child, {
            key:index+"wc",
            hasActive: option.hasActive !== "undefined" && option.hasActive, 
            selectOption: (data) => selectOption(option.id,typeof option.close === "undefined" || (typeof option.close !== "undefined" &&option.close), data)
        }));
    };
    
    return (
        <Box onClick={props.onClick} className={props.className} sx={props.sx} onContextMenu={handleRightClick}>
            {props.children}
            
            <Popper sx={{zIndex:2000}} ref={popperRef} id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                <Box
                    sx={{
                        position:"relative",
                        top:8,
                        "& .popover-triangle" : {
                            position:"absolute",
                            left:8,
                            top:-11,
                            width:32,
                            height:12,
                            zIndex:200,
                            "& *" : {
                                fill: theme.palette.mode === "dark" ? colors.grey[300] : "#fff"
                            }
                        },
                    }}
                >
                    <Triangle 
                        className="popover-triangle"
                    ></Triangle>
                    <Box
                        sx={{
                            borderRadius:4,
                            background:theme.palette.mode === "dark" ? colors.grey[300] : "#fff",
                            padding:1.5,
                            minWidth:280,
                            display:"flex",
                            flexDirection:"column",
                            gap:0.5,
                            filter: "drop-shadow(0px 2px 8px rgba(49, 65, 95, 0.25))",
                        }}
                    >
                        {props.options.map((link, index) => (
                            typeof link.component !== "undefined" ? 
                            wrappedComponent(link,index) 
                            :
                                <ContextButton 
                                    key={index+"cb"}
                                    onClick={(data) => selectOption(link.id,typeof link.close === "undefined" || (typeof link.close !== "undefined" &&link.close),data)} 
                                    hasActive={typeof link.hasActive !== "undefined" && link.hasActive}
                                    active={link.active}
                                >
                                    <ContextIcon>
                                        {link.icon}
                                    </ContextIcon>
                                    <Text medium>{link.title}</Text>
                                </ContextButton>
                        ))}
                    </Box>
                </Box>
            </Popper>
        </Box>
    );
  };
export default ContextMenu;