import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";

// import AdvancedFilterView from "./Filter/Views/AdvancedFilterView";
import PopoverModal from "../../Modal/PopoverModal";

import Inventory2Icon from '@mui/icons-material/Inventory2';
import AdvancedFilterView from "./Views/AdvancedFilterView";
import FbContextProvider from "../../../../contexts/FbState";
import TabView from "../../TabViews/TabView";
import SortView from "./Views/SortView";
import FilterSettingsView from "./Views/FilterSettingsView";
import TabView3 from "../../TabView3/TabView3";
import { filterModalConfig } from "./filterModalConfig";

const FilterModal = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <FbContextProvider>
            <PopoverModal
                {...props}
                triangle={{position:"right", color: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0]}}
            >
                <Box 
                    sx={{
                        background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                        // background:"orange",
                        width:520, //fix
                        "@media screen and (max-width: 768px)" : {
                            width:1,
                        }
                    }}
                >
                    
                    <FilterSettingsView config={filterModalConfig.settings} {...props}/>
                    {/* <TabView3
                        tabConfig={filterModalConfig}
                        // tabConfig=(filtermoda)
                        title={title}
                        // views={views}
                        tabClasses={"s56 as-tab"}
                        lighterBody
                        viewType={"popup"}
                        asTab
                    >
                        <> */}
                            {/* <AdvancedFilterView {...props} apiRef={props.apiRef}/> */}
                            {/* <SortView {...props}/> */}
                            {/* <FilterSettingsView config={filterModalConfig.settings} {...props}/>
                        </>
                    </TabView3> */}
                </Box>

            </PopoverModal>
        </FbContextProvider>
    );
}

export default FilterModal;