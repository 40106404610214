import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import * as yup from "yup";
import cx from "classnames"
import { useTranslation } from "react-i18next";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { showToastMessageRequestError, showToastMessageUpdateRequest, showToastMessageDeleteRequest} from "../../../../../utils/toasts";
import { useGetWarehousesSelectQuery, useUpdateWarehouseMutation } from "../../../../../newapi/warehouse/warehouseApiSlice";
import { useFindPrintersSelectQuery } from "../../../../../newapi/warehouse/printerSlice";
import { yupRequired, yupNumberRequired } from "../../../../../utils/validation";
// import { cx } from "classnames";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import { useDeleteWorkspaceMutation, useUpdateWorkspaceMutation } from "../../../../../newapi/warehouse/workspaceSlice";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";


const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(false);

    const { data: warehouses } = useGetWarehousesSelectQuery();
    const { data: printers } = useFindPrintersSelectQuery();

    const [workspaceInfo, setWorkspaceInfo] = useState({
        name: "",
        printer_packinglist: null,
        printer_label: null,
        printer_documents: null,
        autoPrint: false,
        warehouseId: null
    });

    useEffect(() => {
        setWorkspaceInfo(props.data);
    },[props.data]);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        printer_packinglist: yupNumberRequired('printer_packinglist', t),
        printer_label: yupNumberRequired('printer_label', t),
        printer_documents: yupNumberRequired('printer_documents', t),
        warehouseId: yupNumberRequired('warehouseId', t),
    };
    
    const schema = yup.object().shape(rules);

    const handleWarehouseChange = (e) => {
        handleChange("warehouseId", warehouses.data[e].id);
    }

    const handlePrinterChange = (key,e) => {
        handleChange(key, printers.data[e].id);
    }

    const handleChange = (key, value) => {
        setWorkspaceInfo(prevState => ({
            ...prevState,
          [key]: value
        }));
      };
    
    const [UpdateWorkspace,{isLoading}] = useUpdateWorkspaceMutation();
    const [deleteWorkspace,{isLoading: isDeleting}] = useDeleteWorkspaceMutation();
    const handleSaveClick = async () => {
        try {
            const workspaceInfoUpdateObject = {
                name: workspaceInfo.name,
                printer_packinglist: workspaceInfo.printer_packinglist,
                printer_label: workspaceInfo.printer_label,
                printer_documents: workspaceInfo.printer_documents,
                autoPrint: workspaceInfo.autoPrint,
                warehouseId: workspaceInfo.warehouseId
            }

            // Reset field errors
            setFieldErrors({});
        
            await schema.validate(workspaceInfoUpdateObject, { abortEarly: false });
            const response = await UpdateWorkspace({id: props.data.id, data: workspaceInfoUpdateObject}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
            } else {
                // Handle other errors, such as network errors
                showToastMessageUpdateRequest(t, {success: false, error: err.error});
            }
        }
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: 
                <GridActions
                    id={props.id}
                    action={handleSaveClick}
                />,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[handleSaveClick,workspaceInfo]);

    const handleDeleteClick = () => {
        setOpen(true); // Open confirmation modal
    };

    const confirmDelete = async () => {
        try {
            // Implement the delete logic here, e.g., call a delete mutation
            const response = await deleteWorkspace(workspaceInfo.id).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageRequestError(t("delete_failed"), t, err);
        } finally {
            setOpen(false); // Close modal after deletion
        }
    };

  

    return (
            <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
                sx={{
                    "@media screen and (min-width: 48.1em)"  :{
                        marginBottom:11
                    }
                }}
            >
            <Dropdown title={t("general")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    gap:2,
                    flexDirection:"column",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        // alignItems:"flex-start",
                        // justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("name")}</Text>
                    </Box>
                    <ShValidatedInput
                        name="name"
                        value={workspaceInfo?.name || ""}
                        onChange={handleChange}
                        error={fieldErrors.name}
                    />
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        // alignItems:"flex-start",
                        // justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("printer_packinglist")}</Text>
                    </Box>
                        <ShValidatedInputDropdown
                            name="printer_packinglist"
                            displayName="description"
                            changeField="id"
                            selected={printers?.data?.findIndex(item => item.id === workspaceInfo?.printer_packinglist) ?? -1}
                            options={printers?.data ?? []}
                            noSelection={t("choose_printer")} 
                            onChange={handleChange}
                            error={fieldErrors.printer_packinglist}
                        />
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        // alignItems:"flex-start",
                        // justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("printer_label")}</Text>
                    </Box>
                    <ShValidatedInputDropdown
                        name="printer_label"
                        displayName="description"
                        changeField="id"
                        selected={printers?.data?.findIndex(item => item.id === workspaceInfo?.printer_label) ?? -1}
                        options={printers?.data ?? []}
                        noSelection={t("choose_printer")} 
                        onChange={handleChange}
                        error={fieldErrors.printer_label}
                    />
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        // alignItems:"flex-start",
                        // justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("printer_document")}</Text>
                    </Box>
                    <ShValidatedInputDropdown
                        name="printer_documents"
                        displayName="description"
                        changeField="id"
                        selected={printers?.data?.findIndex(item => item.id === workspaceInfo?.printer_documents) ?? -1}
                        options={printers?.data ?? []}
                        noSelection={t("choose_printer")} 
                        onChange={handleChange}
                        error={fieldErrors.printer_documents}
                    />
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        // alignItems:"flex-start",
                        // justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("autoprint")}</Text>
                    </Box>
                    <ToggleButtonGroup
                            color={workspaceInfo?.autoPrint === "true" || workspaceInfo?.autoPrint === true ? "green" : "red"}
                            value={workspaceInfo?.autoPrint === "true" || workspaceInfo?.autoPrint === true ? "true" : "false"}
                            exclusive
                            onChange={(e) => handleChange('autoPrint', e.target.value)}
                            aria-label="Active"
                            className={cx(`${workspaceInfo?.autoPrint}`, {
                                'active': workspaceInfo?.autoPrint === "true" || workspaceInfo?.autoPrint === true
                            })}
                            sx={{
                                background: colors.red[100],
                                padding:0.5,
                                borderRadius:5,
                                transition:"250ms",
                                "&.active" : {
                                    background:colors.green[100],
                                    transition:"250ms",
                                },
                            }}
                        >
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.green[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.red[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="false">
                                {t('no')}
                            </ToggleButton>
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.red[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.green[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="true"
                            >
                                {t('yes')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        // alignItems:"flex-start",
                        // justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("warehouse")}</Text>
                    </Box>
                        <ShValidatedInputDropdown
                            name="warehouseId"
                            displayName="name"
                            changeField="id"
                            selected={warehouses?.data?.findIndex(item => item.id === workspaceInfo?.warehouseId) ?? -1}
                            options={warehouses?.data ?? []}
                            noSelection={t("choose_warehouse")} 
                            onChange={handleChange}
                            error={fieldErrors.warehouseId}
                        />
                </Box>
                
                
                
                </Box>
            </Dropdown>

                
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
            <GridActions
                id={props.id}
                action={handleSaveClick}
                deleteAction={handleDeleteClick}
            />
            <MConfirmDelete
            open={open}
            handleClose={() => setOpen(false)}
            confirm={confirmDelete}
        />
            
                </Box>
            </Box>
    );
}

export default DetailsPanel;