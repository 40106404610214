
import WarehouseIcon from '@mui/icons-material/Warehouse';


export const relationInboundsConfig = {
    inbound:  {
        title: 'all_inbounds',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
    inboundLines: {
        title: 'all_inboundlines',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    }
};
