import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseCard from "../BaseCard";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import TopRight from "../../positionals/TopRight";

export const WarehouseBatchCard = (props) => {
    const theme = useTheme();
    
    const { t,i18n } = useTranslation();

    let status = props.state;
    console.log(status);
    let labelcolor = "grey";
    let labeltext = t('unknown');
    switch(status){
        case "notreceived":
            labelcolor = "grey"
            labeltext = t('not_received');
            break;
        case "partlyreceived":
            labelcolor = "blue"
            labeltext = t('partly_received');
            break;
        case "fullyreceived":
            labelcolor = "green"
            labeltext = t('fully_received');
            break;
        default:
            labelcolor = "red"
            labeltext = t('unknown');
            break;
    }

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box>
                        {props.skeleton ?
                            <ShTextSkeleton/> 
                            :
                            <Text bold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{props.id}</Text>
                        }
                    </Box>

                    <Box
                        sx={{
                            mt:4,
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >
                        {props.skeleton ?
                            <ShTextSkeleton/> 
                            :
                            <>
                                <Text>{props.userName}</Text>
                                <Text light>{props.warehouse?.name}</Text>
                            </>
                        }
                    </Box>

                    <TopRight>
                        <CardLabel hasright variant={labelcolor} position="child-tr">
                            {labeltext}
                        </CardLabel>
                        <CardLabel 
                            variant={theme.palette.mode === "dark" ? "greyLight" : "grey"}
                            hasleft 
                            colorflip={theme.palette.mode !== "dark"}
                            position="child-tr"
                        >
                            {props.skeleton ? "N/A" : props.orderCount}
                        </CardLabel>
                    </TopRight>
                </Box>
            </Box>
        </BaseCard>
    )
}
