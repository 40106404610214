import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import WidgetsIcon from '@mui/icons-material/Widgets';

import { useTranslation } from "react-i18next";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { showToastMessageDeleteRequest, showToastMessageUpdateRequest} from "../../../../../utils/toasts";
import { useDeleteWarehouseLocationTypeMutation, useUpdateWarehouseLocationTypeMutation } from "../../../../../newapi/warehouse/warehouseLocationTypeSlice";
import { useGetEnumsQuery } from "../../../../../newapi/global/generalApiSlice";
import * as yup from "yup";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired } from "../../../../../utils/validation";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const { data: globalEnums, enumIsLoading } = useGetEnumsQuery();

    const [warehouseLocationTypeInfo, setWarehouseLocationTypeInfo] = useState({
      id: 0,
      description: "",
      type: "",
    });

    useEffect(() => {
        setWarehouseLocationTypeInfo(props.data);
    },[props.data]);

    const handleChange = (key, value) => {
        setWarehouseLocationTypeInfo({
          ...warehouseLocationTypeInfo,
          [key]: value
        });
      };
    
    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        description: yupRequired('description', t),
        type: yupRequired('type', t)
    };
        
    const schema = yup.object().shape(rules);

    const [UpdateWarehouseLocationType,{isLoading}] = useUpdateWarehouseLocationTypeMutation();
    const handleSaveClick = async () => {
        try {
            setFieldErrors({});
            await schema.validate(warehouseLocationTypeInfo, { abortEarly: false });

            const response = await UpdateWarehouseLocationType({id: props.data.id, data: warehouseLocationTypeInfo}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageUpdateRequest(t, {success: false, error: err.error});
              }
        }
    };
    
    const [DeleteWarehouseLocationType] = useDeleteWarehouseLocationTypeMutation();
    const handleDeleteClick = async () => {
      try {
          const response = await DeleteWarehouseLocationType({id: props.data.id, warehouseId: props.data.warehouseId}).unwrap();
          showToastMessageDeleteRequest(t, response);
          props.setIsExtended(false);
      } catch (err) {
          showToastMessageDeleteRequest(t, {success: false, error: err.error});
      }
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions 
                id={props.warehouseLocationTypeInfo?.id}
                action={handleSaveClick}
                deleteAction={handleDeleteClick}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props.warehouseLocationTypeInfo,handleSaveClick,handleDeleteClick]);

    return (
            <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
                sx={{
                    "@media screen and (min-width: 48.1em)"  :{
                        marginBottom:11
                    }
                }}
            >
            <Dropdown title={t("general")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1.5,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >
                <Box
                    sx={{
                        display:"flex",
                        gap:2,
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text noBreak>{t("description")}</Text>
                    </Box>
                    <Box display="flex"
                        height="40px"
                        // maxWidth="400px"
                        borderRadius="20px"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedInput
                            height={40}
                            name="description"
                            value={warehouseLocationTypeInfo?.description}
                            onChange={handleChange}
                            error={fieldErrors.description}
                        />
                    </Box>
                </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("type")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                        <ShValidatedInputDropdown
                            height={40}
                            name="type"
                            variant="darker"
                            displayName="name"
                            changeField="name"
                            onChange={handleChange}
                            noSelection={t("choose_option")} 
                            options={globalEnums ? globalEnums["warehouselocationtype.type"].map((item, index) => ({ id: index, name: item })) : []}
                            selected={globalEnums ? globalEnums["warehouselocationtype.type"].findIndex(item => item === warehouseLocationTypeInfo?.type) ?? -1 : -1}
                            closeOnSelection
                            error={fieldErrors.type}
                        />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dropdown>
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
            <GridActions
                id={warehouseLocationTypeInfo?.id}
                action={handleSaveClick}
                deleteAction={handleDeleteClick}
            />
                </Box>
            </Box>
    );
}

export default DetailsPanel;