import { Box, IconButton, useTheme } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { tokens } from "../../../../../../theme";
import Text from "../../../../../../components/theme/text/Text";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { useTranslation } from "react-i18next";
import Subheading from "../../../../../../components/theme/text/Subheading";
import BoolSetting from "../../../../../../components/global/Settings/BoolSetting";
import ShLabel from "../../../../../../components/theme/label/ShLabel";
import { useGetEnumsQuery, useGetSettingsQuery } from "../../../../../../newapi/global/generalApiSlice";
import DropdownSetting from "../../../../../../components/global/Settings/DropdownSetting";
const WareHouseDropdown = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        height: 40,
        background:
          theme.palette.mode === "dark"
            ? colors.grey["400"]
            : colors.txt["primary"],
        borderRadius: 5,
        paddingX: 2,
        paddingRight: 1,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        color: "#fff",
      }}
    >
      <Box>
        <Text bold variant="blue">
          All
        </Text>
        &nbsp;<Text variant="white">Warehouses</Text>
      </Box>
      <KeyboardArrowDownIcon />
    </Box>
  );
};

const PickBasis = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [view, setView] = useState({
    title: 'pick_basis',
    icon: <AccountTreeOutlinedIcon />,
    topRight: <WareHouseDropdown />,
  });

  useEffect(() => {
    props.replaceView({
        ...props.config,
        topRight: <WareHouseDropdown />,
    },);

    return () => {};
  }, []);

  const { data: settings} = useGetSettingsQuery("picking");
  const { data: pickingProcessTypeEnums, enumIsLoading } = useGetEnumsQuery("pickingprocesstype");
  const { data: pickingProductProcessTypeEnums, enumIsLoading: productEnumIsLoading } = useGetEnumsQuery("pickingproductprocesstype");

  return  (
    <Box
      sx={{
        height: 1,
        "@media screen and (max-width: 47.9375em)": {
          paddingTop: 0,
          paddingX: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1,
          gap: 4,
          overflow: "hidden",
          background:
            theme.palette.mode === "dark"
              ? colors.grey[300]
              : colors.bg["tertiary"],
          paddingX: 2,
          paddingTop: 4,
          height: 1,
          position: "relative",
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          "@media screen and (max-width: 47.9375em)": {
            paddingTop: 0,
            paddingX: 0,
            background: colors.bg["tertiary"],
          },
          "& .carrier": {
            paddingLeft: 0,
            marginRight: 2,
          },
          "& [aria-label='Carrier']": {
            // background:"purple",
            paddingLeft: 0,
            marginRight: 2,
          },
        }}
      >
        <Box
          sx={{
            paddingX: 3,
            "@media screen and (max-width: 47.9375em)": {
              display: "none",
            },
          }}
        >
          <Subheading>{t("core_pickbasis_heading")}</Subheading>
          <Text light>{t("core_pickbasis_subtitle")}</Text>
          <Box>
            <Box sx={{ display: "inline-block" }}>
              <ShLabel size={32} fitted variant="green">
                {t("core_pickbasis_label1")}
              </ShLabel>
            </Box>
            <Box sx={{ display: "inline-block" }}>
              <ShLabel size={32} fitted variant="red">
                {t("core_pickbasis_label2")}
              </ShLabel>
            </Box>
          </Box>
        </Box>
        {/* <GridActions></GridActions> */}
        <Box
          sx={{
            background:
              theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
            flex: 1,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            "@media screen and (max-width: 48em)": {
              // display:"none"
              background:
                theme.palette.mode === "dark"
                  ? colors.grey[200]
                  : colors.grey[0],
            },
          }}
        >
          <Box
            sx={{
              background:
                theme.palette.mode === "dark"
                  ? colors.grey[400]
                  : colors.grey[0],
              height: 24,
              padding: 3,
              display:"flex",
              flexDirection:"column",
              gap:1,
            }}
          >
            <DropdownSetting 
            settings={settings} 
            objects={pickingProcessTypeEnums ? pickingProcessTypeEnums["pickingprocesstype"].map((item, index) => ({ id: item, value: item, name: t(item) })) : []} 
            noselection={"choose_type"}
            variant={"default"} 
            skey="picking_default_process_type"
            />
            <DropdownSetting 
            settings={settings} 
            objects={pickingProductProcessTypeEnums ? pickingProductProcessTypeEnums["pickingproductprocesstype"].map((item, index) => ({ id: item, value: item, name: t(item) })) : []} 
            noselection={"choose_type"}
            variant={"default"} 
            skey="picking_product_process_type"
            />
            <BoolSetting settings={settings} skey="picking_without_cart_scan"/>
            {/* <BoolSetting 
              settings={settings} 
              skey="picking_without_count_confirm"
            />
            <BoolSetting 
              settings={settings} 
              skey="picking_without_product_scan"
            /> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PickBasis;
