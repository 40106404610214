import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme"
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Text from "../../../../../components/theme/text/Text";

import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../../components/theme/label/ShLabel";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useGetOrdersQuery, useGetOrderPickingListQuery, useGetOrderPickingListPDFMutation } from "../../../../../newapi/order/ordersSlice";
import { useAddNewPickingJobMutation, useGetPickingJobPickingListQuery } from "../../../../../newapi/pickingjob/pickingjobSlice";
import OrdersCard from "../../../../../components/theme/cards/orders/OrdersCard";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import { general_states } from "../../../../../utils/staticEnums";
import SpOrderPickingList from "../../../../../components/global/Sidepanels/SpOrderPickingList/SpOrderPickingList";
import SpPickingJobPickingList from "../../../../../components/global/Sidepanels/SpPickingJobPickingList/SpPickingJobPickingList";

import PrintIcon from '@mui/icons-material/Print';
import BallotIcon from '@mui/icons-material/Ballot';
import ShDataGridSelectedActions from "../../../../../components/theme/datagrid/ShDataGridSelectedActions";
import DropdownButton from "../../../../../components/theme/buttons/DropdownButton";
import MSelectWarehouses from "../.././../../../components/global/Modals/MSelectWarehouses";
import { PickListCard } from "../../../../../components/theme/cards/stock/PickListCard";
import { showToastMessagePostRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";

const GridActions = (props) => {
    const theme = useTheme();
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <ButtonGroup version={2}>
                <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} endIcon={<AddIcon/>} variant="contained">New</Shbutton>
            </ButtonGroup>
        </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const isShipmentDue = (inputDateString) => {
   const inputDate = new Date(inputDateString);
   const now = new Date();
  
    return now > inputDate;
  };

const OutboundPickingListsTodo = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    const [pickingJobIsExtended, setPickingJobIsExtended] = useState(false);
    const activeWarehouseId = useSelector(state => state.warehouse.activeId);
    const [createdPickingJobId, setCreatedPickingJobID] = useState(0);
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);
    
    const ref = useRef();
    const [config, setConfig] = useState({"state": general_states.OPEN});

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
        }
    },[]);

    // const { data: orderData, isLoading } = useGetOrderPickingListQuery({orders: [selectedRowId]},
    //     {
    //        enabled: rowSelected && selectedRowId !== -1,
    //     skip: !rowSelected
    //     }
    // );

    // const { data: pickingJobData, isPickingJobLoading } = useGetPickingJobPickingListQuery(createdPickingJobId,
    //     {
    //        skip: createdPickingJobId === 0
    //     }
    // );

    const [selectedItems, setSelectedItems] = useState([]);
    // const setSelected
    const onSelected = (selectedItems) => {
        setSelectedItems(selectedItems);
    }
      // Function to select or deselect a card by its ID
    const handleCardSelection = (id) => {
        setSelectedItems((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
            // Deselect the card if it's already selected
            return prevSelectedIds.filter((selectedId) => selectedId !== id);
        } else {
            // Select the card if it's not selected
            return [...prevSelectedIds, id];
        }
        });
    };
    
    useEffect(() => {
        console.log(selectedItems);
    },[selectedItems])
    
    const [orderId, setOrderId] = useState();
    const orderIdRef = useRef(orderId);
    
    const getOrderPickingList = (params) => {
        if (!params) return;
    
        const neworderId = parseInt(params.row.id);
        // Only update state if the orderId has actually changed
        if (orderIdRef.current !== neworderId) {
            setOrderId(neworderId);
            orderIdRef.current = neworderId; // Update the ref to the new value
        }
        setIsExtended(true);
    };


    // const getPickingList = (event, id) => {
    //     if (id === undefined || id === null) {
    //         return;
    //     }
    //     event.stopPropagation();
    //     setSelectedRowId(id);
    //     setRowSelected(true);
    // };
    
    const [GetOrderPickingListPDF] = useGetOrderPickingListPDFMutation();
    const handlePrintAction = async () => {
        if (selectedItems.length === 0) {
            return;
        }

        try {
            const response = await GetOrderPickingListPDF({orders: selectedItems}).unwrap();
            const newWindow = window.open(response.data, '_blank', 'noopener,noreferrer');
            if (newWindow) {
                setTimeout(() => {
                    window.URL.revokeObjectURL(response.data);
                }, 500);
            } else {
                window.URL.revokeObjectURL(response.data);
            }
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };

    const [open, setOpen] = useState(false);
    const [addNewPickingJob, { isLoading: isAddingNewPickingJob }] = useAddNewPickingJobMutation();

    const handleCreateBatchAction = async () => {
        if (parseInt(activeWarehouseId) > 0) {
            await createNewPickingJob({ orderIds: selectedItems, warehouseId: activeWarehouseId });
        } else {
            setOpen(true); // Open the modal to select a warehouse
        }
    };

    const createNewPickingJob = async (body) => {
        try {
            const result = await addNewPickingJob(body).unwrap();
            setCreatedPickingJobID(result.pickingJobId);
            setPickingJobIsExtended(true);
            showToastMessagePostRequest(t, result);
            } catch (err) {
                console.log(err);
                showToastMessageRequestError(t("create_failed"), t, {success: false, error: err.error});
            }
    };

    //Modal close for warehouse 
    // this modal opens when no warehouse is actively set because you can only make 
    // a batch for a specific warehouse. Batch is a picikingjob
    const handleClose = (updateWarehouse, warehouse) => {
        setOpen(false);
        if (warehouse && warehouse.id) {
            createNewPickingJob({ orderIds: selectedItems, warehouseId: warehouse.id });
        }
    };

    // useEffect(() => {
    //     if (!isExtended && rowSelected && orderData) {
    //         setSelectedRowId(-1);
    //         setRowSelected(false);
    //     }
    // }, [isExtended]);

    // useEffect(() => {
    //     if (!isLoading && orderData && rowSelected) {
    //         setIsExtended(true);
    //     }
    // }, [orderData, isLoading, rowSelected]);

    // useEffect(() => {
    //     if (!isPickingJobLoading && pickingJobData && createdPickingJobId > 0) {
    //         setPickingJobIsExtended(true);
    //     }
    // }, [pickingJobData, isPickingJobLoading, createdPickingJobId]);
    

    const columns = [
        {
            field: "id",
            headerName: t("reference"),
            shFilter : {
                type: "number",
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <Box 
                        sx={{
                            width:80,
                            cursor:"pointer",
                            lineHeight:"64px",
                        }} 
                        onClick={(event) => {
                            event.stopPropagation();
                            getOrderPickingList(params)
                        }}
                    >
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.id}</Text>
                    </Box>
                );
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                let labelcolor = isShipmentDue(params.row.date_planned) ? "red" : "blue";
                
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {t('open')}
                    </ShLabel>
                  );
              }
        },
        {
            field: "type", 
            headerName: t("type"),
            flex:1,
            sortable:false,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                let labelcolor = params.row.totalOrderLines && params.row.totalOrderLines === 1 ? "orange" : "purple";
                let labelText = params.row.totalOrderLines && params.row.totalOrderLines === 1 ? t('single') : t('normal');
                
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {labelText}
                    </ShLabel>
                  );
              }
        },
        {field: "products", headerName: t("products"),flex:1,
            sortable:false,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.row.totalOrderLines
        }},
        {field: "stream", headerName: t("stream"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.stream ? params.row.stream.shortName : "-";
        }},
        ...isFulfilment ? [{field: "relation", headerName: t("fullfillmentcustomer"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.relation?.name
            }}] : [],
        {field: "date_planned", headerName: t("expected_shipment_date"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
            //  let labelcolor = "grey";
                let planned = new Date(params.row.date_planned);
            //   let sent = params.row.date_sent ? new Date(params.row.date_sent) : null;

                return planned.toLocaleDateString(t.language);
            }
        },
        {field: "shipper", headerName: t("shipper"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (params.row.shipper ? params.row.shipper.name : t("unknown"))
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <PickListCard select={handleCardSelection} selected={selectedItems.includes(params.row.id)} skeleton={params.row.skeleton ?? false} {...params.row} />
                )
            }
        }
    ];

    return (
        <Box height={1} ref={ref} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetOrdersQuery}
                config={config}
                // gridActions={<GridActions></GridActions>} 
                title={t('pickinglists')}
                gridOptions={gridOptions} 
                columns={columns}
                rows={data}
                sortModel={{field: columns[0].field,type:"DESC"}}
                selectable
                setSelected={onSelected}
                selectedItems={selectedItems}
                selectedActions={
                    <ShDataGridSelectedActions selectedItems={selectedItems}>
                        <DropdownButton onClick={handlePrintAction} icon={<PrintIcon/>} text={t("print")}/>
                        <DropdownButton onClick={handleCreateBatchAction} icon={<BallotIcon/>} text={t("create_batch")}/>
                    </ShDataGridSelectedActions>
                }
            >
            </ShDataGrid2>

            <MSelectWarehouses
                open={open}
                handleClose={handleClose}
                ignoreAll={true}
            />
            
            {/* <ShDataGridSelectedActions selectedItems={selectedItems}>
                <DropdownButton onClick={handlePrintAction} icon={<PrintIcon/>} text={"print"}/>
                <DropdownButton onClick={handleCreateBatchAction} icon={<BallotIcon/>} text={"Maak batch"}/>
            </ShDataGridSelectedActions> */}

            <SpOrderPickingList
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                orderId={orderId}
            ></SpOrderPickingList>

            <SpPickingJobPickingList
                isExtended={pickingJobIsExtended}
                setIsExtended={setPickingJobIsExtended}
                pickingJobListId={createdPickingJobId}
                
                // data={pickingJobData}
            ></SpPickingJobPickingList>
        </Box>
    );
}

export default OutboundPickingListsTodo;