import { useTranslation } from "react-i18next";
import ExplainerModal from "../../../theme/Modal/ExplainerModal/ExplainerModal";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import ExplainerBox from "../../../theme/Modal/ExplainerModal/ExplainerBox";

const EMGeneralExplainerBox = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <ExplainerModal>
            <Box
                sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:2,
                }}
            >
                <ExplainerBox
                    title={props.title}
                    text={props.text}
                />
                
            </Box>
        </ExplainerModal>
    );
}

export default EMGeneralExplainerBox;