
import WidgetsIcon from '@mui/icons-material/Widgets';
import CropFreeIcon from '@mui/icons-material/CropFree';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const spCarrierDetailsConfig = {
    details:  {
        title: 'details',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
    // channels: {
    //     title: 'channels',
    //     icon: <CropFreeIcon/>,
    // },
    logs: {
        title: 'logs',
        icon: <FormatListBulletedIcon/>,
        isSingleDataGrid: true,
    }
};
