import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { updateToken, logout } from './user/authentication/authSlice';
import { JWT_TOKEN } from '../storagekeys'; // If you need this for any purpose
import { cacheKeys } from './cacheKeys';
import { ENV_TYPES } from '../components/global/Sidebar';
import { config } from './apiUrlConfig';
import { authApiSlice } from './user/authentication/authApiSlice';
import { Mutex } from 'async-mutex'
const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
    baseUrl: config.url.API_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;

        // Log the token (optional)
        if (token) {
            const tokenStart = token.substring(0, 10);
            const tokenEnd = token.substring(token.length - 10);
            // console.log("Setting header token for request", `${tokenStart}...${tokenEnd}`);
            headers.set('authorization', `Bearer ${token}`);
        }

        const warehouseId = getState().warehouse.activeId;
        const type = getState().settings.environment.selectedEnvironmentType;
        const id = getState().settings.environment.selectedId;

        if (warehouseId) {
            headers.set('warehouse_id', warehouseId);
        }

        if (type === ENV_TYPES[100]) {
            // CUSTOMER
            headers.set("customer_id", id);
        } else if (type === ENV_TYPES[200]) {
            // RELATION
            headers.set("relation_id", id);
        }

        return headers;
    },
});

// Custom base query with re-authentication logic
const baseQueryWithReauth = async (args, api, extraOptions) => {
    // First, try the base query
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);

    // Check for 401 Unauthorized status
    if (result.error && result.error.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try{
        const refreshToken = api.getState().auth.refreshToken;
        await api.dispatch(updateToken({ refreshToken: null }));
        const refreshTokenResponse = await api.dispatch(authApiSlice.endpoints.refreshToken.initiate({ "refresh_token": refreshToken }));

        // Check if the refresh was successful
        if (refreshTokenResponse.data) {
            // Store the new token
            api.dispatch(updateToken(refreshTokenResponse.data));

            // Retry the original query with the new token
            result = await baseQuery(args, api, extraOptions);
        } else {
            // If token refresh fails, dispatch the loggedOut action
            api.dispatch(logout());
        }
    } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
    }
    return result;
};

// Create the API slice using the custom base query
export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: baseQueryWithReauth,
    tagTypes: cacheKeys,
    refetchOnMountOrArgChange: false,
    endpoints: (builder) => ({
        // Define your endpoints here
    }),
});

// Export hooks for usage in functional components
// export const { useGetRandQuery } = apiSlice; // Uncomment and adjust as necessary
