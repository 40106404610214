import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";

import { useTranslation } from "react-i18next";
import { showToastMessageUpdateRequest, showToastMessageDeleteRequest } from "../../../../../utils/toasts";
import { useUpdateWarehouseMutation, useDeleteWarehouseMutation } from "../../../../../newapi/warehouse/warehouseApiSlice";
import { countriesFromLocale } from '../../../../../utils/countries';
import { countryCodeNL } from '../../../../../utils/constants';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmationWithValue from "../../../Modals/MConfirmationWithValue"; // Import the confirmation modal
import * as yup from "yup";
import MConfirmDelete from "../../../Modals/MConfirmDelete";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired } from "../../../../../utils/validation";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const language = useSelector((state) => state.settings.language);
    const countries = countriesFromLocale(language);
    const [open, setOpen] = useState(false); // State for confirmation modal

    const [warehouseInfo, setWarehouseInfo] = useState({
      id: 0,
      name: "",
      dockings: "",
      street: "",
      house_nr: "",
      house_nr_extra: "",
      postal: "",
      city: ""
    });

    useEffect(() => {
        setWarehouseInfo(props.data);
    },[props.data]);


    const handleChange = (key, value) => {
        setWarehouseInfo({
          ...warehouseInfo,
          [key]: value
        });
      };

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        street: yupRequired('street', t),
        house_nr: yupRequired('house_nr', t),
        postal: yupRequired('postal', t),
        city: yupRequired('city', t),
        country: yupRequired('country', t)
    };
    
    const schema = yup.object().shape(rules);
    
    const [UpdateWarehouse,{isLoading}] = useUpdateWarehouseMutation();
    const [DeleteWarehouseMutation] = useDeleteWarehouseMutation();
    const handleSaveClick = async () => {
        try {
            setFieldErrors({});
            await schema.validate(warehouseInfo, { abortEarly: false });
            const response = await UpdateWarehouse({id: props.data.id, data: warehouseInfo}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageUpdateRequest(t, {success: false, error: err.error});
              }
        }
    };

    const handleDeleteClick = () => {
        setOpen(true); // Open confirmation modal
    };

    const confirmDelete = async (value) => {
        try {
            const response = await DeleteWarehouseMutation(props.data.id).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, { success: false, error: err.error });
        } finally {
            setOpen(false); // Close modal after deletion
        }
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions data={warehouseInfo} action={handleSaveClick} deleteAction={handleDeleteClick} />,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props.warehouseInfo,handleSaveClick]);

    return (
            <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
                sx={{
                    "@media screen and (min-width: 48.1em)"  :{
                        marginBottom:11
                    }
                }}
            >
            <Dropdown title={t("general")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1.5,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >
                <Box
                    sx={{
                        display:"flex",
                        gap:2,
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text noBreak>{t("name")}</Text>
                    </Box>
                    <Box display="flex"
                        height="40px"
                        // maxWidth="400px"
                        borderRadius="20px"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedInput
                            height={40}
                            name="name"
                            value={warehouseInfo?.name}
                            onChange={handleChange}
                            error={fieldErrors.name}
                        />
                    </Box>
                </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("dockings")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="dockings"
                                value={warehouseInfo?.dockings}
                                onChange={handleChange}
                                error={fieldErrors.dockings}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dropdown>
            <Dropdown title={t("address_details")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1.5,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >
                <Box
                    sx={{
                        display:"flex",
                        gap:2,
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text noBreak>{t("street")}</Text>
                    </Box>
                    <Box display="flex"
                        height="40px"
                        // maxWidth="400px"
                        borderRadius="20px"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedInput
                            height={40}
                            name="street"
                            value={warehouseInfo?.street}
                            onChange={handleChange}
                            error={fieldErrors.street}
                        />
                    </Box>
                </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("house_nr")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="house_nr"
                                value={warehouseInfo?.house_nr}
                                onChange={handleChange}
                                error={fieldErrors.house_nr}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("cap_house_nr_extra")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="house_nr_extra"
                                value={warehouseInfo?.house_nr_extra}
                                onChange={handleChange}
                                error={fieldErrors.house_nr_extra}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("postal")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="postal"
                                value={warehouseInfo?.postal}
                                onChange={handleChange}
                                error={fieldErrors.postal}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("city")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="city"
                                value={warehouseInfo?.city}
                                onChange={handleChange}
                                error={fieldErrors.city}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("country")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                        <ShValidatedInputDropdown
                            height={40}
                            name="country"
                            displayName="name"
                            changeField="alpha2"
                            options={countries ?? null}
                            noSelection={t("choose_option")} 
                            onChange={handleChange}
                            selected={countries?.findIndex(country => (warehouseInfo?.country && warehouseInfo?.country === country.alpha2) || (country.alpha2 === countryCodeNL)) ?? -1}
                            error={fieldErrors.country}
                        />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dropdown>
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
            <GridActions
                id={warehouseInfo?.id}
                action={handleSaveClick}
                deleteAction={handleDeleteClick}
            />
                </Box>
                <MConfirmDelete
                    open={open}
                    handleClose={() => setOpen(false)}
                    confirm={confirmDelete}
                />
            </Box>
    );
}

export default DetailsPanel;