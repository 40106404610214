
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme"

import { useTranslation } from 'react-i18next';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import { useEffect, useRef, useState } from "react";
import { useGetInvoiceCategoriesQuery } from "../../../../../newapi/financial/invoiceCategorySlice";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { general_states } from "../../../../../utils/staticEnums";
import AddIcon from '@mui/icons-material/Add';

import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import SpInvoiceCategoryDetails from "../../../../../components/global/Sidepanels/SpInvoiceCategoryDetails/SpInvoiceCategoryDetails";
import MAddInvoiceCategory from "../../../../../components/global/Modals/MAddInvoiceCategory";
import { WarehouseInvoiceCategoryCard } from "../../../../../components/theme/cards/financial/InvoiceCategoryCard";

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('add_category')}
            </Shbutton>
        </ButtonGroup>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Categories = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    // const [selectedRowId, setSelectedRowId] = useState(-1);
    // const [rowSelected, setRowSelected] = useState(false);
    
    const ref = useRef();
    const [config, setConfig] = useState({"state": general_states.ACTIVE});

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
        }
    },[]);

    const handleOpen = () => {
        setAddModalOpen(true);
    }
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const [invoiceCategoryId, setInvoiceCategoryId] = useState();
    const invoiceCategoryIdRef = useRef(invoiceCategoryId);
    
    const getInvoiceCategory = (params) => {
        if (!params) return;
    
        const newInvoiceCategoryId = parseInt(params.row.id);
        // Only update state if the pickingJobListId has actually changed
        if (invoiceCategoryIdRef.current !== newInvoiceCategoryId) {
            setInvoiceCategoryId(newInvoiceCategoryId);
            invoiceCategoryIdRef.current = newInvoiceCategoryId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex:2,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return params.row.name;
            }
        },
        {field: "relations", headerName: t("relations"),flex:1,
            sortable:false,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.row.relations ? params.row.relations.length : 0;
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <WarehouseInvoiceCategoryCard
                        onClick={() => getInvoiceCategory(params)}
                        skeleton={params.row.skeleton}
                        {...params.row}
                    />
                    // <ShTextSkeleton/>
                )
            }
        }
    ];

    return (
        <Box height={1} ref={ref} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetInvoiceCategoriesQuery}
                config={config}
                gridActions={<GridActions handleOpen={handleOpen}></GridActions>} 
                title={t('categories')}
                gridOptions={gridOptions} 
                columns={columns}
                rows={data}
                onRowClick={getInvoiceCategory}
                sortModel={{field: columns[0].field,type:"ASC"}}
                >
            </ShDataGrid2>

            <SpInvoiceCategoryDetails
                invoiceCategoryId={invoiceCategoryId}
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                // data={pickingJobData}
            ></SpInvoiceCategoryDetails>
            <MAddInvoiceCategory onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}

export default Categories;