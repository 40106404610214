import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { showToastMessageDeleteRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import { useDeletePickupCartMutation, useUpdatePickupCartMutation } from "../../../../../newapi/warehouse/pickupCartSlice";
import { useGetReachTypesSelectQuery } from "../../../../../newapi/warehouse/reachTypeSlice";
import { useGetWarehouseZonesSelectQuery } from "../../../../../newapi/warehouse/warehouseApiSlice";
import * as yup from "yup";
import { yupRequired, yupNumberRequired } from "../../../../../utils/validation";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete"; // Import the confirmation modal

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data: reachTypes} = useGetReachTypesSelectQuery();
    const {data: warehouseZones} = useGetWarehouseZonesSelectQuery();
    const data = props.data;

    const [pickingCartInfo, setPickingCartInfo] = useState({
        state: "active",
        id: 0,
        capacity: 0,
        barcode: "",
        pickupCartLocations: [],
        warehouseZoneId: 0,
        reachTypeId: 0
    });
    const [open, setOpen] = useState(false); // State for confirmation modal

    useEffect(() => {
        setPickingCartInfo(props.data);
    },[props.data]);
    

    const handleChange = (key, value) => {
        setPickingCartInfo({
          ...pickingCartInfo,
          [key]: value
        });
      };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={pickingCartInfo} action={handleSaveClick} deleteAction={handleDeleteClick}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[pickingCartInfo]);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        barcode: yupRequired('barcode',t),
        reachTypeId: yupNumberRequired('reachtype', t),
        warehouseZoneId: yupNumberRequired('warehousezone', t)
    };
    
    const schema = yup.object().shape(rules);

    const [EditPickingCart,{isLoading}] = useUpdatePickupCartMutation();
    const [DeletePickupCart,{isLoading2}] = useDeletePickupCartMutation();
    const handleSaveClick = async () => {
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                const updatedPickingCartInfo = {
                    id: pickingCartInfo.id,
                    body: {
                        barcode: pickingCartInfo.barcode,
                        reachTypeId: pickingCartInfo.reachTypeId,
                        warehouseZoneId: pickingCartInfo.warehouseZoneId,
                        state: pickingCartInfo.state
                    }
                };
                const data = await EditPickingCart(updatedPickingCartInfo).unwrap()
                showToastMessageUpdateRequest(t,data);
        } catch (err) {
            showToastMessageRequestError(t("update_failed"),t,err);
          }
            
        } else {
            if(!isLoading) {
                toast.error(t("not_all_fields_filled"), {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }    
        }        
    };

    const handleDeleteClick = () => {
        setOpen(true); // Open confirmation modal
    };

    const confirmDelete = async () => {
        try {
            // Implement the delete logic here, e.g., call a delete mutation
            const response = await DeletePickupCart(pickingCartInfo.id).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageRequestError(t("delete_failed"), t, err);
        } finally {
            setOpen(false); // Close modal after deletion
        }
    };

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
        <Dropdown title={t("general")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1.5,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("barcode")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                name="barcode"
                                value={pickingCartInfo?.barcode ?? ""}
                                onChange={handleChange}
                                error={fieldErrors.barcode}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("reachtype")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInputDropdown
                                name="reachTypeId"
                                displayName="description"
                                changeField="id"
                                selected={reachTypes?.data.findIndex(reachType => reachType.id === pickingCartInfo?.reachTypeId) ?? -1}
                                options={reachTypes?.data?? []}
                                noSelection={t("choose_reachtype")} 
                                onChange={handleChange}
                                error={fieldErrors.reachTypeId}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("warehousezone")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInputDropdown
                                name="warehouseZoneId"
                                displayName="description"
                                changeField="id"
                                selected={warehouseZones?.data?.findIndex(warehouseZone => warehouseZone.id === pickingCartInfo?.warehouseZoneId) ?? -1}
                                options={warehouseZones?.data ?? []}
                                noSelection={t("choose_warehousezone")} 
                                onChange={handleChange}
                                error={fieldErrors.warehouseZoneId}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dropdown>
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
        <GridActions
            id={props.data?.id}
            action={handleSaveClick}
            deleteAction={handleDeleteClick}
            // active={active}
            // blocked={blocked}
            // remark={remark}
            // carrier={carrier}
            // quarantine={quarantine}
            // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
        />
        <MConfirmDelete
            open={open}
            handleClose={() => setOpen(false)}
            confirm={confirmDelete}
        />
            </Box>
        </Box>
    );
}

export default DetailsPanel;