import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useTranslation } from 'react-i18next';
import DetailsPanel from "./Views/Details";
import Relations from "./Views/Relations";
import Products from "./Views/Products";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spInvoiceCategoryDetailsConfig } from "./spInvoiceCategoryDetailsConfig";
import { useGetInvoiceCategoryQuery } from "../../../../newapi/financial/invoiceCategorySlice";
import ListIcon from '@mui/icons-material/List';

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpInvoiceCategoryDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: invoiceCategoryData, isLoading,isFetching,isError } = useGetInvoiceCategoryQuery(props.invoiceCategoryId,
    {
        skip: !props.invoiceCategoryId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ListIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon,defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.invoiceCategoryId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && invoiceCategoryData) {
            setPanel(prevPanel => ({
                ...prevPanel,
                title: invoiceCategoryData.name,
                subTitle: "",
                options: [],
            }));
        }
    }, [invoiceCategoryData, isLoading, isFetching, t,theme.palette.mode]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spInvoiceCategoryDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <DetailsPanel config={spInvoiceCategoryDetailsConfig.details} data={invoiceCategoryData} setIsExtended={props.setIsExtended} />
                    <Relations config={spInvoiceCategoryDetailsConfig.relations} invoiceCategoryId={props.invoiceCategoryId} setIsExtended={props.setIsExtended} />
                    <Products config={spInvoiceCategoryDetailsConfig.products} invoiceCategoryId={props.invoiceCategoryId} setIsExtended={props.setIsExtended} />
                </>
        </Sidepanel2>
    );
};

export default SpInvoiceCategoryDetails;