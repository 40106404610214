import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const INVOICECATEGORY_BASE = "/invoicecategories";
const buildUrl = (endpoint) => INVOICECATEGORY_BASE + endpoint;
const API_TAG = 'InvoiceCategories';

const invoiceCategorySlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInvoiceCategories: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: API_TAG }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getInvoiceCategories', API_TAG)
        }),
        getInvoiceCategorySelect: builder.query({
            query: () => ({
                url: buildUrl(`/select`),
                method: 'GET',
            }),
            providesTags: () => [{ type: API_TAG }]
        }),
        getProductFromCategoryAndInvoiceProduct: builder.query({
            query: ({ categoryId, invoiceProductId }) => ({
                url: buildUrl(`/${categoryId}/products/${invoiceProductId}`),
                method: 'GET',
            }),
            providesTags: () => [{ type: API_TAG }]
        }),
        getInvoiceCategoryRelations: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/relations`),
                method: 'GET',
            }),
            providesTags: () => [{ type: API_TAG }]
        }),
        getInvoiceCategoryProducts: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}/products`),
                method: 'GET'
            }),
            providesTags: () => [{ type: API_TAG }],
        }),
        getInvoiceCategory: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            // providesTags: (id) => [{ type: `CountingStrategyTemplateSingle`,id }]
            providesTags: (result, error, arg) => [{ type: `${API_TAG}Single`, id: arg }]
        }),
        addInvoiceCategory: builder.mutation({
            query: invoiceCategoryInfo => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: invoiceCategoryInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG}],
        }),
        updateInvoiceCategory: builder.mutation({
            query: invoiceCategoryInfo => ({
                url: buildUrl(`/${invoiceCategoryInfo.id}`),
                method: 'PUT',
                body: invoiceCategoryInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }],
        }),
        deleteInvoiceCategory: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }],
        }),
    })
});

export const {
    useGetInvoiceCategoriesQuery,
    useGetInvoiceCategoryQuery,
    useGetProductFromCategoryAndInvoiceProductQuery,
    useGetInvoiceCategoryProductsQuery,
    useGetInvoiceCategorySelectQuery,
    useGetInvoiceCategoryRelationsQuery,
    useAddInvoiceCategoryMutation,
    useUpdateInvoiceCategoryMutation,
    useDeleteInvoiceCategoryMutation
  } = invoiceCategorySlice;
