import { Box, useTheme } from "@mui/material"
import { tokens } from "../../../theme";

const WithHeaderCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <>
            {/* Header */}fwew
            <Box
                sx={{
                    paddingY:1.5,
                    paddingX:2,
                    borderBottom:`1px solid ${theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.grey[100]}`,
                    ...props.sx
                }}
            >
                {props.children[0]}
            </Box>

            {/* Body */}
            <Box
                sx={{
                    padding:2
                }}
            >
                {props.children[1]}
            </Box>
        </>
    )
}

const NormalCard = (props) => {
    return (
        <Box
            sx={{
                padding:2,
                ...props.sx
            }}
        >
            {props.children}
        </Box>
    )
}

const BaseCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isHeader = props.isHeader ?? false;
    const color = props.color ?? theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0];
    return (
        <Box
            sx={{
                background: color,
                boxShadow: '0px 1px 2px 1px rgba(49, 65, 95, 0.15)',
                position:"relative", //Needed for absolutely positioned things
                width:1,
                flex:1,
                borderRadius:3
            }}
            onClick={props.onClick}
        >
            {/* weufiwue */}
            {typeof props.children.length === "undefined" || props.children.length === 1 ?
                <NormalCard {...props}>{props.children}</NormalCard>
            :
                <WithHeaderCard {...props}>{props.children}</WithHeaderCard>
            }
        </Box>
    )
}

export default BaseCard;